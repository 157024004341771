<template>
  <div class="mt-4">
    <div class="max-sm:w-full w-80 h-full rounded-lg shadow bg-white relative">
      <div>
        <div>
          <div class="h-28 rounded-t-lg overflow-hidden">
            <img :src="xmark" alt="" class="pt-2 pr-2.5 absolute top-0 right-0 cursor-pointer" @click="closeDep" />
            <img src="../../assets/card_dep.svg" alt="" class="h-full w-full object-cover" />
          </div>
        </div>

        <div :class="getBackgroundColorClass(departement.label.charAt(0))"
          class="w-16 h-16 rounded-full absolute top-28 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center border-2 border-slate-50">
          <span class="text-white font-bold text-2xl">{{
            departement.label.charAt(0).toUpperCase()
          }}</span>
        </div>
        <span class="text-base font-medium absolute top-36 flex justify-center w-full mt-2">{{ departement.label
          }}</span>
      </div>
      <!-- Details departments -->
      <div class="ml-10 mt-20">
        <div class="flex w-56 py-2">
          <img :src="todo" alt="" />
          <span class="pl-4 font-bold text-base text-gray-700">Enseignants</span>
          <div class="flex pl-2">
            <div class="z-40 w-7 h-7 rounded-full border-2 border-white">
              <img :src="profil" alt="" />
            </div>
            <div class="z-30 w-7 h-7 rounded-full border-2 border-white -mx-2">
              <img :src="profil" alt="" />
            </div>
            <div class="z-20 w-7 h-7 rounded-full border-2 border-white">
              <img :src="profil" alt="" />
            </div>
          </div>
        </div>
        <div class="flex w-56 py-2">
          <img :src="calendar" alt="" />
          <span class="pl-4 font-bold text-base text-gray-700">Emplois de temps</span>
        </div>
        <div class="flex w-56 py-2">
          <img :src="calendarStylo" alt="" />
          <span class="pl-4 font-bold text-base text-gray-700 w-full">Créé le {{ departement.added_at }}</span>
        </div>
        <div class="flex w-56 py-2">
          <img :src="refresh" alt="" />
          <span class="pl-4 font-bold text-base text-gray-700 w-full">Mis à jour le {{ departement.updated_at }}</span>
        </div>
        <div class="flex w-56 py-2">
          <img :src="chef" alt="" />
          <span class="pl-4 font-bold text-base text-gray-700 w-full">
            {{
              departement.user.nom && departement.user.prenom
                ? departement.user.nom + " " + departement.user.prenom
                : "Aucun directeur"
            }}
          </span>
        </div>
      </div>
      <div class="flex pt-7 justify-center">
        <button @click="openModalEditionModal">
          <ModalEditionDepartement v-if="isEditAllowed" ref="modalEdition" :departementId="id"
            :defaultLabel="departement.label" :id_directeur="departement.user.id" @departementUpdated="refreshData" />
        </button>
        <button>
          <ConfirmArchivage v-if="isEditAllowed" @confirm="handleArchiveDepartement(departement)"
            :img="departement.archived ? archiveGreen : archiveOrange" :archived="departement.archived" />
        </button>
      </div>
      <!-- fin details departments -->
      <div class="p-4 px-6 flex flex-col justify-center" v-if="isEditAllowed">
        <button class="bg-blue-600 w-full h-12 rounded text-neutral-100 text-base font-medium hover:bg-blue-500">
          Action
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import ModalEditionDepartement from "../Modals/ModalEditionDepartement/ModalEditionDepartement.vue";
import calendar from "../../assets/calendar2.svg";
import calendarStylo from "../../assets/calendarStylo.svg";
import chef from "../../assets/chef.svg";
import crayon from "../../assets/crayon.svg";
import pencil from "../../assets/pencil.svg";
import refresh from "../../assets/refresh2.svg";
import todo from "../../assets/todo.svg";
import xmark from "../../assets/xmark.svg";
import profil from "../../assets/profil.svg";
import ConfirmArchivage from "../Popup/ConfirmArchivage.vue";
import archiveOrange from "../../assets/OrangeArchive.svg";
import archiveGreen from "../../assets/GreenArchive.svg";
import { editAllowed } from "../../SPA/spa.services.js";
export default {
  name: "DetailsCarteDepartement",
  components: {
    ModalEditionDepartement,
    ConfirmArchivage,
  },
  props: {
    id: {
      type: Number,
    },
    departement: {
      type: Object,
      required: true,
      default: function () {
        return {
          id: null,
          label: "",
          added_at: "",
          updated_at: "",
          user: {
            id: null,
            nom: "",
            prenom: "",
          },
          archived: null,
        };
      },
    },
  },
  data() {
    return {
      openKebabMenu: false,
      closeDetailsDep: false,
      calendar,
      calendarStylo,
      chef,
      crayon,
      pencil,
      refresh,
      todo,
      xmark,
      profil,
      archiveOrange,
      archiveGreen,
      etatArchive: "",
      isEditAllowed: editAllowed

    };
  },
  methods: {
    toggleKebabMenu() {
      this.openKebabMenu = !this.openKebabMenu;
    },
    toggleDetailsCarte() {
      this.openDetailsCarte = !this.openDetailsCarte;
    },
    closeKebabMenu(event) {
      if (!this.$el.contains(event.target)) {
        this.openKebabMenu = false;
      }
    },
    closeDep() {
      this.$emit("closeDetailsDep", this.closeDetailsDep);
    },
    handleArchiveDepartement(departement) {
      departement.archived = !departement.archived;
      this.$emit("archiveConfirmed");
    },
    refreshData() {
      this.$emit("departementUpdated");
    },
    getBackgroundColorClass(letter) {
      const colorMap = {
        A: "bg-pink-400",
        B: "bg-green-500",
        C: "bg-blue-500",
        D: "bg-yellow-500",
        E: "bg-orange-500",
        F: "bg-purple-500",
        G: "bg-red-400",
        H: "bg-indigo-500",
        I: "bg-teal-500",
        J: "bg-cyan-500",
        K: "bg-lime-500",
        L: "bg-emerald-500",
        M: "bg-rose-500",
        N: "bg-amber-500",
        O: "bg-fuchsia-500",
        P: "bg-yellow-800",
        Q: "bg-teal-800",
        R: "bg-gray-500",
        S: "bg-cyan-400",
        T: "bg-lime-400",
        U: "bg-blue-700",
        V: "bg-orange-300",
        W: "bg-red-600",
        X: "bg-pink-600",
        Y: "bg-green-800",
        Z: "bg-indigo-700",
      };
      return colorMap[letter.toUpperCase()];
    },
    openModalEditionModal() {
      this.$refs.modalEdition.openModal(
        this.departement.label,
        this.departement.user.id
      );
    },
  },
  mounted() {
    document.addEventListener("click", this.closeKebabMenu);
  },
};
</script>
