<template>
  <router-link
    :to="route"
    class="flex gap-5 items-center hover:bg-slate-100 rounded duration-500 hover:ps-1"
  >
    <RoundedIcon :icon="icon" />
    <div class="text-slate-500 text-xs font-bold">
      {{ title }}
    </div>
  </router-link>
</template>

<script>
import RoundedIcon from "../../../../../components/RoundedIcon/RoundedIcon.vue";
export default {
  name: "ShortcutLink",
  components: {
    RoundedIcon,
  },
  props: {
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    route: {
      type: String,
      default: "",
    },
  },
};
</script>
