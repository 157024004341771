<template>
  <div class="bg-white rounded-t-lg flex gap-6 items-center">
    <SelectedElements :count="count" />
    <div class="flex items-center gap-6">
      <button class="mt-1" v-if="count > 0 && isEditAllowed">
        <ConfirmSuppression @confirm="handleConfirmation" />
      </button>
      <button class="mt-1" v-if="count > 0 && isEditAllowed">
        <ConfirmArchivage @confirm="handleArchive" :img="archiveGray" />
      </button>
      <button @click="printPage">
        <img :src="printer" alt="imprimer-icon" />
      </button>
      <button @click="exportCSV">
        <img :src="file_download" alt="exporter-icon" />
      </button>
    </div>
  </div>
</template>

<script>
import file_download from "../../assets/file_download.svg";
import trash from "../../assets/trash.svg";
import printer from "../../assets/printer.svg";
import archiveGray from "../../assets/archiveGray.svg";
import SelectedElements from "../SelectedElements/SelectedElements.vue";
import ConfirmSuppression from "../Popup/ConfirmSupression.vue";
import ConfirmArchivage from "../Popup/ConfirmArchivage.vue";
import { editAllowed } from "@/SPA/spa.services";

export default {
  components: {
    SelectedElements,
    ConfirmSuppression,
    ConfirmArchivage,
  },
  data() {
    return {
      selectedItems: [],
      file_download,
      trash,
      printer,
      archiveGray,
      confirmDeleteVisible: false,
      isEditAllowed:editAllowed
    };
  },
  props: {
    count: {
      Type: Number,
    },
  },
  methods: {
    handleConfirmation() {
      this.$emit("Confirmed");
    },
    handleArchive() {
      this.$emit("Archive");
    },
    printPage() {
      window.print();
    },
    exportCSV() {
      this.$emit("exportCSV");
    },
  },
};
</script>
