<template>
  <div class="bg-gray-50">
    <div class="pt-16 md:mx-28">
      <div
        class="w-full h-full pt-20 pb-8 flex flex-col banner-bg bg-cover rounded-lg"
      >
        <div class="flex items-center justify-between pb-5">
          <h1 class="text-4xl pl-10 text-white font-Inter font-extrabold">
            Gestion des matières
          </h1>
          <div class="pr-6">
            <ModalMatiere ref="modalMatiere" @update-table="refreshData" />
          </div>
        </div>
        <div class="flex items-center justify-end pr-12 gap-2">
          <button
            @click="$store.commit('toggleStatBloc')"
            class="flex items-center justify-center sm:w-28 w-8 h-8 pl-0 rounded-full border-2 border-gray-300 outline-none bg-gradient-to-r from-indigo-500 to-purple-500 text-white text-center cursor-pointer"
          >
            <span class="items-center mr-4 hidden sm:flex">Stats</span>
            <img :src="statschart" class="w-4 h-4" />
          </button>
          <div v-if="isEditAllowed">
            <import-matiere
              class="items-center mr-4 hidden sm:flex"
              @refresh-view="refreshData"
              @toggle-erreur="toggleErreur"
            />
          </div>
        </div>
      </div>
      <ModalErreur
        v-if="hasError"
        @toggle-erreur="toggleErreur"
        :reason="reason"
      />
      <div class="bg-white rounded-tl-10 rounded-tr-10">
        <div class="py-4 px-4">
          <StatMatieres v-if="$store.state.isStatOpen"></StatMatieres>
          <SearchField @search="handleSearch" />
        </div>
        <div></div>
        <div class="flex items-center justify-between">
          <div>
            <TogglePanneau @displayPanneau="displayPanneau" />
          </div>
          <div class="flex justify-end">
            <div class="w-36 mr-4">
              <v-select
                :items="semestres"
                v-model="selectedSemestre"
                label="Semestre"
                variant="outlined"
                class="h-16 text-sm text-slate-600"
              ></v-select>
            </div>
            <div class="w-60 pr-4">
              <v-select
                v-model="itemsPerPage"
                label="Nombres d'élements par page"
                :items="perPageOptions"
                variant="outlined"
                class="h-16 text-sm text-slate-600"
              >
              </v-select>
            </div>
          </div>
        </div>
        <div class="flex">
          <div v-if="togglePanneau" class="pr-3 absolute z-10">
            <FiltrePanneau @update-filter="handleFilterUpdate" />
          </div>
          <div class="flex-1" :class="{'overflow-x-auto': togglePanneau}">
            <v-data-table
              v-model="selected"
              :headers="getDynamicHeaders()"
              class="elevation-1"
              hide-default-header
              :items="filteredMatieres"
              return-object
              show-select
              :items-per-page="itemsPerPage"
              :page="page"
            >
              <template v-slot:[`item.label`]="{value}">
                <p class="font-bold">{{ value }}</p>
              </template>
              <template v-slot:[`item.departmentLabel`]="{value}">
                <div class="flex align-center">
                  <ButtonDisplay :label="value" />
                </div>
              </template>

              <template v-slot:[`item.volume_horaire`]="{value}">
                <p class="text-violet-600 font-semibold text-sm">
                  {{ value }}H
                </p>
              </template>
              <template v-slot:[`item.semestre`]="{value}">
                <p class="text-violet-600 font-semibold text-sm">{{ value }}</p>
              </template>
              <template v-slot:[`item.specialites`]="{value}">
                <p class="text-gray-500 text-sm font-normal">{{ value }}</p>
              </template>
              <template v-slot:[`item.natures`]="{value}">
                <ChipList :items="value" />
              </template>
              <template v-slot:[`item.niveau`]="{value}">
                <p class="text-gray-500 text-xs font-normal">{{ value }}</p>
              </template>
              <template v-slot:[`item.action`]="{item}" v-if="isEditAllowed">
                <v-icon class="mr-4" size="small">
                  <div class="flex items-center justify-center">
                    <ActionButton
                      :icon="editionIcon"
                      buttonText="Edition"
                      :item="item"
                      @action-clicked="editItem(item)"
                    />
                  </div>
                </v-icon>
              </template>

              <template v-slot:bottom>
                <div class="flex p-4 justify-between items-center">
                  <BlockActionMasse
                    :count="selectedCount"
                    @Confirmed="handleConfirmation"
                    @Archive="handleArchive"
                    @exportCSV="exportCSV"
                  />
                  <div class="ml-auto">
                    <v-pagination
                      variant="flat"
                      prev-icon="mdi-arrow-left"
                      next-icon="mdi-arrow-right"
                      active-color="blue-700 text-white"
                      :total-visible="5"
                      size="40"
                      rounded="circle"
                      v-model="page"
                      :length="pageCount"
                    />
                  </div>
                </div>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
      <img :src="logo" />
    </div>
  </div>
</template>
<script>
import gql from 'graphql-tag';
import apolloClient from '../../apolloClient';
import statschart from '../../assets/stats-chart.svg';
import ModalMatiere from '../../components/Modals/ModalMatiere/ModalMatiere.vue';
import ActionButton from '../../components/Buttons/ActionButton.vue';
import ChipList from '../../components/Chips/ChipList.vue';
import editionIcon from '../../assets/editionIcon.svg';
import ButtonDisplay from '../../components/Buttons/ButtonDisplay.vue';
import SearchField from '../../components/SearchField/SearchField.vue';
import BlockActionMasse from '../../components/BlockActionMasse/BlockActionMasse.vue';
import uploadIcon from '../../assets/upload.svg';
import {GET_MATIERE} from './Matiers.services';
import {editAllowed} from './../spa.services.js';
import TogglePanneau from '../../components/Panneau/Panneau/TogglePanneau/TogglePanneau.vue';
import FiltrePanneau from '../../components/Panneau/Panneau/FiltrePanneau/FiltrePanneau.vue';
import logo from '../../assets/searchIcon.svg';
import time from '@/assets/time.svg';
import table from '@/assets/table.svg';
import x from '@/assets/x.svg';
import StatMatieres from './StatsMatieres/StatMatieres.vue';
import ImportMatiere from '@/components/Modals/ModalMatiere/ImportMatiere.vue';
import ModalErreur from '@/components/Popup/Erreurs/ModalErreur.vue';
export default {
  components: {
    ModalMatiere,
    ActionButton,
    ChipList,
    ButtonDisplay,
    SearchField,
    BlockActionMasse,
    TogglePanneau,
    FiltrePanneau,
    StatMatieres,
    ImportMatiere,
    ModalErreur,
  },
  data() {
    return {
      selected: [],
      hasError: false,
      errorReason: '',
      filterCriteria: {
        departement: '',
        specialite: '',
        categorie: '',
        logo,
        time,
        table,
        x,
      },
      headers: [
        {
          title: 'Libellé',
          key: 'label',
          align: 'center',
          classes: 'libelle-header',
        },
        {title: 'Département', key: 'departmentLabel', align: 'center'},
        {
          title: "Nombre d'heure réservés",
          key: 'volume_horaire',
          align: 'center',
        },
        {title: 'Semestre', key: 'semestre', align: 'center'},
        {title: 'Spécialité', key: 'specialites', align: 'center'},
        {title: 'Natures de séances', key: 'natures', align: 'center'},
        {title: 'Niveau', key: 'niveau', align: 'center'},
      ],
      matieres: [],
      semestres: ['Tout', 'S1', 'S2'],
      selectedSemestre: 'Tout',
      editionIcon,
      statschart,
      uploadIcon,
      page: 1,
      itemsPerPage: 5,
      perPageOptions: [
        {title: '5 par page', value: 5},
        {title: '10 par page', value: 10},
        {title: '20 par page', value: 20},
        {title: '50 par page', value: 50},
      ],
      searchQuery: '',
      selectedCount: 0,
      isEditAllowed: editAllowed,
      togglePanneau: false,
    };
  },
  mounted() {
    this.fetchMatieres();
  },
  watch: {
    selected: {
      handler() {
        this.countElements();
      },
      deep: true,
    },
  },
  methods: {
    toggleErreur(response = null) {
      this.hasError = !this.hasError;
      if (response && response.success == false && response.message) {
        this.reason = response.message;
      }
    },
    countElements() {
      this.selectedCount = this.selected.length;
    },
    handleConfirmation() {
      this.deleteMatiere();
    },
    handleArchive() {
      this.archiveMatiere();
    },
    async refreshData() {
      await this.fetchMatieres(true);
    },
    async fetchMatieres(refetch = false) {
      try {
        const response = await apolloClient.query({
          query: GET_MATIERE,
          fetchPolicy: refetch ? 'network-only' : 'cache-first',
        });
        const matieres = response.data.getAllMatieres;
        this.matieres = matieres;
      } catch (error) {
        console.error('Error fetching Matieres:', error);
      }
    },
    async deleteMatiere() {
      const selectedIds = this.selected.map(item => item.id);

      try {
        await apolloClient.mutate({
          mutation: gql`
            mutation deleteMatiere($ids: [Int]) {
              deleteMatiere(ids: $ids)
            }
          `,
          variables: {
            ids: selectedIds,
          },
        });

        this.matieres = this.matieres.filter(
          item => !selectedIds.includes(item.id)
        );
        this.selected = [];
        this.countElements();
      } catch (error) {
        console.error('Error deleting items:', error);
      }
    },
    async archiveMatiere() {
      const selectedIds = this.selected.map(item => item.id);
      try {
        await apolloClient.mutate({
          mutation: gql`
            mutation matiere_prs($ids: [Int], $archived: Boolean) {
              archiveMatiere(ids: $ids, archived: $archived)
            }
          `,
          variables: {
            ids: selectedIds,
            archived: true,
          },
        });
        this.selected = [];
        this.countElements();
        this.fetchMatieres(true);
      } catch (error) {
        console.error("Une erreur s'est produite :", error);
      }
    },
    convertToCSV() {
      const delimiter = ',';
      const headerMapping = {
        label: 'Libelle',
        volume_horaire: 'Volume horaire',
        specialites: 'Specialite',
        departmentLabel: 'Departement',
        natures: 'Natures de seance',
      };

      const header = Object.keys(this.matieres[0])
        .filter(
          key =>
            key !== '__typename' && !['id_specialite', 'dep_id'].includes(key)
        )
        .map(key => headerMapping[key] || key)
        .join(delimiter);

      const csvRows = [
        header,
        ...this.matieres.map(matiere =>
          Object.values(matiere)
            .filter(
              (_, index) => index !== Object.keys(matiere).indexOf('__typename')
            )
            .filter((_, index) => ![4, 6].includes(index))
            .map(value => {
              if (typeof value === 'string') {
                return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
              }
              return value;
            })
            .join(delimiter)
        ),
      ];
      return csvRows.join('\n');
    },
    exportCSV() {
      if (!this.matieres.length) {
        alert('Aucun élément affiché');
        return;
      }
      const csvContent = this.convertToCSV();
      const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'});
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'matieres.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    handleSearch(query) {
      this.searchQuery = query;
    },
    editItem(item) {
      this.$refs.modalMatiere.editItem(item);
    },
    getDynamicHeaders() {
      if (this.isEditAllowed) {
        return [
          ...this.headers,
          {title: 'Action', key: 'action', align: 'center'},
        ];
      }
      return this.headers;
    },
    displayPanneau() {
      if (this.togglePanneau == false) {
        this.filterCriteria.departement = '';
        this.filterCriteria.specialite = '';
        this.filterCriteria.categorie = '';
      }
      this.togglePanneau = !this.togglePanneau;
    },
    handleFilterUpdate({type, value}) {
      this.updateFilterCriteria({type, value});
    },
    updateFilterCriteria({type, value}) {
      this.filterCriteria[type] = value;
    },
    filterMatiere(matiere) {
      const {departement, specialite, categorie} = this.filterCriteria;
      const isDepartementMatched = departement.length
        ? departement.includes(matiere.departmentLabel)
        : true;
      const isSpecialiteMatched = specialite.length
        ? specialite.some(spec => matiere.specialites.includes(spec))
        : true;
      const isCategorieMatched = categorie.length
        ? categorie.some(cat => matiere.natures.includes(cat))
        : true;
      return isDepartementMatched && isSpecialiteMatched && isCategorieMatched;
    },
  },
  computed: {
    pageCount() {
      return Math.ceil(this.filteredMatieres.length / this.itemsPerPage);
    },
    filteredMatieres() {
      return this.matieres
        .filter(this.filterMatiere)
        .filter(
          matiere =>
            (matiere.label &&
              matiere.label
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase())) ||
            (matiere.departmentLabel &&
              matiere.departmentLabel
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase())) ||
            (matiere.specialites &&
              matiere.specialites
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase())) ||
            (matiere.natures &&
              matiere.natures.some(nature =>
                nature.toLowerCase().includes(this.searchQuery.toLowerCase())
              )) ||
            (matiere.niveau &&
              matiere.niveau
                .toLowerCase()
                .includes(this.searchQuery.toLowerCase()))
        )
        .filter(
          matiere =>
            !this.selectedSemestre ||
            this.selectedSemestre === 'Tout' ||
            matiere.semestre === this.selectedSemestre
        );
    },
  },
};
</script>
<style>
.banner-bg {
  background-image: url('../../assets/officeDesk.png');
}
.v-data-table-header__content {
  font-weight: bold !important;
}
.v-data-table-header__content .libelle-header {
  font-weight: bold;
}
.v-table__wrapper th:first-child {
  visibility: visible;
}
.v-select-list .v-field__input {
  display: none;
}
.v-table__wrapper {
  overflow: visible !important;
}
.overflow-x-auto .v-table__wrapper {
  overflow-x: auto !important;
}
</style>
