import gql from "graphql-tag";
export const GET_DEPARTEMENTS = gql`
query departement_prs {
             getAllDepartements {
                label
              }
            }
            `;
export const ADD_ENSEIGNANT = gql`
mutation enseignant_prs 
            (
            $nom:String,
            $prenom:String,
            $cin_off:String,
            $mail:String,
            $grade_fr:String,
            $gendre:String,
            $departement:String,
            $situation:String, 
            $indentifiant_unique:String ,
            $nom_ar:String, 
            $prenom_ar:String,
            $cin:String,
            $date_n_ar:String ,
            $lieu_ar:String,
            $lieu_fr:String,
            $justification1:String, 
            $justification2:String,
            $dep_id:Int)
        {
        addEnseignant
        (
        nom:$nom ,
        prenom:$prenom,
        cin_off:$cin_off,
        mail:$mail,
        grade_fr:$grade_fr,
        gendre:$gendre, 
        departement:$departement,
        situation:$situation, 
        indentifiant_unique:$indentifiant_unique,
        nom_ar:$nom_ar,
        prenom_ar:$prenom_ar, 
        cin:$cin, 
        date_n_ar:$date_n_ar,
        lieu_ar:$lieu_ar,
        lieu_fr:$lieu_fr, 
        justification1:$justification1, 
        justification2:$justification2,
        dep_id:$dep_id
        )

        }
` 
export const UPDATE_ENSEIGNANT = gql`
mutation updateEnseignant($id: Int!, $nom: String, $prenom: String, $cin_off: String, $mail: String, $grade_fr: String, $gendre: String, $departement: String, $situation: String, $indentifiant_unique: String, $nom_ar: String, $prenom_ar: String, $cin: String, $date_n_ar: String, $lieu_ar: String, $lieu_fr: String, $justification1: String, $justification2: String, $dep_id: Int) {
  updateEnseignant(
    id: $id
    nom: $nom
    prenom: $prenom
    cin_off: $cin_off
    mail: $mail
    grade_fr: $grade_fr
    gendre: $gendre
    departement: $departement
    situation: $situation
    indentifiant_unique: $indentifiant_unique
    nom_ar: $nom_ar
    prenom_ar: $prenom_ar
    cin: $cin
    date_n_ar: $date_n_ar
    lieu_ar: $lieu_ar
    lieu_fr: $lieu_fr
    justification1: $justification1
    justification2: $justification2
    dep_id: $dep_id
  )
}
`;
export const IMPORT_ENSEIGNANTS = gql`
mutation ImportEnseignants($file: String!, $dep_id: Int!) {
  importEnseignants(file: $file, dep_id: $dep_id)
}
`;

export const ENSEIGNANT_GRADES = [
  { code: "p", label: "Professeur" },
  { code: "mc", label: "Maître de conférence" },
  { code: "ma", label: "Maître assistant" },
  { code: "a", label: "Assistant" },
  { code: "pt", label: "Professeur technologue" },
  { code: "mt", label: "Maître technologue" },
  { code: "t", label: "Technologue" },
  { code: "at", label: "Assistant technologue" },
  {code : 'ppea', label: 'Professeur principal émérite agrégé'},
  {code : 'ppe', label: 'Professeur Principal Emérite'},
  {code : 'ppes' , label: 'Professeur Principal D’enseignement Secondaire'},
  {code : 'pees',  label: 'Professeur Emérite de L’enseigement secondaire'}
]