<template>
  <div
    :class="`px-3 py-4 rounded-3xl border-2 flex flex-1 grow align-center ${getColor(
      'bg'
    )} ${getColor('border')}`"
  >
    <div
      :class="`w-12 h-12 rounded-full flex justify-center items-center ${getColor(
        'circle'
      )}`"
    >
      <img :src="getImage" alt="" class="w-5 h-5" />
    </div>
    <div class="pl-4 flex flex-col">
      <span class="text-gray-800 text-xs font-semibold text-base"
        >Nb.{{ title }}</span
      >
      <span :class="`font-semibold text-xs ${getColor('text')}`"
        >{{ number }} {{ element }}
      </span>
    </div>
  </div>
</template>
<script>
import book from "../../assets/book.svg";
import openBook from "../../assets/openBook.svg";
import team from "../../assets/team.svg";
import notification from "../../assets/notification.svg";
export default {
  name: "CarteNombres",
  props: {
    title: {
      Type: String,
      Default: "title",
    },
    element: {
      String,
      Default: "element",
    },
    number: Number,
  },
  data() {
    return {
      book,
      openBook,
      team,
      notification,
    };
  },
  methods: {
    getColor(type) {
      const labels = [
        ["Départements"],
        ["Emplois"],
        ["Enseignants"],
        ["Classes"],
      ];
      const index = labels.findIndex((list) => list.includes(this.element));

      const circleColors = [
        "bg-orange-300",
        "bg-lime-300",
        "bg-pink-300",
        "bg-sky-300",
      ];
      const bgColors = [
        "bg-orange-50",
        "bg-lime-50",
        "bg-pink-50",
        "bg-sky-50",
      ];
      const textColors = [
        "text-orange-400",
        "text-lime-500",
        "text-pink-500",
        "text-sky-600",
      ];
      const borderColors = [
        "border-orange-400",
        "border-lime-500",
        "border-pink-500",
        "border-sky-600",
      ];
      let colors;
      switch (type) {
        case "circle":
          colors = circleColors;
          break;
        case "bg":
          colors = bgColors;
          break;
        case "text":
          colors = textColors;
          break;
        case "border":
          colors = borderColors;
          break;
        default:
          colors = bgColors;
          break;
      }
      return colors[index];
    },
  },
  computed: {
    getImage() {
      switch (this.element) {
        case "Départements":
          return this.book;
        case "Emplois":
          return this.notification;
        case "Enseignants":
          return this.team;
        case "Classes":
          return this.openBook;
        default:
          return this.book;
      }
    },
  },
};
</script>
