<template>
  <div>
    <div
      class="fixed w-full h-full left-0 top-0 bg-black opacity-25 z-40"
    ></div>
    <div
      class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50"
    >
      <div
        class="bg-white p-6 rounded-lg shadow-lg w-99 relative fadeIn min-w-100"
      >
        <img :src="fail" alt="Union" class="absolute -mt-14 left-6 w-18 h-20" />

        <h2 class="text-2xl font-bold mb-2 mt-12 text-gray-800">{{ title }}</h2>
        <p class="mb-10 text-gray-500 text-sm">
          <span v-html="message"> </span>
        </p>
        <div class="z-10 relative">
          <div class="flex justify-center gap-3">
            <GradientButton
              text="Annuler"
              color="redLite"
              type="soft"
              @btn-click="$emit('b-reject')"
            />
            <GradientButton
              text="Confirmer"
              color="red"
              type="soft"
              @btn-click="$emit('b-confirm')"
            />
          </div>
        </div>
        <img
          :src="redBubbles"
          alt="Red Bubbles"
          class="absolute bottom-0 left-0 w-24 z-0 rounded-lg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import redBubbles from "../../../assets/red_bubbles.png";
import fail from "../../../assets/fail.svg";
import GradientButton from "../../Buttons/GradientButton/GradientButton.vue";

export default {
  name: "ColoredModal",
  props: {
    title: {
      type: String,
      default: "Confirmation",
    },
    message: {
      type: String,
      default:
        "Erreur d'opération est survenu. Veuillez ré-essayez ultérieurement.",
    },
  },
  components: {
    GradientButton,
  },
  data() {
    return {
      redBubbles,
      fail,
    };
  },
};
</script>
