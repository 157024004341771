<template>
  <SimpleCard fullWidth class="pl-6 py-6 pr-2">
    <div class="flex flex-col gap-6 overflow-y-auto max-h-85 pr-4">
      <div class="" v-if="isLoading">Chargement en cours ...</div>
      <ItemActiviteRecentes
        v-for="(elt, ind) in elements"
        v-else
        :date="elt.op_date"
        :icon="elt.ac_type"
        :label="elt.label"
        :user="elt.personne"
        :key="ind"
      />
    </div>
  </SimpleCard>
</template>

<script>
import apolloClient from "@/apolloClient";
import SimpleCard from "../../../../components/Cards/SimpleCard/SimpleCard.vue";
import ItemActiviteRecentes from "./ItemActiviteRecentes.vue";
import { STAT_ACTIVITY_RECENT } from "../../Dashboard.service";

export default {
  name: "DashActivitesRecents",
  props: {
    icon: {
      type: String,
      default: "",
    },
  },
  components: {
    SimpleCard,
    ItemActiviteRecentes,
  },
  data() {
    return {
      elements: [],
      isLoading: true,
    };
  },
  mounted() {
    this.listActivites();
  },
  methods: {
    listActivites() {
      apolloClient
        .query({
          query: STAT_ACTIVITY_RECENT,
          fetchPolicy: "no-cache",
        })
        .then((res) => {
          this.elements = res.data.activities;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
