import gql from "graphql-tag";
export const FETCH_ENSEIGNANTS = gql`
  query getAllEnseignants($etab: String, $departement: String) {
    getAllEnseignants(etab: $etab, departement: $departement) {
      id
      nom
      prenom
      nom_ar
      prenom_ar
      indentifiant_unique
      gendre
      grade_fr
      situation
      grade_trad
      mail
      cin_off
      dep_id
      departement {
        id
        label
      }
    }
  }
`;
export const GETDEPARTEMENT_BY_ID_DIRECTEUR = gql`
  query getDepartementByIdDirecteur($id_directeur: Int) {
    getDepartementByIdDirecteur(id_directeur: $id_directeur) {
      label
    }
  }
`;
export const GET_ENSEIGNANT_BY_ID = gql`
query getEnseignantById($id: Int!) {
  getEnseignantById(id: $id) {
    id
    cin
    cin_off
    nom_ar
    prenom_ar
    grade_ar
    indentifiant_unique
    date_n_ar
    lieu_ar
    lieu_fr
    justificatif1
    justificatif2
    nom
    prenom
    gendre
    grade_fr
    situation
    grade_trad
    dep_id
    mail
    departement {
      id
      label
    }
  }
}
`;
export const DELETE_ENSEIGNANT = gql`
mutation deleteEnseignant($id: Int) {
  deleteEnseignant(id:$id)
}
`;
