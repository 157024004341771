import gql from "graphql-tag";
export const GET_MATIERE = gql`
  query {
    getAllMatieres {
      id
      label
      niveau
      semestre
      dep_id
      volume_horaire
      id_specialite
      natures
      departmentLabel
      specialites
    }
  }
`;


export const GET_STATS_MATIERES = gql`
  query GetStatsMatieres {
    getMatiereStats {
      vol_horaire
      specialite
      nombre_matieres
    }
  }
`;
export const GET_PANNEAU_DATA = gql`
  query {
    getAllMatieres {
      departmentLabel
      specialites
    }
  }
`;
