<template>
  <div class="mx-4 py-4">
    <form @submit.prevent="handleSubmit">
      <div class="w-full bg-gray-100 px-5 pb-5 pt-4  rounded-lg flex align-center justify-between gap-6 flex-wrap">
        <div class="relative flex-1">
          <input class="border-2 border-gray-300 bg-white h-12 w-full px-12 pr-16 rounded-lg text-sm focus:outline-none"
            type="search" :placeholder="placeholderText" v-model="searchQuery" @keydown.enter.prevent="handleSubmit" />
          <button type="submit" class="absolute left-0 bottom-3.5 mt-6 ml-4">
            <img :src="searchIcon" alt="Search Icon" class="h-5 w-5 fill-current" />
          </button>
        </div>
        <select v-model="selectedDepartement"
          class="bg-white h-12 border border-gray-300 w-64 text-stale-600 rounded-lg px-4 text-sm focus:outline-none">
           <option value="" >Tout département</option>
          <option v-for="(option, index) in DepartementOptions" :key="index" :value="option.label">{{ option.label }}</option>
        </select>
        <button type="submit" class="bg-blue-900 text-white font-medium rounded-lg h-11 py-2 px-6">
          {{ buttonText }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import searchIcon from '../../assets/searchIcon.svg';

export default {
  props: {
    placeholderText: {
      type: String,
      default: 'Recherche..'
    },
    buttonText: {
      type: String,
      default: 'Valider'
    },
    DepartementOptions: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      searchQuery: '',
      searchIcon,
      selectedDepartement: '',
    }
  },
  methods: {
    handleSubmit() {
      let searchData = {
        searchQuery: this.searchQuery,
        selectedDepartement: this.selectedDepartement
    };
      this.$emit('search', searchData);
    }
  }
}
</script>