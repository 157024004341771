<template>
  <label class="switch relative inline-block w-16 h-8" :class="{ 'cursor-not-allowed opacity-50': isDisabled }">
    <input type="checkbox" v-model="isChecked" @change="toggle" class="opacity-0 w-0 h-0" :disabled="isDisabled">
    <span :class="[
        isChecked 
          ? (textYes === '' && textNo === '' ? 'bg-blue-600' : 'bg-teal-600') 
          : (textYes === '' && textNo === '' ? 'bg-gray-300' : 'bg-red-500')
      ]"
      class="slider absolute cursor-pointer top-0 left-0 right-0 bottom-0 transition duration-300 flex items-center justify-between font-medium text-gray-600 rounded-full px-2">
      <span class="text-xxs absolute"
        :class="{ 'text-white  mr-2 left-2': isChecked, 'text-white ml-2 right-1.5': !isChecked }">{{
          isChecked ? textYes : textNo }}</span>
      <span
        class="slider-button w-6 h-6 bg-white rounded-full shadow-md transform transition-transform -translate-x-1 duration-300"
        :class="{ 'translate-x-7': isChecked }"></span>
    </span>
  </label>
</template>

<script>
export default {
  name: 'v-toggle',
  props: {
    textYes: {
      type: String,
      default: 'On',
    },
    textNo: {
      type: String,
      default: 'Off',
    },
    value: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isChecked: this.value
    };
  },
  watch: {
    value(newValue) {
      this.isChecked = newValue;
    }
  },
  methods: {
    toggle() {
      this.isChecked = !this.isChecked;
      this.$emit('input', this.isChecked);
      this.$emit('toggle-input', this.isChecked);
    }
  }
};
</script>

<style>
.text-xxs {
  font-size: x-small;
}
</style>
