<template>
  <v-dialog v-model="modalOpen" max-width="500">
    <template v-slot:activator="{ on }">
      <ActionButton
        v-on="on"
        :icon="info"
        buttonText="Détails"
        @click="openModal"
      />
    </template>
    <v-card class="rounded-lg">
      <div class="flex items-center justify-between py-2 pl-5 border-b">
        <img :src="info" alt="" class="w-7 h-7" />
        <h1 class="text-sm text-blue-600 font-medium pl-2">Détails</h1>
        <img
          :src="Button"
          class="ml-auto cursor-pointer pr-2"
          @click="closeModal"
        />
      </div>
      <div class="mt-4">
        <div>
          <span class="text-base font-semibold pl-10 pt-6"
            >Informations générales</span
          >
          <div class="rounded-md border-2 border-neutral-200 mx-10 my-4">
            <div class="p-4 grid grid-rows-1 gap-4">
              <div class="flex justify-between">
                <span class="text-gray-800 text-sm">Nom en arabe: </span>
                <span class="text-gray-600 text-sm font-bold">{{
                  nom_ar ? nom_ar : "Non renseigné"
                }}</span>
              </div>
              <div class="flex justify-between">
                <span class="text-gray-800 text-sm">Prénom en arabe: </span>
                <span class="text-gray-600 text-sm font-bold">{{
                  prenom_ar ? prenom_ar : "Non renseigné"
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          <span class="text-base font-semibold pl-10 pt-6"
            >Informations personnels</span
          >
          <div class="rounded-md border-2 border-neutral-200 mx-10 my-4">
            <div class="p-4 grid grid-rows-1 gap-4">
              <div class="flex justify-between">
                <span class="text-gray-800 text-sm">Identifiant unique: </span>
                <span class="text-gray-600 text-sm font-bold">{{
                  identifiant_unique ? identifiant_unique : "Non renseigné"
                }}</span>
              </div>
              <div class="flex justify-between">
                <span class="text-gray-800 text-sm">Date de naissance: </span>
                <span class="text-gray-600 text-sm font-bold">{{
                  date_naissance ? date_naissance : "Non renseigné"
                }}</span>
              </div>
              <div class="flex justify-between">
                <span class="text-gray-800 text-sm">Lieu de naissance: </span>
                <span class="text-gray-600 text-sm font-bold">{{
                  lieu_naissance ? lieu_naissance : "Non renseigné"
                }}</span>
              </div>
              <div class="flex justify-between">
                <span class="text-gray-800 text-sm"
                  >Lieu de naissance en arabe:
                </span>
                <span class="text-gray-600 text-sm font-bold">{{
                  lieu_naissance_ar ? lieu_naissance_ar : "Non renseigné"
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div v-if="justif1 || justif2">
          <span class="text-base font-semibold pl-10 pt-6">Pièces jointes</span>
          <div
            class="rounded-md border-2 border-neutral-200 mx-10 my-4 flex justify-center"
          >
            <div class="p-4 flex">
              <a
                v-if="justif1"
                :href="api_url + '/uploads/' + justif2"
                target="_blank"
                class="mx-4 w-36 h-10 flex items-center rounded-3xl bg-gradient-to-r from-blue-500 to-cyan-500"
              >
                <span class="flex ml-3 text-white font-semibold">
                  Justificatif 1</span
                >
                <img :src="fileDownload" alt="" class="ml-2 w-5 h-5" />
              </a>
              <a
                class="mx-4 w-36 h-10 flex items-center rounded-3xl bg-gradient-to-r from-blue-500 to-cyan-500"
                target="_blank"
                v-if="justif2"
                :href="api_url + '/uploads/' + justif2"
              >
                <span class="flex ml-3 text-white font-semibold">
                  Justificatif 2</span
                >
                <img :src="fileDownload" alt="" class="ml-2 w-5 h-5" />
              </a>
            </div>
          </div>
        </div>
        <div>
          <span class="text-neutral-400 text-xs pl-10"
            >édité le : {{ updated_at }}</span
          >
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import plus from "../../assets/plus-sm.png";
import Button from "../../assets/Button.png";
import info from "../../assets/info.svg";
import fileDownload from "../../assets/fileDownload.svg";
import ActionButton from "../Buttons/ActionButton.vue";

const api_url = process.env.VUE_APP_API_URL;

export default {
  name: "CarteDetailsEnseignant",
  components: {
    ActionButton,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    nom_ar: String,
    prenom_ar: String,
    grade_ar: String,
    identifiant_unique: String,
    date_naissance: String,
    lieu_naissance_ar: String,
    lieu_naissance: String,
    update_at: String,
    justif1: {
      type: String,
      default: "",
    },
    justif2: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modalOpen: false,
      plus,
      Button,
      info,
      fileDownload,
      api_url,
    };
  },
  methods: {
    openModal() {
      this.modalOpen = true;
      this.$emit("showDetails");
    },
    closeModal() {
      this.modalOpen = false;
    },
  },
};
</script>
