<template>
  <SimpleCard
    fullWidth
    noShadow
    :style="{ backgroundImage: `url(${helpBackground})` }"
    class="bg-cover bg-center flex h-96.5 items-center justify-center shadow opacity-90 hover:opacity-100 hover:shadow-lg transition duration-500"
  >
    <div class="text-white font-semibold text-center px-6">
      Avez-vous besoin de l'aide ?
    </div>
  </SimpleCard>
</template>

<script>
import helpBackground from "../../../../../assets/help-background.svg";
import SimpleCard from "../../../../../components/Cards/SimpleCard/SimpleCard.vue";

export default {
  name: "DashProfileHelp",
  components: {
    SimpleCard,
  },
  data() {
    return {
      helpBackground,
    };
  },
};
</script>
