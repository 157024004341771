<template>
    <v-dialog v-model="modalOpen" max-width="500">
        <v-card class="rounded-lg px-5">
            <div class="flex items-center justify-between py-2 border-b">
                <h1 class="text-xl font-semibold">{{ selectedSalleId ? 'Edition de salle' : 'Ajout de salle' }}</h1>
                <img :src="Button" class="ml-auto cursor-pointer" @click="closeModal">
            </div>
            <div class="mt-4">
                <label class="mb-2 block font-inter text-base font-normal leading-6 text-left">Département</label>
                <select class="w-full appearance-none border border-gray-300 rounded-md py-2 pl-4 pr-10 outline-none"
                    v-model="selectedOptions.departement" @change="clearError('departement')">
                    <option value="" disabled hidden>Veuillez sélectionner</option>
                    <option v-for="(option, index) in fields[0].options" :key="index" :value="option.id">
                        {{ option.label }}
                    </option>
                </select>
                <span v-if="fields[0].error" class="absolute flex items-center text-red-500 text-xs">
                    <img :src="redinfo" class="h-3 w-3 mr-1">
                    {{ fields[0].error }}
                </span>
            </div>
            <div class="mt-4">
                <label class="mb-2 block font-inter text-base font-normal leading-6 text-left">Libellé</label>
                <input type="text" v-model="selectedOptions.libelle" @input="clearError('libelle')"
                    class="w-full border border-gray-300 rounded-md py-2 pl-4 pr-10 outline-none"
                    placeholder="Saisissez le libellé ici">
                <span v-if="fields[1].error" class="absolute flex items-center text-red-500 text-xs">
                    <img :src="redinfo" class="h-3 w-3 mr-1">
                    {{ fields[1].error }}
                </span>
            </div>
            <div class="mt-4">
                <label class="mb-2 block font-inter text-base font-normal leading-6 text-left">Bloc</label>
                <input type="text" v-model="selectedOptions.bloc" @input="clearError('bloc')"
                    class="w-full border border-gray-300 rounded-md py-2 pl-4 pr-10 outline-none"
                    placeholder="Saisissez le bloc ici">
                <span v-if="fields[2].error" class="absolute flex items-center text-red-500 text-xs">
                    <img :src="redinfo" class="h-3 w-3 mr-1">
                    {{ fields[2].error }}
                </span>
            </div>
            <div class="mt-4">
                <label class="mb-2 block font-inter text-base font-normal leading-6 text-left">État</label>
                <select class="w-full appearance-none border border-gray-300 rounded-md py-2 pl-4 pr-10 outline-none"
                    v-model="selectedOptions.etat" @change="clearError('etat')">
                    <option value="" disabled hidden>Veuillez sélectionner</option>
                    <option v-for="(option, index) in fields[3].options" :key="index" :value="option.id">
                        {{ option.label }}
                    </option>
                </select>
                <span v-if="fields[3].error" class="absolute flex items-center text-red-500 text-xs">
                    <img :src="redinfo" class="h-3 w-3 mr-1">
                    {{ fields[3].error }}
                </span>
            </div>
            <v-card-actions class="gap-2 flex justify-end mt-2 mb-2">
                <button
                    class="bg-gray-300 rounded-full px-6 outline-none text-black font-inter text-sm font-semibold leading-10 tracking-normal text-center"
                    @click="closeModal">Annuler</button>
                <button
                    class="bg-blue-700 rounded-full px-6 outline-none font-inter text-sm font-semibold leading-10 tracking-normal text-center text-white"
                    @click="validateChamps">Valider</button>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Button from '../../../assets/Button.png';
import redInfo from '../../../assets/redinfo.svg';
import { UPDATE_SALLE, ADD_SALLE } from './ModalSalle.service.js';
import { GET_DEPARTEMENTS } from '../../Modals/ModalEmploisDeTemps/ModalEmploisDeTemps.service.js';
import apolloClient from '../../../apolloClient';

export default {
    components: {
    },
    data() {
        return {
            redInfo,
            modalOpen: false,
            Button,
            selectedSalleId: null,
            decryptedType: '',
            fields: [
                { label: 'Département', options: [], error: '' },
                { label: 'Libellé', options: [], error: '' },
                { label: 'Bloc', options: [], error: '' },
                {
                    label: 'État', options: [
                        { id: 'libre', label: 'Exploitable' },
                        { id: 'maintenance', label: 'Maintenance' },
                        { id: 'inactif', label: 'Inactif' },
                        { id: 'reserve', label: 'Réservé' }
                    ], error: ''
                }
            ],
            selectedOptions: {
                departement: "",
                libelle: "",
                bloc: "",
                etat: "",
            },
            errorMsg: '',
            acces: false,
            error: '',
            updating: false
        };
    },
    methods: {
        openModal() {
            this.modalOpen = true;
        },
        closeModal() {
            this.selectedSalleId = null;
            this.selectedOptions.departement ="";
            this.selectedOptions.libelle = null;
            this.selectedOptions.bloc = null;
            this.selectedOptions.etat = "";
            this.modalOpen = false;
        },
        modifierSalle(salleData) {
            this.selectedSalleId = salleData.idSalle;
            this.selectedOptions.departement = salleData.idDep;
            this.selectedOptions.libelle = salleData.title;
            this.selectedOptions.bloc = salleData.bloc;
            this.selectedOptions.etat = salleData.etat;
            this.modalOpen = true;
        },
        async handleSalleAction() {
            const mutation = this.selectedSalleId ? UPDATE_SALLE : ADD_SALLE;
            const variables = {
                label: this.selectedOptions.libelle,
                dep_id: this.selectedOptions.departement,
                etat: this.selectedOptions.etat,
                bloc: this.selectedOptions.bloc
            };
            if (this.selectedSalleId!=null) {
                variables.id = this.selectedSalleId;
            }
            try {
                
                await apolloClient.mutate({ mutation, variables });
                this.$emit('refresh-view');
            } catch (error) {
                console.error("Error handling salle action:", error);
            }
            this.closeModal();
        },
        validateChamps() {
            let isValid = true;

            this.fields.forEach((field) => {
                field.error = '';
                switch (field.label) {
                    case 'Département':
                        if (!this.selectedOptions.departement) {
                            field.error = 'Le champ Département est obligatoire.';
                            isValid = false;
                        }
                        break;
                    case 'Libellé':
                        if (!this.selectedOptions.libelle) {
                            field.error = 'Le champ Libellé est obligatoire.';
                            isValid = false;
                        }
                        break;
                    case 'Bloc':
                        if (!this.selectedOptions.bloc) {
                            field.error = 'Le champ Bloc est obligatoire.';
                            isValid = false;
                        }
                        break;
                    case 'État':
                        if (!this.selectedOptions.etat) {
                            field.error = 'Le champ État est obligatoire.';
                            isValid = false;
                        }
                        break;
                }
            });

            if (isValid) {
                this.handleSalleAction();
            }
        },
        clearError(field) {
            switch (field) {
                case 'departement':
                    this.fields.find(f => f.label === 'Département').error = '';
                    break;
                case 'libelle':
                    this.fields.find(f => f.label === 'Libellé').error = '';
                    break;
                case 'bloc':
                    this.fields.find(f => f.label === 'Bloc').error = '';
                    break;
                case 'etat':
                    this.fields.find(f => f.label === 'État').error = '';
                    break;
            }
            this.error = '';
        },
        async getDepartements() {
            try {
                const response = await apolloClient.query({
                    query: GET_DEPARTEMENTS,
                });
                this.fields[0].options = response.data.getAllDepartements.map(departement => ({
                    id: departement.id,
                    label: departement.label
                }));
            } catch (error) {
                console.error('erreur de recuperation departements :', error);
            }
        },
    },
    mounted() {
        this.getDepartements();
    }
};
</script>