<template>
    <div class="flex flex-row ">
        <div class="text-gray-800  font-semibold w-70" v-if="title">{{ title }} :</div>
        <div class="text-gray-500 text-capitalize text-sm font-medium grow">{{ value }}</div>
    </div>
</template>
<script>
export default {
    name: "InformationRow",
    props: {
        title: { type: String, required: true },
        value: { type: String, required: true }
    }
}
</script>