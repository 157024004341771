<template>
  <div class="relative">
    <div class="w-full h-4 absolute inset-0 flex items-center justify-center">
      <div class="w-full h-1 bg-gray-300 pointer-events-none"></div>
    </div>

    <div class="flex w-full items-center justify-between mt-4">
      <div v-for="(step, index) in steps" :key="index" class="flex relative">
        <div
          @click="selectStep(step)"
          class="flex-shrink-0 w-10 h-10 rounded-full inline-flex items-center justify-center relative z-10 -translate-y-3 cursor-pointer"
          :class="modelValue === step.value ? 'bg-orange-500' : 'bg-orange-50'"
        >
          <span
            :class="
              modelValue === step.value ? 'text-white' : 'text-orange-500'
            "
            class="text-sm"
            >{{ step.label }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepperComponent',
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    steps: {
      type: Array,
      default: () => [
        {label: '1h', value: '1h'},
        {label: '1h30', value: '1h30'},
      ],
    },
    isWrite: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    selectStep(step) {
      if (this.isWrite) {
        this.$emit('update:modelValue', step.value);
      }
    },
  },
};
</script>
