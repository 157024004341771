<template>
  <div class="mx-4 my-4">
    <form @submit.prevent="handleSubmit">
      <div class="w-full h-24 bg-gray-100 px-5 pb-5 pt-4  rounded-lg flex align-center justify-between gap-6">
        <div class="pt-2 relative mx-auto text-gray-600 h-full w-full">
          <input class="border-2 border-gray-300 bg-white h-12 w-full px-12 pr-16 rounded-lg text-sm focus:outline-none"
            type="search" :placeholder="placeholderText" v-model="searchQuery" @keydown.enter.prevent="handleSubmit"/>
          <button type="submit" class="absolute left-0 top-0 mt-6 ml-4">
            <img :src="searchIcon" alt="Search Icon" class="h-5 w-5 fill-current " />

          </button>
        </div>
        <button type="submit" class="bg-blue-900  text-white font-medium rounded-lg h-11 py-2 px-6 ">
          {{ buttonText }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import searchIcon from '../../assets/searchIcon.svg'; 

export default {
  props: {
    placeholderText: {
      type: String,
      default: 'Recherche..'
    },
    buttonText: {
      type: String,
      default: 'Valider'
    }
  },
  data() {
    return {
      searchQuery: '',
      searchIcon
    }
  },
  methods: {
    handleSubmit() {
      this.$emit('search', this.searchQuery);
    }
  }
}
</script>
