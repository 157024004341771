import CryptoJS from 'crypto-js';
export const getDecryptedType = () => {
  if (localStorage.getItem('type') && localStorage.getItem('type') != '') {
    const typeCrypte = localStorage.getItem('type');
    const bytes = CryptoJS.AES.decrypt(typeCrypte, 'KEY');
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  return null;
};

export const isEditAllowed = () => {
  const decryptedType = getDecryptedType();
  return decryptedType === 'dir_dept' || decryptedType === 'dir_etu';
};

export const editAllowed = isEditAllowed();

export const getCurrentYear = () => {
  const currDate = new Date();
  const currentYear = currDate.getFullYear();
  const currentMonth = currDate.getMonth();
  const AOUT = 5;
  if (currentMonth >= AOUT) {
    return `${currentYear}-${currentYear + 1}`;
  }
  return `${currentYear - 1}-${currentYear}`;
};

export const getCurrentSemestre = () => {
  const currDate = new Date();
  const currentMonth = currDate.getMonth();
  const AOUT = 5;
  if (currentMonth >= AOUT) {
    return 'S1';
  }
  return 'S2';
};
