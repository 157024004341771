<template>
  <div class="w-full h-84 print:hidden">
    <div>
      <img src="../../assets/shape.svg" class="w-full" alt="" />
    </div>
    <div class="md:pl-40 sm:pl-16 max-sm:pl-16 pt-9 pr-24">
      <div>
        <ul class="flex md:pl-24 sm:pl-12 pb-9">
          <li
            class="mr-6 text-indigo-900 font-medium md:text-base max-sm:py-2 sm:py-2"
          >
            À propos de nous
          </li>
          <li
            class="mr-6 text-indigo-900 font-medium text-base max-sm:py-2 sm:py-2"
          >
            Découvrir
          </li>
          <li
            class="mr-6 text-indigo-900 font-medium text-base max-sm:py-2 sm:py-2"
          >
            Explorer
          </li>
        </ul>
      </div>
      <div
        class="flex pt-7 items-center border-t-2 border-slate-900 border-opacity-20"
      >
        <div class="md:flex md:pl-24 sm:pl-12 items-center">
          <span class="text-sm font-medium text-gray-600 max-sm:pl-5 pb-4"
            >© 2024 Didousoft .Tous Droits Résevés.</span
          >
          <img
            class="md:mx-32 max-sm:mx-24 sm:mx-24 w-20 h-12 max-sm:my-8 sm:my-8"
            src="../../assets/logo2.png"
            alt=""
          />
          <ul class="flex pl-6 pb-4">
            <li class="mr-6 font-medium text-sm">Conditions d'utilisation</li>
            <li class="mr-6 font-medium text-sm">
              politique de confidentialité
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "FooterComponent",
  props: {},
};
</script>
