<template>
  <gradient-button
    v-if="type === 'dir_dept' || type === 'dir_etu'"
    class="flex items-center justify-center gap-2 px-6"
    dark
    type="soft"
    color="primary"
    @click="openModal"
  >
    <v-icon size="small">mdi-plus</v-icon>
    <span class="flex items-center">Créer</span>
  </gradient-button>
</template>
<script>
import GradientButton from '../GradientButton/GradientButton.vue';
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  components: {
    GradientButton,
  },

  methods: {
    openModal() {
      this.modalOpen = true;
    },
  },
};
</script>
