<template>
  <div
    class="py-8 px-6 h-full flex flex-col justify-center items-center bg-zinc-50"
  >
    <div class="flex items-center pb-8 w-full">
      <h1 class="text-4xl font-semibold text-gray-500 pt-4 pl-12 mb-4">
        Paramètres
      </h1>
    </div>
    <div
      class="flex flex-col w-2/3 shadow-md rounded-lg bg-white hover:shadow-xl transition-all duration-300"
    >
      <div class="p-6 border-b border-gray-500">
        <span class="text-gray-800 font-semibold text-2xl">
          Déroulement des séances
        </span>
      </div>
      <div class="flex flex-col px-24 py-8">
        <div>
          <span class="text-gray-900 text-sm">Horaire de démarrage </span>
          <div class="flex mt-4">
            <v-radio-group v-model="horaireDeDemarrage" inline>
              <v-radio
                class="mr-24"
                color="info"
                label="8h"
                value="8h"
                @click="showSaveButton ? null : $event.preventDefault()"
              ></v-radio>
              <v-radio
                class="mr-24"
                color="info"
                label="8h15"
                value="8h15"
                @click="showSaveButton ? null : $event.preventDefault()"
              ></v-radio>
              <v-radio
                color="info"
                label="8h30"
                value="8h30"
                @click="showSaveButton ? null : $event.preventDefault()"
              ></v-radio>
            </v-radio-group>
          </div>
        </div>
        <div>
          <div class="text-gray-900 text-sm pb-2">Durée de séance</div>

          <stepper
            v-model="dureeDuSeance"
            :steps="steps"
            :isWrite="showSaveButton"
          />
        </div>
        <div class="mt-8">
          <div class="text-gray-900 text-sm">Durée de pause</div>
          <div class="flex items-center justify-center w-full mt-4">
            <div class="bg-blue-50 w-2/3 h-10 rounded-md p-1 flex">
              <div
                v-for="(item, index) in dureeDuPauseItems"
                :key="index"
                @click="dureeDuPause = item.value"
                class="flex items-center justify-center w-1/2 h-full rounded-md cursor-pointer"
                :class="[
                  {'pointer-events-none': !showSaveButton},
                  dureeDuPause === item.value
                    ? 'bg-blue-500 text-white'
                    : 'text-blue-500',
                ]"
              >
                {{ item.label }}
              </div>
            </div>
          </div>
        </div>
        <div class="mt-8">
          <span class="text-gray-900 text-sm">Nombre de séances</span>
          <div class="w-full px-10 flex items-center justify-around mt-4">
            <div
              v-for="(item, index) in nombreDeSeanceItems"
              :key="index"
              class="flex items-center justify-center w-24 h-12 rounded-xl cursor-pointer border border-sky-600"
              :class="[
                nombreDeSeance === item.value
                  ? 'bg-sky-600 text-white'
                  : 'text-sky-600 bg-blue-50',
                {'pointer-events-none': !showSaveButton},
              ]"
              @click="showSaveButton ? (nombreDeSeance = item.value) : null"
            >
              {{ item.label }}
            </div>
            <div
              class="static"
              :class="{active: isActive, 'text-danger': hasError}"
            ></div>
          </div>
        </div>
        <div class="mt-8">
          <span class="text-gray-900 text-sm">
            Séance de pause midi (entre {{ seancePauseMidToStr() }} seance)
          </span>
          <div class="w-full px-10 flex items-center justify-around my-4">
            <div
              v-for="(item, index) in seanceDePauseMidiItems"
              :key="index"
              class="flex items-center justify-center w-24 h-12 rounded-xl cursor-pointer border border-sky-600"
              :class="[
                mid_seance === item.value
                  ? 'bg-sky-600 text-white'
                  : 'text-sky-600 bg-blue-50',
                {'pointer-events-none': !showSaveButton},
              ]"
              @click="showSaveButton ? (mid_seance = item.value) : null"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div>
          <div class="text-gray-900 text-sm pb-2">Durée de pause midi</div>
          <stepper
            v-model="mid_duree"
            :steps="stepsPause"
            :isWrite="showSaveButton"
          />
        </div>
        <div class="mt-8 flex justify-end w-full">
          <button
            v-if="showSaveButton"
            class="bg-blue-700 rounded-full md:px-6 max-sm:px-2 outline-none font-inter text-sm font-semibold leading-10 tracking-normal text-center text-white"
            @click="validate"
          >
            Sauvegarder
          </button>
        </div>
      </div>
    </div>
    <ModalSuccess v-if="showSuccess" @toggleSuccess="toggleSuccess" />
  </div>
</template>
<script>
import {
  UPDATE_DEROULEMENT,
  GET_DEROULEMENT_SEANCE_BY_ETAB,
  MID_PAUSE_TYPES,
} from './Parametre.services.js';
import apolloClient from '../../apolloClient';
import ModalSuccess from '../../components/Popup/Success/ModalSuccess.vue';
import Stepper from '../../components/Inputs/Stepper.vue';

export default {
  name: 'ParametreComp',
  components: {
    ModalSuccess,
    Stepper,
  },
  data() {
    return {
      showSuccess: false,
      showSaveButton: false,
      horaireDeDemarrage: '8h',
      dureeDuSeance: '1h',
      dureeDuPause: '10min',
      nombreDeSeance: 4,
      dureeDuSeanceMidi: '0min',
      dureeDuPauseItems: [
        {label: '10 minutes', value: '10min'},
        {label: '15 minutes', value: '15min'},
      ],
      nombreDeSeanceItems: [
        {label: '4', value: 4},
        {label: '5', value: 5},
        {label: '6', value: 6},
      ],
      seanceDePauseMidiItems: [
        {label: '2 - 3', value: MID_PAUSE_TYPES.MID_2_3},
        {label: '3 - 4', value: MID_PAUSE_TYPES.MID_3_4},
        {label: '4 - 5', value: MID_PAUSE_TYPES.MID_4_5},
      ],

      mid_duree: 0,
      mid_seance: 0,

      steps: [
        {label: '1h', value: '1h'},
        {label: '1h30', value: '1h30'},
        {label: '2h', value: '2h'},
      ],
      stepsPause: [
        {label: '0min', value: 0},
        {label: '5min', value: 5},
        {label: '10min', value: 10},
        {label: '15min', value: 15},
      ],
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    seancePauseMidToStr() {
      const pauseStrClassMap = ['2eme et 3eme', '3eme et 4eme', '4eme et 5eme'];
      return pauseStrClassMap[this.mid_seance] ?? pauseStrClassMap[0];
    },
    async loadData() {
      try {
        const response = await apolloClient.query({
          query: GET_DEROULEMENT_SEANCE_BY_ETAB,
        });

        const deroulementSeance = response.data.getDeroulementSeanceByEtab;
        if (deroulementSeance != null) {
          this.horaireDeDemarrage = deroulementSeance.hr_demarrage;
          this.dureeDuSeance = deroulementSeance.duree_seance;
          this.dureeDuPause = deroulementSeance.duree_pause;
          this.mid_duree = deroulementSeance.mid_duree;
          this.mid_seance = deroulementSeance.mid_seance;
          this.nombreDeSeance = parseInt(deroulementSeance.nbr_de_seances);
          this.showSaveButton = false;
        } else {
          this.showSaveButton = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async validate() {
      try {
        const response = await apolloClient.mutate({
          mutation: UPDATE_DEROULEMENT,
          variables: {
            hr_demarrage: this.horaireDeDemarrage,
            duree_seance: this.dureeDuSeance,
            duree_pause: this.dureeDuPause,
            nbr_de_seances: this.nombreDeSeance.toString(),
            mid_seance: this.mid_seance,
            mid_duree: this.mid_duree,
          },
        });

        if (response) this.showSuccess = true;
        this.showSaveButton = false;
      } catch (error) {
        console.error(error);
      }
    },
    toggleSuccess() {
      this.showSuccess = !this.showSuccess;
    },
  },
};
</script>
