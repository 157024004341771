<template>
  <v-card class="rounded-lg">
    <v-card-text>
      <div class="flex items-center justify-between py-2 border-b">
        <h1 class="text-xl font-semibold">
          Salles {{ props.typeSalle == 'libre' ? 'Libres' : 'Occupées' }}
        </h1>
      </div>
      <div class="flex mt-5 mb-5 gap-2 items-end">
        <div class="flex-1">
          <label class="mb-2">Jour</label>
          <select
            v-model="selectedJour"
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none text-sm"
          >
            <option value="">Veuillez sélectionner</option>
            <option v-for="jour in jours" :key="jour.id" :value="jour.id">
              {{ jour.label }}
            </option>
          </select>
        </div>
        <div class="flex-1">
          <label class="mb-2">Séance</label>
          <select
            v-model="selectedSeance"
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none text-sm"
            @change="handleSeanceChange"
          >
            <option value="">Veuillez sélectionner</option>
            <template v-for="(seance, ind) in seances">
              <option
                :key="ind"
                :value="ind"
                v-if="seance.toLowerCase() != 'p'"
              >
                {{ seance }}
              </option>
            </template>
          </select>
        </div>
        <div class="flex-1">
          <label class="mb-2">Semestre</label>
          <select
            v-model="selectedSemestre"
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none text-sm"
          >
            <option value="S1">S1</option>
            <option value="S1">S2</option>
          </select>
        </div>
        <div>
          <GradientButton
            class="rounded-lg"
            type="soft"
            color="blueDark"
            @click="getSalles()"
          >
            Valider
          </GradientButton>
        </div>
      </div>
      <div v-if="listerSalles" class="overflow-y-auto flex flex-col">
        <div class="text-end" v-if="salles.length">
          <GradientButton
            class="rounded-lg"
            type="soft"
            color="orange"
            @click="imprimerPage()"
          >
            <VIcon> mdi-printer </VIcon>
          </GradientButton>
        </div>
        <div
          v-for="salle in salles"
          :key="salle.label"
          :value="salle.label"
          class="py-2"
        >
          <div class="border-2 rounded-md border-gray-100">
            <div class="py-2 flex items-center justify-between px-24">
              <div>Salle : {{ salle.label }}</div>
              <div>Bloc : {{ salle.bloc }}</div>
              <router-link
                :to="`/emploi-salle?id=${salle.id}`"
                v-if="props.typeSalle == 'occupe'"
                target="_blank"
                class="print:hidden"
              >
                <GradientButton text="Emploi de temps" type="soft" />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import apolloClient from '@/apolloClient';
import {GET_SALLES_LIBRE_OCCUPE_EMPLOI} from '@/SPA/Enseignants/ModalEnsSeance/ModalEnsSeance.service';
import {GET_DEROULEMENT_SEANCE_BY_ETAB} from '@/SPA/Parametre/Parametre.services';
import {getCurrentSemestre} from '@/SPA/spa.services';
import {ref, defineProps} from 'vue';
import GradientButton from '@/components/Buttons/GradientButton/GradientButton.vue';

const selectedJour = ref('');
const selectedSeance = ref('');
const selectedSemestre = ref();
const salles = ref([]);
const seances = ref([]);
const listerSalles = ref(false);

const props = defineProps({
  typeSalle: {
    type: String,
    default: () => 'libre',
  },
});

const jours = [
  {id: 1, label: 'Lundi'},
  {id: 2, label: 'Mardi'},
  {id: 3, label: 'Mercredi'},
  {id: 4, label: 'Jeudi'},
  {id: 5, label: 'Vendredi'},
  {id: 6, label: 'Samedi'},
];

const getSalles = () => {
  apolloClient
    .query({
      query: GET_SALLES_LIBRE_OCCUPE_EMPLOI,

      fetchPolicy: 'no-cache',
      variables: {
        horaire: selectedSeance.value,
        jour: selectedJour.value,
        semestre: selectedSemestre.value,
        isLibre: props.typeSalle == 'libre' ? true : false,
      },
    })
    .then(res => {
      salles.value = res.data.getSallesLibreEmploi;
    })
    .catch(e => {
      console.error(e);
    });
};

const getDeroulementSeances = () => {
  apolloClient
    .query({
      query: GET_DEROULEMENT_SEANCE_BY_ETAB,
    })
    .then(res => {
      seances.value = res.data.getDeroulementSeanceByEtab.horaire;
      listerSalles.value = true;
      selectedSemestre.value = getCurrentSemestre();
    })
    .catch(e => {
      console.error(e);
    });
};

const imprimerPage = () => {
  window.print();
};

getDeroulementSeances();
</script>
