<template>
  <v-dialog v-model="modalOpen" max-width="500" ref="modalEdition">
    <template v-slot:activator="{ on }" v-if="showPencil == 1">
      <button dark v-on="on" @click="openModal">
        <img src="../../../assets/pencil.svg" alt="" class=" w-6 h-6 mr-7">
      </button>
    </template>
    <v-card class="rounded-lg px-5">
      <div class="flex items-center justify-between py-2 border-b">
        <h1 class="text-xl font-semibold">Edition du département</h1>
        <img :src="Button" class="ml-auto cursor-pointer" @click="closeModal">
      </div>
      <div class="mt-4">
        <label class="mb-2">Veuillez saisir le libellé du département</label>
        <input type="text" v-model="departmentLabel" class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4">
        <label class="mb-2">Choisir le directeur du département</label>
        <select v-model="selectedDirector"
          class="w-full appearance-none border border-gray-300 rounded-md py-2 pl-4 pr-10 mb-4">
          <option value="" disabled hidden selected>Directeur de département</option>
          <option v-for="director in directors" :key="director.id" :value="director.id">{{ director.nom }}
            {{ director.prenom }}
          </option>
        </select>
      </div>
      <v-card-actions class="gap-2 flex justify-end mt-2">
        <button
          class="bg-gray-300 rounded-full px-6 outline-none text-black font-inter text-sm font-semibold leading-10 tracking-normal text-center"
          @click="closeModal">Annuler</button>
        <button
          class="bg-blue-700 rounded-full px-6 outline-none font-inter text-sm font-semibold leading-10 tracking-normal text-center text-white"
          @click="updateDepartement">Valider</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import plus from '../../../assets/plus-sm.png';
import Button from '../../../assets/Button.png';
import gql from 'graphql-tag';
import apolloClient from '../../../apolloClient';
import CryptoJS from 'crypto-js';
import { UPDATE_DEPARTEMENT } from './ModalEditionDepartment.services';

export default {
  name: "ModalEditionDepartement",
  props: {
    type: {
      type: String,
      default: ""
    },
    departementId: {
      type: Number,
      default: 0
    },
    id_directeur: {
      type: Number,
      default: 0
    },
    defaultLabel: {
      type: String,
    },
    showPencil: {
      type: Number,
      default: 1
    },
    showModal: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      modalOpen: false,
      plus,
      Button,
      selectedDirector: '',
      departmentLabel: '',
      directors: [],
      decryptedType: '',
      decryptedLabel: ''
    };
  },
  mounted() {
    const typeCrypte = localStorage.getItem('type');
    const bytes = CryptoJS.AES.decrypt(typeCrypte, 'KEY');
    this.decryptedType = bytes.toString(CryptoJS.enc.Utf8);
    this.getDep();

    this.modalOpen = (this.showModal == 1)
    if (this.modalOpen) {
      this.openModal(this.defaultLabel, this.id_directeur)
    }
  },
  methods: {
    openModal(defaultLabel, id_directeur) {
      this.departmentLabel = defaultLabel;
      if (id_directeur !== null) {
        this.selectedDirector = id_directeur;
      }
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
      this.departmentLabel = '';
      this.selectedDirector = '';
    },
    async updateDepartement() {
      try {
        const id = this.selectedDirector ? parseInt(this.selectedDirector) : this.id_directeur;
        const label = this.departmentLabel.trim() !== '' ? this.departmentLabel : this.defaultLabel;
        const response = await apolloClient.mutate({
          mutation: UPDATE_DEPARTEMENT,
          variables: {
            id: this.departementId,
            label: label,
            id_directeur: id
          },
          fetchPolicy: 'no-cache'
        });
        if (response.data && response.data.updateDepartement) {
          this.$emit('departementUpdated');
          this.closeModal();
        } else {
          console.error("Erreur");
        }
      } catch (error) {
        console.error("Une erreur s'est produite :", error);
      }
    },

    async getDep() {
      try {
        const response = await apolloClient.query({
          query: gql`
            query getAllUsersByType($type: String!) {
              getAllUsersByType(type: $type) {
                id
                nom
                prenom
              }
            }
          `,
          variables: {
            type: 'dir_dept',
          },
          fetchPolicy: 'no-cache'
        });
        this.directors = response.data.getAllUsersByType;
      } catch (error) {
        console.error('Erreur lors de la récupération des directeurs :', error);
      }
    }
  },

};
</script>