<template> 
 <div class="">
   <button :class="buttonClass" class="flex items-center justify-center w-32 h-10 rounded-full mx-1">
    <div class="flex ">
      <img :src="svgPath" alt="" class="mr-2 w-5 h-5">
      <span :class="titleClass">{{ title }}</span>
    </div>
  </button>
 </div>
</template>
<script>
export default {
  name: 'RoundedButton',
  props: {
    Buttoncolor: {
      type: String,
      default: "blue-700"
    },
    Textcolor:{
      type:String,
      default: "white"
    },
    svgPath: {
      type: String,
      default: "plus-sm.png"
    },
    title: {
      type: String,
      default: "Créer"
    }
  },
  computed: {
    buttonClass() {
      return `${this.Buttoncolor} flex items-center justify-center w-32 h-10 rounded-full mx-1`;
    },
    titleClass() {
      return `${this.Textcolor} font-semibold text-sm`;
    }
  },
  component: {
  }
}
</script>