<template>
    <div>
        <div class="fixed top-0 left-0 w-full h-full bg-black opacity-25 z-40"></div>
        <div class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50">
            <div class="bg-white p-6 rounded-lg shadow-lg w-99 relative">
                <h2 class="text-xl font-bold mb-2 mt-6 text-blue-900 text-center">Confirmez-vous la suppression?</h2>
                <p class="mb-10 text-neutral-400 text-sm mt-4">Si vous confirmer ce groupe sera définitivement effacé
                </p>
                <div class="flex justify-between gap-2">
                    <button @click="confirmSuppression" class="bg-blue-600 text-white px-6 py-2 rounded">
                        <v-icon icon="mdi-check" size="small" color="white" class="ml-2" />
                        Je confirme
                    </button>
                    <button @click="closeModal" class="bg-gray-300 text-blue-900 px-6 py-2 rounded">
                        <v-icon icon="mdi-close" size="small" color="blue-900" class="ml-2" />
                        Annuler
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'SupprimerGroupe',
    data() {
        return {

        }
    },
    props: {
        groupId: {
            type: Number,
            required: false
        }
    },
    methods: {
        closeModal() {
            this.$emit('close-modal');
        },
        confirmSuppression() {
            this.$emit('confirm-suppression', this.$props.groupId);
            this.closeModal();
        },
    }
}
</script>
