import {createStore} from 'vuex';
export const store = createStore({
  state: {
    showStats: false,
  },
  mutations: {
    toggleStatBloc(state) {
      state.isStatOpen = !state.isStatOpen;
    },
  },
});
