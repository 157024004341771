<template>
  <div>
    <div
      class="fixed w-full h-full left-0 top-0 bg-black opacity-25 z-40"
    ></div>
    <div
      class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50"
    >
      <div class="bg-white p-6 rounded-lg shadow-lg w-99 relative min-w-96">
        <img :src="fail" alt="Union" class="absolute -mt-14 left-6 w-18 h-20" />

        <h2 class="text-2xl font-bold mb-2 mt-12 text-gray-800">Erreur</h2>
        <p class="mb-10 text-gray-500 text-sm">
          <span v-if="reason.length > 0">{{ reason }} </span>
          <span v-else>
            Erreur d'opération est survenu. Veuillez ré-essayez ultérieurement.
          </span>
        </p>
        <div class="flex justify-end">
          <button
            @click="$emit('toggleErreur')"
            class="bg-red-500 text-white px-6 py-2 rounded"
          >
            OK
          </button>
        </div>
        <img
          :src="redBubbles"
          alt="Red Bubbles"
          class="absolute bottom-0 left-0 w-24 rounded-lg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import redBubbles from "../../../assets/red_bubbles.png";
import fail from "../../../assets/fail.svg";

export default {
  name: "ModalErreur",
  props: {
    reason: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      redBubbles,
      fail,
    };
  },
};
</script>
