import gql from "graphql-tag";
export const ADD_Departement = gql`
  mutation addDepartement($label: String!, $id_directeur: Int!) {
    addDepartement(label: $label, id_directeur: $id_directeur)
  }
`;
export const Get_Dep = gql`
  query getAllUsersByType($type: String!, $etab: String!) {
    getAllUsersByType(type: $type, etab: $etab) {
      id
      nom
      prenom
    }
  }
`;
