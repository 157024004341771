import gql from "graphql-tag";
export const GET_DEPARTEMENT_BY_ID = gql`
  query getDepartementById($id: Int) {
    getDepartementById(id: $id) {
      specialites {
        id
        label
      }
    }
  }
`;
export const ADD_SPECIALITE = gql`
  mutation specialite($label: String, $id_dept: Int) {
    addSpecialite(label: $label, id_dept: $id_dept)
  }
`;

export const DELETE_SPECIALITE = gql`
  mutation specialite($id: Int) {
    deleteSpecialite(id: $id)
  }
`;
