<template>
  <v-dialog v-model="modalOpen" max-width="531">
    <template v-slot:activator="{on}">
      <GradientButton
        dark
        v-on="on"
        @click="openModal"
        type="soft"
        color="gray"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
        title="Importer"
      >
        <VIcon> mdi-file-excel </VIcon>
        <transition>
          <span v-if="hover" class="ml-2">Importer</span>
        </transition>
      </GradientButton>
    </template>
    <v-card class="rounded-lg px-5">
      <div class="flex items-center justify-between py-2 border-b">
        <h1 class="text-xl font-semibold">Importer salle</h1>
        <img :src="Button" class="ml-auto cursor-pointer" @click="closeModal" />
      </div>
      <div class="mt-4">
        <label class="mb-2">Département </label>
        <select
          v-model="selectedDepartement"
          class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none"
          @change="handleDepartmentChange"
        >
          <option value="" disabled hidden>Veuillez sélectionner</option>
          <option
            v-for="departement in Departements"
            :key="departement.id"
            :value="departement.id"
          >
            {{ departement.label }}
          </option>
        </select>
      </div>
      <div>
        <label class="mb-2">Fichier attaché </label>
        <div class="flex items-center mt-2">
          <input
            type="text"
            id="fileName"
            disabled
            class="w-4/6 rounded-l-full border border-gray-300 h-10 text-gray-400 text-sm px-4"
            placeholder="Insérer le fichier"
          />

          <label
            for="fileInput2"
            class="w-2/6 bg-cyan-600 rounded-r-full h-10 text-white flex items-center justify-center cursor-pointer gap-1"
          >
            <img :src="upload" class="w-4 h-4" />
            <span class="flex items-center max-sm:text-xs text-center"
              >Choisir le fichier</span
            >
            <input
              id="fileInput2"
              type="file"
              class="hidden"
              @change="loadFile"
            />
          </label>
        </div>
        <a
          :href="api_url + '/download/model_salle.csv'"
          class="text-blue-500 text-xs flex justify-end items-center w-full px-2 mt-2"
        >
          <span>Télécharger le modèle</span>
        </a>
      </div>
      <v-card-actions class="gap-2 flex justify-end mt-4">
        <button
          class="bg-gray-300 rounded-full px-6 outline-none text-black font-inter text-sm font-semibold leading-10 tracking-normal text-center"
          @click="closeModal"
        >
          Annuler
        </button>
        <button
          class="bg-blue-700 rounded-full px-6 outline-none font-inter text-sm font-semibold leading-10 tracking-normal text-center text-white"
          @click="importSalle"
        >
          Valider
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Button from '../../../assets/Button.png';
import plus from '../../../assets/plus-sm.png';
import upload from '../../../assets/upload.png';
import uploadIm from '../../../assets/upload.svg';
import {Get_All_Dep} from '../ModalClasse/ModalClasse.service';
import apolloClient from '../../../apolloClient';
import {IMPORT_SALLE} from './ModalSalle.service';
import GradientButton from '@/components/Buttons/GradientButton/GradientButton.vue';
const api_url = process.env.VUE_APP_API_URL;

export default {
  name: 'ModalImportSalle',
  components: {
    GradientButton,
  },
  data() {
    return {
      plus,
      uploadIm,
      Button,
      upload,
      modalOpen: false,
      selectedDepartement: '',
      Departements: [],
      FileBase64: null,
      base64String: '',
      api_url,
      hover: false,
    };
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },

    loadFile(event) {
      this.handleFileUpload(event, 'fileName');
    },
    handleFileUpload(event, fileNameId) {
      const input = event.target;
      const fileNameInput = document.getElementById(fileNameId);
      if (input.files && input.files[0]) {
        const file = input.files[0];
        fileNameInput.value = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result.split(',')[1];
          if (fileNameId == 'fileName') {
            this.FileBase64 = base64String;
          }
        };
        reader.readAsDataURL(file);
      }
    },
    async getDep() {
      try {
        const response = await apolloClient.query({
          query: Get_All_Dep,
        });
        this.Departements = response.data.getAllDepartements;
      } catch (error) {
        console.error('Error fetching Departements:', error);
      }
    },
    async importSalle() {
      try {
        await apolloClient
          .mutate({
            mutation: IMPORT_SALLE,
            variables: {
              file: this.FileBase64,
              dep_id: parseInt(this.selectedDepartement),
              etat: 'libre',
            },
          })
          .then(() => this.$emit('refresh-view', 1));
      } catch (error) {
        console.error('Error importing salles:', error);
      }
      this.closeModal();
    },
  },
  mounted() {
    this.getDep();
  },
};
</script>
