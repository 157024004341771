<template>
  <div class="w-full h-68 rounded-lg shadow bg-white relative">
    <div>
      <div>
        <div class="h-28 rounded-t-lg overflow-hidden">
          <img
            v-if="isEditAllowed"
            src="../../assets/kebabIcon.svg"
            alt=""
            class="pt-2 pr-2.5 absolute top-0 right-0 cursor-pointer"
            @click="toggleKebabMenu"
          />
          <img
            src="../../assets/card_dep.svg"
            alt=""
            class="h-full w-full object-cover"
          />
          <KebabMenu
            v-if="openKebabMenu"
            class="absolute right-4 top-8 z-10"
            :dataOpenKebabMenu="openKebabMenu"
            :menuItems="menuItems"
            :archived="archived"
            @toggleArchive="toggleArchive"
            @toggleModalSpecialite="toggleSpecialites"
            @toggleModalEdition="toggleEdition"
          />
        </div>
      </div>
      <ModalSpecialite
        :isModalOpen="isModalSpecialiteOpen"
        @closeModal="isModalSpecialiteOpen = false"
        :departementId="departementId"
      />
      <ModalEditionDepartement
        v-if="isModalEdition"
        :departementId="departementId"
        :id_directeur="idDirecteur"
        :defaultLabel="title"
        showPencil="0"
        @departementUpdated="refreshData"
        :showModal="isModalEdition ? 1 : 0"
      />

      <div
        :class="getBackgroundColorClass(title.charAt(0))"
        class="w-16 h-16 rounded-full absolute top-28 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center border-2 border-slate-50"
      >
        <span class="text-white font-bold text-2xl">{{
          title.charAt(0).toUpperCase()
        }}</span>
      </div>
      <span
        class="text-base font-medium absolute top-36 flex justify-center w-full mt-2"
        >{{ title }}</span
      >
    </div>
    <div class="py-4 px-6 flex flex-col justify-center mt-14">
      <button
        class="bg-blue-600 w-full h-12 rounded mt-4 text-neutral-100 text-base font-medium hover:bg-blue-500"
        @click="sendId(departementId)"
      >
        Plus d'info
      </button>
    </div>
  </div>
</template>
<script>
import KebabMenu from "./../KebabMenu/KebabMenu.vue";
import ModalSpecialite from "../Modals/ModalSpecialite/ModalSpecialite.vue";
import ModalEditionDepartement from "../Modals/ModalEditionDepartement/ModalEditionDepartement.vue";
import { editAllowed } from "./../../SPA/spa.services";

export default {
  name: "CarteDepartement",
  components: {
    KebabMenu,
    ModalSpecialite,
    ModalEditionDepartement,
  },
  props: {
    title: {
      type: String,
      default: "title",
    },
    departementId: {
      type: Number,
    },
    archived: {
      type: Boolean,
      default: false,
    },
    idDirecteur: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      openKebabMenu: false,
      openDetailsDep: true,
      isModalEdition: false,
      isModalSpecialiteOpen: false,
      isEditAllowed: editAllowed,
    };
  },
  computed: {
    menuItems() {
      const archiveLabel = this.archived ? "Désarchiver" : "Archiver";
      if (this.archived) {
        return [
          { label: "Editer", method: this.toggleEdition },
          { label: archiveLabel, method: this.toggleArchive },
        ];
      }
      return [
        { label: "Editer", method: this.toggleEdition },
        { label: archiveLabel, method: this.toggleArchive },
        { label: "Gérer les spécialités", method: this.toggleSpecialites },
      ];
    },
  },
  methods: {
    toggleKebabMenu() {
      this.openKebabMenu = !this.openKebabMenu;
    },
    toggleEdition() {
      this.isModalEdition = !this.isModalEdition;
    },
    toggleSpecialites() {
      this.isModalSpecialiteOpen = !this.isModalSpecialiteOpen;
    },
    closeKebabMenu(event) {
      if (!this.$el.contains(event.target)) {
        this.openKebabMenu = false;
      }
    },
    getBackgroundColorClass(letter) {
      const colorMap = {
        A: "bg-pink-400",
        B: "bg-green-500",
        C: "bg-blue-500",
        D: "bg-yellow-500",
        E: "bg-orange-500",
        F: "bg-purple-500",
        G: "bg-red-400",
        H: "bg-indigo-500",
        I: "bg-teal-500",
        J: "bg-cyan-500",
        K: "bg-lime-500",
        L: "bg-emerald-500",
        M: "bg-rose-500",
        N: "bg-amber-500",
        O: "bg-fuchsia-500",
        P: "bg-yellow-800",
        Q: "bg-teal-800",
        R: "bg-gray-500",
        S: "bg-cyan-400",
        T: "bg-lime-500",
        U: "bg-blue-700",
        V: "bg-orange-300",
        W: "bg-red-600",
        X: "bg-pink-600",
        Y: "bg-green-800",
        Z: "bg-indigo-700",
      };
      return colorMap[letter.toUpperCase()];
    },
    sendId(departementId) {
      let info = {
        id_dep: departementId,
        openDetailsDep: this.openDetailsDep,
      };
      this.$emit("info", info);
    },
    toggleArchive() {
      this.$emit("toggleArchive", this.departementId);
      this.toggleKebabMenu();
    },
    refreshData() {
      this.$emit("refreshData", true);
    },
  },
  mounted() {
    document.addEventListener("click", this.closeKebabMenu);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeKebabMenu);
  },
};
</script>
