<template>
  <div>
    <SimpleCard title="Changer le mot de passe" fullWidth>
      <div class="grid sm:grid-cols-2 grid-cols-1 gap-6 p-6">
        <div class="text-gray-500 text-lg">
          Après une mise à jour réussie du mot de passe, vous serez redirigé
          vers la page de connexion où vous pouvez vous connecter avec votre
          nouveau mot de passe.
        </div>
        <div class="flex gap-6 flex-col">
          <InputWithLabel
            showShadow="false"
            type="password"
            label="Mot de passe actuel"
            v-model="oldMdp"
            labelClass="text-gray-800 text-sm"
            inputClass="border-gray-200"
            layoutClass="my-0"
          />
          <InputWithLabel
            showShadow="false"
            type="password"
            label="Nouveau mot de passe"
            v-model="newMdp"
            labelClass="text-gray-800 text-sm"
            inputClass="border-gray-200"
            layoutClass="my-0"
          />
          <InputWithLabel
            showShadow="false"
            type="password"
            label="Confirmez le mot de passe"
            v-model="confirmMdp"
            labelClass="text-gray-800 text-sm"
            inputClass="border-gray-200"
            layoutClass="my-0"
          />
          <div>
            <GradientButton text="Mettre à jour" @btn-click="submitUpdate()" />
          </div>
        </div>
      </div>
    </SimpleCard>
    <ModalSuccess v-if="showSuccess" @toggleSuccess="toggleSuccess" />
    <ModalErreur
      v-else-if="showError"
      @toggle-erreur="toggleToggleError"
      :reason="errorReason"
    />
  </div>
</template>

<script>
import SimpleCard from "@/components/Cards/SimpleCard/SimpleCard.vue";
import InputWithLabel from "@/components/InputWithLabel/InputWithLabel.vue";
import GradientButton from "@/components/Buttons/GradientButton/GradientButton.vue";
import apolloClient from "@/apolloClient";
import { UPDATE_MDP } from "../../ProfilePage.service";
import ModalSuccess from "@/components/Popup/Success/ModalSuccess.vue";
import ModalErreur from "@/components/Popup/Erreurs/ModalErreur.vue";
export default {
  name: "ProfileChangePassword",
  components: {
    SimpleCard,
    InputWithLabel,
    GradientButton,
    ModalSuccess,
    ModalErreur,
  },
  data() {
    return {
      oldMdp: "",
      newMdp: "",
      confirmMdp: "",
      showSuccess: false,
      showError: false,
      errorReason: "",
    };
  },
  methods: {
    async submitUpdate() {
      if (!this.oldMdp || !this.newMdp || !this.confirmMdp) {
        return;
      }
      const resp = await apolloClient.mutate({
        mutation: UPDATE_MDP,
        variables: {
          oldMdp: this.oldMdp,
          newMdp: this.newMdp,
          confirmMdp: this.confirmMdp,
        },
      });

      const responseData = resp.data.changerMdp;
      if (responseData.success == true) {
        this.showSuccess = true;
        this.oldMdp = "";
        this.newMdp = "";
        this.confirmMdp = "";
      } else {
        this.errorReason = responseData.message;
        this.showError = true;
      }
    },
    toggleSuccess() {
      this.showSuccess = !this.showSuccess;
    },
    toggleToggleError() {
      this.showError = !this.showError;
    },
  },
};
</script>
