import gql from "graphql-tag";
export const ADD_GROUPE = gql`
  mutation groupe_classe($classe_id: Int) {
    ajouterGroupe(classe_id: $classe_id)
  }
`;
export const DELETE_GROUPE = gql`
  mutation groupe_classe($id: Int) {
    supprimerGroupe(id: $id)
  }
`;
