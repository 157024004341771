<template>
  <form @submit.prevent="handleSubmit" class="mx-4 pt-2">
    <div class="bg-gray-100 px-5 py-4 rounded-lg">
      <div class="flex gap-2 items-center flex-wrap">
        <div class="relative flex-1">
          <input
            class="border border-gray-300 bg-white h-12 w-full px-12 pr-16 rounded-lg text-sm focus:outline-none"
            type="search"
            :placeholder="placeholderText"
            v-model="searchQuery"
            @keydown.enter.prevent="handleSubmit"
          />
          <div class="absolute left-0 bottom-3.5 mt-6 ml-4">
            <img
              :src="searchIcon"
              alt="Search Icon"
              class="h-5 w-5 fill-current"
            />
          </div>
        </div>
        <select
          v-model="selectedSemestre"
          class="bg-white h-12 border border-gray-300 text-stale-600 rounded-lg px-4 text-sm focus:outline-none"
        >
          <option value="" disabled hidden selected>Semestre</option>
          <option
            v-for="(option, index) in semestres"
            :key="index"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
        <div>
          <select
            v-model="selectedDepartement"
            class="bg-white h-12 border border-gray-300 w-60 text-stale-600 rounded-lg px-4 text-sm focus:outline-none"
          >
            <option value="">Tout département</option>
            <option
              v-for="(option, index) in DepartementOptions"
              :key="index"
              :value="option.label"
            >
              {{ option.label }}
            </option>
          </select>
        </div>

        <VBtn
          type="submit"
          class="h-11 rounded-lg"
          variant="flat"
          color="primary"
        >
          {{ buttonText }}
        </VBtn>
      </div>
    </div>
  </form>
</template>

<script>
import {getCurrentSemestre} from '@/SPA/spa.services';
import searchIcon from '../../../assets/searchIcon.svg';
import {VBtn} from 'vuetify/lib/components/index.mjs';

export default {
  components: {
    VBtn,
  },
  props: {
    placeholderText: {
      type: String,
      default: 'Recherche..',
    },
    buttonText: {
      type: String,
      default: 'Valider',
    },
    DepartementOptions: {
      type: Array,
      default: () => [],
    },
    semestres: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchQuery: '',
      searchIcon,
      selectedSemestre: getCurrentSemestre(),
      selectedDepartement: '',
    };
  },
  methods: {
    handleSubmit() {
      let searchData = {
        searchQuery: this.searchQuery,
        selectedSemestre: this.selectedSemestre,
        selectedDepartement: this.selectedDepartement,
      };
      this.$emit('search', searchData);
    },
  },
};
</script>
