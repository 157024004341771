<template>
  <SimpleCard
    fullWidth
    noShadow
    :style="{ backgroundImage: `url(${userProfileBg})` }"
    class="bg-cover p-7.5"
  >
    <div class="flex flex-row items-center">
      <div class="grow text-white">
        <div class="text-3xl font-semibold text-capitalize">
          Bienvenue , {{ userInfos.nom + " " + userInfos.prenom }}
        </div>
        <div class="text-xl font-medium pt-4">
          {{ getRoleStr() }}
        </div>
      </div>
      <img :src="userPapers" class="pe-7.5 h-60 hidden lg:block" />
    </div>
  </SimpleCard>
</template>

<script>
import SimpleCard from "../../../../components/Cards/SimpleCard/SimpleCard.vue";
import userPapers from "../../../../assets/user-papers.svg";
import userProfileBg from "../../../../assets/user-profile-bg.svg";
export default {
  components: {
    SimpleCard,
  },
  data() {
    return {
      userPapers,
      userProfileBg,
      constRole: "",
    };
  },
  props: {
    userInfos: {
      type: Object,
      default: () => {},
    },
    role: {
      type: String,
      default: "",
    },
  },

  methods: {
    getRoleStr() {
      if (this.role == "dir_dept") {
        return "Directeur de département : " + this.userInfos.departement_str;
      }
      return this.userInfos.type_translate;
    },
  },
};
</script>
