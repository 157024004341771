<template>
  <div
    class="tooltip absolute bg-gray-800 text-white text-sm p-2 rounded-md shadow-lg ml-4 top-1/4 left-1/4 translate-x-12 whitespace-nowrap "
  >
    {{ label }} 
    <span class="tooltip-arrow absolute w-3 h-3 left-[-2px] top-1/4 transform-translate-y-1/2 border-t-transparent border-b-transparent border-t-4 border-b-4 border-r-4 border-gray-800"></span>
  </div>
</template>

<script>
export default {
  name: "LevelTooltip",

  props: {
    label:String
 },
};
</script>