<template>
  <div class="p-4 mb-4 text-sm rounded-lg" :class="getCssColor()" role="alert">
    <slot></slot>
    <span v-if="text">
      {{ text }}
    </span>
  </div>
</template>

<script>
export default {
  name: "VAlert",
  props: {
    variant: String,
    text: String,
  },
  methods: {
    getCssColor() {
      const variantClassMap = {
        info: "text-blue-800  bg-blue-50 dark:bg-gray-800 dark:text-blue-400",
        danger: "text-red-800 bg-red-50 dark:bg-gray-800 dark:text-red-400",
        success:
          "text-green-800 bg-green-50 dark:bg-gray-800 dark:text-green-400",
        warning:
          "text-yellow-800 bg-yellow-50 dark:bg-gray-800 dark:text-yellow-300",
        dark: "text-gray-800 bg-gray-50 dark:bg-gray-800 dark:text-gray-300",
      };
      return variantClassMap[this.variant] || variantClassMap["dark"];
    },
  },
};
</script>
