import { createApp } from "vue";
import App from "./App.vue";
import "./assets/style.css";

import apolloClient from "./apolloClient";

import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import router from "./router";
import VueCryptojs from "vue-cryptojs";
import { store } from './store.js'

const vuetify = createVuetify({
  components,
  directives,
});

window.addEventListener("storage", () => {
  location.href = "";
});

createApp(App)
  .provide("apollo", apolloClient)
  .use(vuetify)
  .use(router)
  .use(VueCryptojs)
  .use(store)
  .mount("#app");
