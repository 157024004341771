<template>
  <div class="w-48 bg-white rounded shadow">
    <div class="flex justify-between border-b px-2 py-5">
      <span class="font-semibold text-gray-800">Panneau</span>
      <img :src="kebabVertical" alt="" class="cursor-pointer" @click="toggleMenu">
      <div v-if="showMenu" class="absolute bg-white shadow rounded mt-6 z-40">
        <div class="pr-3 py-3 flex items-center">
          <v-radio @click="selectFilter('departement')" class="flex-grow-0" color="primary"></v-radio>
          <span>Par département</span>
        </div>
        <div class="pr-3 py-3 flex items-center">
          <v-radio @click="selectFilter('specialite')" class="flex-grow-0" color="primary"></v-radio>
          <span>Par spécialité</span>
        </div>
        <div class="pr-3 py-3 flex items-center">
          <v-radio @click="selectFilter('categorie')" class="flex-grow-0" color="primary"></v-radio>
          <span>Par catégorie</span>
        </div>
      </div>
    </div>
    <div v-if="filterChoices === 'departement'">
      <div v-for="departement in uniqueDepartements" :key="departement" class="flex items-center border-b">
        <v-checkbox color="info" hide-details :value="departement" @change="toggleSelection('departement', departement)"></v-checkbox>
        <span class="font-semibold text-gray-800 ml-1 truncate w-32">{{ departement }}</span>
      </div>
    </div>
    <div v-else-if="filterChoices === 'specialite'">
      <div v-for="specialite in uniqueSpecialites" :key="specialite" class="flex items-center border-b">
        <v-checkbox color="info" hide-details :value="specialite" @change="toggleSelection('specialite', specialite)"></v-checkbox>
        <span class="font-semibold text-gray-800 ml-1 truncate w-32">{{ specialite }}</span>
      </div>
    </div>
    <div v-else-if="filterChoices === 'categorie'">
      <div v-for="categorie in categories" :key="categorie" class="flex items-center border-b">
        <v-checkbox color="info" hide-details :value="categorie" @change="toggleSelection('categorie', categorie)"></v-checkbox>
        <span class="font-semibold text-gray-800 ml-1 truncate w-32">{{ categorie }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import kebabVertical from "../../../../assets/KebabVertical.svg";
import { GET_PANNEAU_DATA } from "@/SPA/Matieres/Matiers.services";
import apolloClient from "@/apolloClient";

export default {
  props: {
    text: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filteredMatiere: '',
      selectedDepartements: [],
      selectedSpecialites: [],
      selectedCategories: [],
      matieres: [],
      departements: [],
      specialites: [],
      categories: ["td", "cours", "tp","cours integrés"],
      kebabVertical,
      showMenu: false,
      filterChoices: 'departement'
    };
  },
  computed: {
    buttonText() {
      switch (this.filteredMatiere) {
        case 'departement':
          return 'Par département';
        case 'specialite':
          return 'Par spécialité';
        case 'categorie':
          return 'Par catégorie';
        default:
          return '';
      }
    },
    uniqueDepartements() {
      if (this.matieres && this.matieres.length) {
        return [...new Set(this.matieres.map(matiere => matiere.departmentLabel))];
      }
      return [];
    },
    uniqueSpecialites() {
      if (this.matieres && this.matieres.length) {
        return [...new Set(this.matieres.map(matiere => matiere.specialites).flat())];
      }
      return [];
    }
  },
  methods: {
    async fetchMatieres() {
      try {
        const response = await apolloClient.query({
          query: GET_PANNEAU_DATA
        });
        this.matieres = response.data.getAllMatieres || [];
      } catch (error) {
        console.error("Error fetching matieres:", error);
      }
    },
    toggleSelection(type, value) {
      let selectedArray;

      if (type === 'departement') {
        selectedArray = this.selectedDepartements;
      } else if (type === 'specialite') {
        selectedArray = this.selectedSpecialites;
      } else if (type === 'categorie') {
        selectedArray = this.selectedCategories;
      }

      const index = selectedArray.indexOf(value);
      if (index >= 0) {
        selectedArray.splice(index, 1);
      } else {
        selectedArray.push(value);
      }

      this.$emit('update-filter', {
        type,
        value: selectedArray
      });
    },
    filterMatiere() {
      let filtered = this.matieres;
      if (this.filterType) {
        if (this.filterType === 'departement') {
          filtered = filtered.filter(matiere => matiere.departmentLabel === this.selectedDepartement);
        } else if (this.filterType === 'specialite') {
          filtered = filtered.filter(matiere => matiere.specialites.includes(this.selectedSpecialite));
        } else if (this.filterType === 'categorie') {
          filtered = filtered.filter(matiere => matiere.natures.includes(this.selectedCategorie));
        }
      }
      if (this.query) {
        const query = this.query.toLowerCase();
        filtered = filtered.filter(matiere => matiere.label.toLowerCase().includes(query));
      }
      return filtered;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    selectFilter(type) {
      this.filterChoices = type;
      this.showMenu = false;
    },
  },
  created() {
    this.fetchMatieres();
  }
};
</script>
