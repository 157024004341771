<template>
  <div class="bg-gray-50">
    <div class="pt-16 md:mx-28">
      <div
        class="w-full h-full pt-20 pb-16 banner-bg sm:banner-bg-phone flex flex-col bg-cover rounded-lg"
      >
        <div class="flex items-center justify-between pb-5">
          <h1
            class="text-4xl pl-10 md:text-right text-white font-Inter font-extrabold"
          >
            Gestion des classes
          </h1>
          <div class="pr-12">
            <ModalClasse
              @toggle-erreur="toggleErreur"
              @refresh-view="refreshView"
            />
          </div>
        </div>
        <ModalErreur
          v-if="modalErreur"
          @toggle-erreur="toggleErreur"
          :reason="reason"
        />
      </div>
      <div class="bg-white rounded-tl-10 rounded-tr-10">
        <div class="py-4 px-4">
          <SearchWithDeps
            @search="handleSearch"
            :semestres="semestres"
            :DepartementOptions="departements"
          />
        </div>
        <div class="ml-auto w-full pr-4 flex justify-between items-center">
          <div class="flex items-center ml-14">
            <input
              type="checkbox"
              checked
              class="mr-2"
              @change="handleCheckboxChange"
            />
            <span class="text-sm font-semibold text-gray-800"
              >Seulement les actifs</span
            >
          </div>
          <div class="w-60 pr-4">
            <v-select
              v-model="itemsPerPage"
              label="Nombres d'élements par page"
              :items="perPageOptions"
              variant="outlined"
              class="h-16 text-sm text-slate-600"
            >
            </v-select>
          </div>
        </div>
        <div class="p-5">
          <div v-if="isLoading">Chargement en cours...</div>
          <div v-else-if="paginatedItems.length == 0">
            Aucun élément à afficher...
          </div>
          <div v-else class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
            <div v-for="(item, index) in paginatedItems" :key="index">
              <CardItemClasse
                :nomDep="item.department.label"
                :labelClasse="item.label"
                :isActive="item.is_actif"
                :classId="item.id"
                :groupes="item.groupes"
                @toggleActive="toggleClasseActive"
                :specialite="item.specialite"
                @refresh-view="refreshView"
              />
            </div>
          </div>
        </div>
        <div class="ml-auto" v-if="paginatedItems.length > 0">
          <v-pagination
            v-model="page"
            variant="flat"
            prev-icon="mdi-arrow-left"
            next-icon="mdi-arrow-right"
            active-color="blue-700 text-white"
            size="40"
            rounded="circle"
            :length="Math.ceil(items.length / itemsPerPage)"
          ></v-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardItemClasse from "../../components/CardItemClasse/CardItemClasse.vue";
import ModalClasse from "@/components/Modals/ModalClasse/ModalClasse.vue";
import SearchWithDeps from "./SearchWithDeps.vue";
import gql from "graphql-tag";
import apolloClient from "../../apolloClient";
import ModalErreur from "../../components/Popup/Erreurs/ModalErreur.vue";
import { GET_ALL_CLASSES } from "@/components/Modals/ModalClasse/ModalClasse.service";
import { GET_DEPARTEMENTS } from "../../components/Modals/ModalEmploisDeTemps/ModalEmploisDeTemps.service";

export default {
  components: {
    SearchWithDeps,
    ModalClasse,
    CardItemClasse,
    ModalErreur,
  },
  data() {
    return {
      classes: [],
      items: [],
      page: 1,
      itemsPerPage: 6,
      showOnlyActive: true,
      perPageOptions: [
        { title: "6 éléments par page", value: 6 },
        { title: "12 éléments par page", value: 12 },
        { title: "24 éléments par page", value: 24 },
      ],
      modalErreur: false,
      departements: [],
      selectedDepartement: "",
      searchQuery: "",
      isLoading: true,
      errorReason: "",
    };
  },
  watch: {
    itemsPerPage() {
      if (Math.ceil(this.items.length / this.itemsPerPage) < this.page) {
        this.page = 1;
      }
      this.filterClasses();
    },
    page() {
      this.filterClasses();
    },
    items() {
      if (Math.ceil(this.items.length / this.itemsPerPage) < this.page) {
        this.page = 1;
      }
    },
  },
  methods: {
    toggleErreur(response = null) {
      this.modalErreur = !this.modalErreur;
      if (response && response.success == false && response.message) {
        this.reason = response.message;
      }
    },
    async refreshView() {
      this.fetchClasses(true);
    },
    async fetchClasses(refetch = false) {
      try {
        const response = await apolloClient.query({
          query: GET_ALL_CLASSES,
          fetchPolicy: refetch ? "network-only" : "cache-first",
        });
        this.classes = response.data.getAllClasses;

        this.filterClasses();
      } catch (error) {
        console.error("Error fetching Matieres:", error);
      }
      this.isLoading = false;
    },
    async toggleClasseActive(classId) {
      try {
        const response = await apolloClient.mutate({
          mutation: gql`
            mutation toggleClasseIsActif($id: Int!) {
              toggleClasseIsActif(id: $id)
            }
          `,
          variables: {
            id: classId,
          },
        });
        if (response.data && response.data.toggleClasseIsActif !== null) {
          const updatedClass = this.classes.find((c) => c.id === classId);
          if (updatedClass) {
            updatedClass.is_actif = response.data.toggleClasseIsActif;
            this.filterClasses();
          }
        } else {
          console.error("Toggle failed");
        }
      } catch (error) {
        console.error("Error toggling class active status:", error);
      }
    },
    handleCheckboxChange() {
      this.showOnlyActive = !this.showOnlyActive;
      this.filterClasses();
    },
    async getDepartements() {
      try {
        const response = await apolloClient.query({
          query: GET_DEPARTEMENTS,
        });
        this.departements = response.data.getAllDepartements;
      } catch (error) {
        console.error("Erreur de récupération des départements :", error);
      }
    },
    handleSearch(searchData) {
      this.searchQuery = searchData.searchQuery.toLowerCase();
      this.selectedDepartement = searchData.selectedDepartement.toLowerCase();
      this.filterClasses();
    },
    filterClasses() {
      let filtered = this.classes;
      if (this.showOnlyActive) {
        filtered = filtered.filter((item) => item.is_actif === 1);
      }

      if (this.searchQuery || this.selectedDepartement) {
        filtered = filtered.filter((item) => {
          const eltLabel = item.label.toLowerCase();
          const depLabel = item.department?.label?.toLowerCase() || "";
          return (
            eltLabel.includes(this.searchQuery) &&
            depLabel.includes(this.selectedDepartement)
          );
        });
      }
      this.items = filtered;
    },
  },
  computed: {
    paginatedItems() {
      const startIndex = (this.page - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.items.slice(startIndex, endIndex);
    },
  },
  mounted() {
    this.fetchClasses();
    this.getDepartements();
  },
};
</script>

<style scoped>
.banner-bg {
  background-image: url("../../assets/bannerClasse.png");
}
</style>
