<template>
  <v-dialog v-model="modalOpen" max-width="400">
    <template v-slot:activator="{ on }">
      <button
        dark v-on="on" @click="openModal">
         <img :src="img" alt="" class="w-6 h-6 ">
      </button>
    </template>
    <v-card class="rounded-lg px-5">
        <div class="flex flex-col items-center py-6">
            <div class="bg-orange-50 w-16 h-16 flex justify-center items-center rounded-full">
                <img :src="alertcircle" alt="" class="bg-orange-100 w-10 h-10 rounded-full border-1 border-black p-2">
            </div>
        </div>
        <h1 class="text-lg">        
          {{ archived ? "Confirmer désarchivage" : "Confirmer archivage" }}
        </h1>
      <div class="mt-2">
        <label class="mb-2 text-gray-500 text-sm">Êtes-vous sûr d'avoir voulu {{ archived ? 'désarchiver' : 'archiver' }} cet element?</label>
      </div>
      <v-card-actions class="gap-10 flex justify-center mb-6 mt-10">
        <button
          class="bg-gray-200 rounded-full px-6 outline-none text-black font-inter text-sm font-semibold leading-10 tracking-normal text-center"
          @click="closeModal">Annuler</button>
        <button
          class="bg-orange-500 rounded-full px-6 outline-none font-inter text-sm font-semibold leading-10 tracking-normal text-center text-white"
          @click="confirmArchivage">Valider</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import alertcircle from '../../assets/alertcircle.svg';
import archiveOrange from '../../assets/archiveOrange.svg';
export default {
  name: "ConfirmArchivage",
  props: {
    img:{
      type:String
    },
    archived:{
      type:Number
    }
  },
  data() {
    return {
     modalOpen: false,
     alertcircle,
     archiveOrange,
    };
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    confirmArchivage() {
      this.$emit("confirm");
      this.closeModal();
    },
  },

};
</script>
