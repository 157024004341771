<template>
  <SimpleCard class="pb-6 pt-10 px-6">
    <div class="flex items-center justify-center flex-col">
      <UserAvatarImg :nom="userInfos.nom" :image="userInfos.image" />
      <div
        class="text-center text-indigo-900 text-xl font-bold mt-4 text-capitalize"
      >
        {{ userInfos.nom + " " + userInfos.prenom }}
      </div>
      <div class="text-slate-400 text-sm font-medium text-center">
        <div class="mt-2">{{ userInfos.type_translate }}</div>
        <div class="mt-2">Institut : {{ userInfos.etab }}</div>
      </div>
    </div>
    <DashProfileShortcuts />
    <a href="mailto:admin@didousoft.com" target="_blank">
      <DashProfileHelp class="mt-6" />
    </a>
  </SimpleCard>
</template>

<script>
import SimpleCard from "../../../../components/Cards/SimpleCard/SimpleCard.vue";
import UserAvatarImg from "../../../Profile/ProfileSections/ProfileUserImage/UserAvatarImg.vue";
import DashProfileHelp from "./DashProfileHelp/DashProfileHelp.vue";
import DashProfileShortcuts from "./DashProfileShortcuts/DashProfileShortcuts.vue";

export default {
  components: {
    SimpleCard,
    UserAvatarImg,
    DashProfileShortcuts,
    DashProfileHelp,
  },
  props: {
    userInfos: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
