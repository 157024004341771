<template>
  <v-dialog v-model="modalOpen" max-width="500">
    <v-card class="rounded-lg px-5">
      <div class="flex items-center justify-between py-2 border-b">
        <h1 class="text-xl font-semibold">Choisissez l'année universitaire</h1>
        <v-icon class="ml-auto cursor-pointer" @click="closeModal"
          >ri-close-line</v-icon
        >
      </div>
      <div class="mt-4">
        <ModalsDropDown
          v-for="(field, index) in fields"
          :key="index"
          :title="field.label"
          :options="field.options"
          :error="field.error"
          @option-selected="updateSelectedOption(index, $event)"
        />
        <span class="text-red-500 text-sm mt-2" v-if="localErrorMsg">{{
          localErrorMsg
        }}</span>
      </div>
      <v-card-actions class="gap-2 flex justify-end mt-2">
        <GradientButton color="gray" type="soft" @click="closeModal"
          >Annuler</GradientButton
        >
        <GradientButton color="blueDark" type="soft" @click="confirmDuplicate"
          >Confirmer</GradientButton
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import apolloClient from '../../../apolloClient';
import GradientButton from '../../Buttons/GradientButton/GradientButton.vue';
import ModalsDropDown from '../../DropDown/ModalsDropDown.vue';
import {CLONER_EMPLOI} from './ModalDupliquerEmploi.service.js';

export default {
  name: 'ModalDupliquerEmploi',
  components: {
    ModalsDropDown,
    GradientButton,
  },
  props: {
    modelValue: Boolean,
    fields: Array,
    errorMsg: String,
    emploiId: Number,
  },
  data() {
    return {
      modalOpen: this.modelValue,
      localErrorMsg: this.errorMsg,
      selectedOption: null,
    };
  },
  watch: {
    modelValue(value) {
      this.modalOpen = value;
    },
    modalOpen(value) {
      this.$emit('update:modelValue', value);
    },
    errorMsg(newVal) {
      this.localErrorMsg = newVal;
    },
  },
  methods: {
    closeModal() {
      this.$emit('closeModal');
    },
    async confirmDuplicate() {
      if (this.validateSelection()) {
        this.closeModal();

        const {data} = await apolloClient.mutate({
          mutation: CLONER_EMPLOI,
          variables: {
            id: this.emploiId,
            tgt_annee: this.selectedOption,
          },
        });

        if (data.clonerEmploi.success) {
          this.$emit('confirmDuplicate', data.clonerEmploi.message);
        } else {
          this.localErrorMsg = data.clonerEmploi.message;
        }
      }
    },
    validateSelection() {
      if (!this.selectedOption) {
        this.localErrorMsg = 'Veuillez choisir une année universitaire';
        return false;
      }
      return true;
    },
    updateSelectedOption(index, value) {
      this.selectedOption = value;
      this.$emit('updateSelectedOption', index, value);
    },
  },
};
</script>
