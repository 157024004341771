import gql from "graphql-tag";

export const GET_PFES_ENS = gql`
query getEnseignantById($id: Int) {
    getEnseignantById(id: $id) {
      pfes {
          id
          titre
          entreprise
          code_projet
      }
    }
  }
  
`