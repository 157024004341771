<template>
  <div>
    <div
      class="print:hidden pt-16 pb-16 print:pb-0 banner-bg sm:banner-bg-phone flex flex-col bg-cover rounded-lg"
    >
      <div class="flex justify-between px-10">
        <h1 class="text-4xl md:text-right text-white font-Inter font-extrabold">
          {{ props.title }}
        </h1>
        <div>
          <div class="flex gap-4">
            <slot></slot>
          </div>
        </div>
      </div>
    </div>

    <h1
      class="text-4xl md:text-right text-white font-Inter font-extrabold print:block hidden"
    >
      {{ props.title }}
    </h1>
  </div>
</template>
<script setup>
import {defineProps} from 'vue';

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
});
</script>
