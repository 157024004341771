<template>
  <div>
    <SimpleCard class="px-2">
      <div class="flex justify-between pt-4 pb-10">
        <span class="font-semibold text-lg text-gray-800 pl-2"
          >Statistiques</span
        >
        <FilterByYear
          v-model="selectedYear"
          :annees="annees"
          :show-buttons="false"
        />
      </div>

      <div class="flex items-center justify-center relative">
        <div class="relative w-full pb-8 pr-3">
          <canvas id="bar"></canvas>
        </div>
      </div>

      <div class="flex justify-between px-20 pb-8">
        <div class="flex items-center" @Click="toggleData(0)">
          <div id="legend1" class="w-3 h-3 rounded-full"></div>
          <span
            id="legend1Text"
            class="pl-1 text-slate-500 text-xs font-semibold"
          ></span>
        </div>
        <div class="flex items-center" @Click="toggleData(1)">
          <div id="legend2" class="w-3 h-3 rounded-full"></div>
          <span
            id="legend2Text"
            class="pl-1 text-slate-500 text-xs font-semibold"
          ></span>
        </div>
        <div class="flex items-center" @Click="toggleData(2)">
          <div id="legend3" class="w-3 h-3 rounded-full"></div>
          <span
            id="legend3Text"
            class="pl-1 text-slate-500 text-xs font-semibold"
          ></span>
        </div>
      </div>
    </SimpleCard>
  </div>
</template>
<script>
import Chart from 'chart.js/auto';
import SimpleCard from '../../../../components/Cards/SimpleCard/SimpleCard.vue';
import {STAT_CHART} from '../../Dashboard.service';
import apolloClient from '@/apolloClient';
import {GET_ANNEE_UNIVERSITAIRES} from '../../../EmploisDeTemps/EmploisDeTemps.service';
import FilterByYear from '../../../../components/FilterByYear/FilterByYear.vue';
import {getCurrentYear} from '@/SPA/spa.services';
export default {
  name: 'BarChart',
  components: {
    SimpleCard,
    FilterByYear,
  },
  data() {
    return {
      chart: null,
      departementsName: [],
      nbEmplois: [],
      nbClasses: [],
      nbSeances: [],
      annees: [],
      selectedYear: '',
      loadingInterval: null,
    };
  },
  watch: {
    stats() {
      if (this.chart) {
        this.chart.destroy();
        this.barChart();
      }
    },
  },
  methods: {
    barChart() {
      const ctx = document.getElementById('bar');
      if (!ctx || !ctx.getContext('2d')) {
        return;
      }
      const labels = this.departementsName.slice(0, 6);
      const nbEmplois = this.nbEmplois.slice(0, 6);
      const nbClasses = this.nbClasses.slice(0, 6);
      const nbSeances = this.nbSeances.slice(0, 6);

      const dataSet = [
        {
          label: 'Nombre d’emplois de temps ',
          data: nbEmplois,
          backgroundColor: [
            'rgb(44, 123, 229)',
            'rgb(44, 123, 229)',
            'rgb(44, 123, 229)',
          ],
          borderRadius: 7,
        },
        {
          label: 'Nombre de séances',
          data: nbSeances,
          backgroundColor: [
            'rgb(56, 189, 248)',
            'rgb(56, 189, 248)',
            'rgb(56, 189, 248)',
          ],
          borderRadius: 7,
        },
        {
          label: 'Nombre de classes',
          data: nbClasses,
          backgroundColor: [
            'rgb(219, 234, 254)',
            'rgb(219, 234, 254)',
            'rgb(219, 234, 254)',
          ],
          borderRadius: 7,
        },
      ];
      this.chart = new Chart(ctx, {
        type: 'bar',
        options: {
          barPercentage: 0.8,
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
        },
        data: {
          labels: labels,
          datasets: dataSet,
        },
      });
      this.costumLegend();
    },

    async getBarStats() {
      try {
        const response = await apolloClient.query({
          query: STAT_CHART,
          variables: {
            annee_univ: this.selectedYear,
          },
          fetchPolicy: 'no-cache',
        });
        const departmentStats = response.data.getDepartmentStats;
        this.departementsName = departmentStats.map(
          department => department.departmentName
        );
        this.nbEmplois = departmentStats.map(
          department => department.numberOfEmploiTemps
        );
        this.nbClasses = departmentStats.map(
          department => department.numberOfClasses
        );
        this.nbSeances = departmentStats.map(
          department => department.numberOfSeances
        );
        this.barChart();
      } catch (error) {
        console.log(error);
      }
    },
    costumLegend() {
      if (!this.chart) {
        return;
      }
      document.getElementById('legend1').style.backgroundColor =
        this.chart.data.datasets[0].backgroundColor[0];
      document.getElementById('legend1Text').innerText =
        this.chart.data.datasets[0].label;
      document.getElementById('legend2').style.backgroundColor =
        this.chart.data.datasets[1].backgroundColor[1];
      document.getElementById('legend2Text').innerText =
        this.chart.data.datasets[1].label;
      document.getElementById('legend3').style.backgroundColor =
        this.chart.data.datasets[2].backgroundColor[2];
      document.getElementById('legend3Text').innerText =
        this.chart.data.datasets[2].label;
    },
    toggleData(value) {
      if (!this.chart) {
        return;
      }
      const visibilityData = this.chart.isDatasetVisible(value);
      if (visibilityData === true) {
        this.chart.hide(value);
      }
      if (visibilityData === false) {
        this.chart.show(value);
      }
    },

    async getAnneeUniversitaires() {
      try {
        const response = await apolloClient.query({
          query: GET_ANNEE_UNIVERSITAIRES,
        });
        this.annees = response.data.getAnneeUniversitaires;
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    this.selectedYear = getCurrentYear();
    this.getBarStats();
    this.getAnneeUniversitaires();
  },
};
</script>
