<template>
  <div class="relative">
    <div class="shadow-md rounded-lg bg-white">
      <div v-if="archived" class="pl-4 pt-2" @click="toggleArchive">
        <div
          class="w-28 bg-gradient-to-r from-gray-200 to-gray-300 hover:from-gray-300 hover:to-gray-400 cursor-pointer flex p-1 items-center justify-center rounded-lg"
        >
          <span class="text-xs text-gray-500">Désarchiver</span>
          <img :src="unarchive" alt="" class="ml-2 w-4 h-4" />
        </div>
      </div>

      <div class="px-4 py-2 flex justify-between items-center">
        <span class="text-lg text-indigo-900 font-semibold">
          Classe : {{ group }}
        </span>
        <img
          :src="groupImg"
          alt=""
          class="cursor-pointer"
          @click="toggleKebabMenu"
        />
      </div>

      <KebabMenu
        class="absolute right-5 top-8 z-10"
        v-if="openKebabMenu"
        :dataOpenKebabMenu="openKebabMenu"
        :menuItems="menuItems"
        @toggleArchive="toggleArchive"
        @toggleSuppression="toggleSuppression"
        @toggleDuplicate="toggleDuplicate"
      />

      <div class="px-4 flex justify-between">
        <ButtonDisplay :label="nomDep" />
      </div>

      <div class="px-4 mt-4 gap-3">
        <div class="text-sm text-gray-800 font-normal flex justify-between">
          <div>Niveau : {{ niveau }}</div>
          <div>Semestre : {{ semestre }}</div>
        </div>
        <div class="flex items-center text-sm text-gray-800 font-normal">
          <span class="pb-5">Accès collaboratif</span>
          <v-checkbox
            v-if="isEditAllowed"
            :model-value="acces"
            color="teal"
            @change="handleAccessChange"
          ></v-checkbox>
          <v-checkbox
            v-else
            :model-value="acces"
            color="teal"
            :disabled="true"
          ></v-checkbox>
        </div>
      </div>

      <div class="h-16 bg-blue-100 bg-opacity-30 flex justify-between">
        <div class="flex pl-5 items-center">
          <img :src="calendar" alt="" />
          <span class="pl-2 text-indigo-900 font-bold text-sm">
            Séances : {{ nbSeances ?? 0 }}
          </span>
        </div>
        <div class="flex pr-5 items-center">
          <img :src="timer" alt="" />
          <span class="pl-2 text-indigo-900 font-bold text-sm">
            Vol. Horaire : {{ nbVolHoraire }}H
          </span>
        </div>
      </div>

      <div class="px-4 pb-4 mt-4 flex justify-between">
        <span class="items-end text-xs font-normal text-neutral-400 pt-6">
          Edité le : {{ updatedDate }}
        </span>
        <router-link
          :to="`/emploi-classe?id=${emploiId}`"
          target="_blank"
          class="rounded-lg bg-gradient-to-r from-orange-400 to-orange-500 text-white px-6 py-2 flex flex-row gap-2 items-center"
        >
          Accéder
        </router-link>
      </div>
    </div>

    <ModalDupliquerEmploi
      v-model="modalOpen"
      :fields="fields"
      :errorMsg="errorMsg"
      :emploiId="emploiId"
      @updateSelectedOption="updateSelectedOption"
      @closeModal="closeModal"
      @confirmDuplicate="confirmDuplicate"
    />
  </div>
</template>

<script>
import ButtonDisplay from '../../components/Buttons/ButtonDisplay.vue';
import KebabMenu from '../KebabMenu/KebabMenu.vue';
import ModalDupliquerEmploi from '@/components/Modals/ModalDupliquerEmploi/ModalDupliquerEmploi.vue';
import groupImg from '../../assets/groupGray.svg';
import calendar from '../../assets/CalendarOrange.svg';
import timer from '../../assets/timer.svg';
import unarchive from '../../assets/unarchive.svg';
import {isEditAllowed} from '../../SPA/spa.services';
import {getDecryptedType} from '../../SPA/spa.services';

export default {
  name: 'CardItemClasse',
  components: {
    ButtonDisplay,
    KebabMenu,
    ModalDupliquerEmploi,
  },
  props: {
    nomDep: String,
    group: String,
    niveau: String,
    nbSeances: Number,
    nbVolHoraire: Number,
    updatedDate: String,
    acces: Boolean,
    emploiId: Number,
    semestre: String,
    archived: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      groupImg,
      timer,
      calendar,
      unarchive,
      openKebabMenu: false,
      modalOpen: false,
      decryptedType: getDecryptedType(),
      fields: [
        {
          label: 'Année universitaire',
          options: [],
          error: '',
        },
      ],
      selectedOptions: {
        annee_universitaire: '',
      },
      errorMsg: '',
    };
  },
  methods: {
    handleAccessChange() {
      this.$emit('access-change', this.emploiId);
    },
    toggleKebabMenu() {
      this.openKebabMenu = !this.openKebabMenu;
    },
    closeKebabMenu(event) {
      if (!this.$el.contains(event.target)) {
        this.openKebabMenu = false;
      }
    },
    toggleArchive() {
      this.$emit('toggleArchive', this.emploiId);
      this.openKebabMenu = false;
    },
    toggleSuppression() {
      this.$emit('toggleSuppression', this.emploiId);
      this.openKebabMenu = false;
    },
    toggleDuplicate() {
      this.modalOpen = true;
      this.openKebabMenu = false;
    },
    closeModal() {
      this.modalOpen = false;
    },

    availableYears() {
      const currentYear = new Date().getFullYear();
      const nextYear = currentYear + 1;
      this.fields[0].options = [
        {
          id: `${currentYear - 1}-${currentYear}`,
          label: `${currentYear - 1}-${currentYear}`,
        },
        {
          id: `${currentYear}-${nextYear}`,
          label: `${currentYear}-${nextYear}`,
        },
      ];
    },
    updateSelectedOption(index, value) {
      this.selectedOptions.annee_universitaire = value;
    },
    refreshData() {
      this.$emit('refreshData', true);
    },
  },
  computed: {
    menuItems() {
      const archiveLabel = this.archived ? 'Désarchiver' : 'Archiver';
      return [
        {label: archiveLabel, method: this.toggleArchive},
        {label: 'Dupliquer', method: this.toggleDuplicate},
        {label: 'Supprimer', method: this.toggleSuppression},
      ];
    },
    isEditAllowed,
  },
  mounted() {
    document.addEventListener('click', this.closeKebabMenu);
    this.decryptedType = getDecryptedType();
    this.availableYears();
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeKebabMenu);
  },
};
</script>
<style>
.v-input__details {
  visibility: hidden;
}
</style>
