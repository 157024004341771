<template>
  <div class="py-6 px-10 bg-gray-50 flex flex-col gap-8">
    <div class="text-indigo-900 text-4xl font-bold">Mon compte</div>
    <div class="grid lg:grid-cols-3 grid-cols-1 gap-8">
      <ProfileUserImage
        class="md:col-span-1 col-span-2"
        :userInfos="userInfos"
      />
      <ProfileInfosDetails class="col-span-2" :userInfos="userInfos" />
    </div>
    <ProfileChangePassword />
    <ProfileChangeEmail :currentEmail="userInfos.email" />
    <ProfileSupprimerCompte />
  </div>
</template>

<script>
import ProfileChangePassword from "./ProfileSections/ProfileChangePassword/ProfileChangePassword.vue";
import ProfileChangeEmail from "./ProfileSections/ProfileChangeEmail/ProfileChangeEmail.vue";
import ProfileUserImage from "./ProfileSections/ProfileUserImage/ProfileUserImage.vue";
import ProfileInfosDetails from "./ProfileSections/ProfileInfosDetails/ProfileInfosDetails.vue";
import ProfileSupprimerCompte from "./ProfileSections/ProfileSupprimerCompte/ProfileSupprimerCompte.vue";
import apolloClient from "@/apolloClient";
import { GET_CURRENT_USER_INFOS } from "./ProfilePage.service";

export default {
  name: "ProfilePage",
  components: {
    ProfileUserImage,
    ProfileChangePassword,
    ProfileChangeEmail,
    ProfileInfosDetails,
    ProfileSupprimerCompte,
  },
  data() {
    return {
      userInfos: {
        nom: "Chargement",
        prenom: "",
        etab: "Chargement...",
      },
    };
  },
  mounted() {
    this.getCurrentUserInfos();
  },
  methods: {
    async getCurrentUserInfos() {
      try {
        const response = await apolloClient.query({
          query: GET_CURRENT_USER_INFOS,
          fetchPolicy: "no-cache",
        });
        this.userInfos = response.data.getCurrentUserInfos;
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>
