<template>
  <img
    :src="getBackgroundImage()"
    v-if="image.length > 0"
    class="w-20 h-20 rounded-full object-cover border border-4 border-blue-600"
  />
  <div
    v-else
    class="w-20 h-20 rounded-full bg-purple-700 flex justify-center items-center"
  >
    <span class="text-white text-lg font-bold">{{ getAvatarImage() }}</span>
  </div>
</template>

<script>
export default {
  name: "UserAvatarImg",
  props: {
    nom: {
      type: String,
      default: "Test",
    },
    image: {
      type: String,
      default: "",
    },
  },
  methods: {
    getAvatarImage() {
      return this.nom.charAt(0).toUpperCase();
    },
    getBackgroundImage() {
      if (this.image.length > 0) {
        return this.image;
      }
      return "";
    },
  },
};
</script>
