import gql from "graphql-tag";
export const Get_All_Dep = gql`
  query getAllDepartements {
    getAllDepartements {
      id
      label
      etab
      added_at
      updated_at
      user {
        id
        nom
        prenom
      }
      archived
    }
  }
`;
export const IMPORT_CLASSE = gql`
  mutation importClasse(
    $file: String
    $dep_id: Int
    $user_id: Int
    $is_actif: Int
    $etab: String
    $date_update: String
    $id_specialite: Int
  ) {
    importClasse(
      file: $file
      dep_id: $dep_id
      user_id: $user_id
      is_actif: $is_actif
      etab: $etab
      date_update: $date_update
      id_specialite: $id_specialite
    ) {
      success
      message
    }
  }
`;
export const GET_ALL_CLASSES = gql`
  query GetAllClasses {
    getAllClasses {
      id
      label
      niveau
      date_update
      user_id
      dep_id
      etab
      is_actif
      department {
        label
      }
      specialite
      groupes {
        id
        label
      }
    }
  }
`;

export const GET_SPECIALITES_BY_DEPT = gql`
  query specialite($id_dept: Int) {
    getSpecialitesByDepartement(id_dept: $id_dept) {
      id
      label
    }
  }
`;
