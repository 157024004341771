<template>
  <SimpleCard fullWidth>
    <div class="grid sm:grid-cols-2 grid-cols-1 gap-6 p-6">
      <div class="text-red-500 text-7.5">Supprimer compte</div>
      <div class="flex gap-6 flex-col">
        <div class="text-gray-500">
          <span class=" "
            >Ceci entrainera une suppression définitive du compte. Cette action
            est
          </span>
          <span class="text-gray-500 font-bold">IRRÉVERSIBLE</span>
          <span class="text-gray-500"
            >. Une fois effectué, il vous sera impossible de se connecter.
            <br />Les autres membres ne peuvent pas ni vous contacter ni voir
            votre profil.<br />On conservera par conséquent vos données échangés
            avec les autres membres.</span
          >
        </div>
        <div>
          <template v-if="showDeleteBar">
            <InputWithLabel
              showShadow="false"
              type="text"
              label="Confirmer suppression"
              v-model="motSupprimer"
              labelClass="text-red-500 text-sm"
              inputClass="border-red-500"
              placeholder="Tapez le mot : Supprimer compte"
              layoutClass="my-0"
            />
            <ModalErreur
              v-if="isErreur"
              :reason="errorReason"
              @toggleErreur="isErreur = false"
            />
            <div class="text-end mt-2">
              <GradientButton
                text="Confirmer"
                type="outlined"
                @btn-click="validateDelete()"
                color="red"
              />
            </div>
          </template>

          <GradientButton
            v-else
            text="Désactiver définitivement mon compte"
            type="outlined"
            @btn-click="showDeleteBar = true"
            color="red"
          />
        </div>
      </div>
    </div>
  </SimpleCard>
</template>

<script>
import SimpleCard from "../../../../components/Cards/SimpleCard/SimpleCard.vue";
import GradientButton from "../../../../components/Buttons/GradientButton/GradientButton.vue";
import InputWithLabel from "@/components/InputWithLabel/InputWithLabel.vue";
import ModalErreur from "@/components/Popup/Erreurs/ModalErreur.vue";
import apolloClient from "@/apolloClient";
import { DISABLE_ACCOUNT } from "../../ProfilePage.service";
export default {
  name: "ProfileSupprimerCompte",
  components: {
    SimpleCard,
    GradientButton,
    InputWithLabel,
    ModalErreur,
  },
  data() {
    return {
      showDeleteBar: false,
      motSupprimer: "",
      isErreur: false,
      errorReason: "",
    };
  },
  methods: {
    validateDelete() {
      if (this.motSupprimer !== "Supprimer compte") {
        this.isErreur = true;
        this.errorReason =
          "Vous n'avez pas renseigné le bon texte, ré-essayez !";
      } else {
        // ? Proceed delete account
        apolloClient
          .mutate({
            mutation: DISABLE_ACCOUNT,
          })
          .then((resp) => {
            const responseData = resp.data.disableAccount;
            if (responseData.success == true) {
              localStorage.clear("");
              self.location = "";
            } else {
              this.isErreur = true;
              this.errorReason = responseData.message;
            }
          })
          .catch((e) => {
            console.error(e);
          });
      }
    },
  },
};
</script>
