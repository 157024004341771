<template>
  <div class="bg-gray-50">
    <div class="pt-10 md:mx-28">
      <TopBanner title="Gestion des salles">
        <div class="flex gap-4">
          <GradientButton v-if="isEditAllowed" @click="openModal">
            <VIcon>mdi-plus</VIcon>
            <span>Créer</span>
          </GradientButton>

          <ModalImportSalle
            v-if="isEditAllowed"
            @toggle-erreur="toggleErreur"
            @refresh-view="refreshView"
          />
        </div>
      </TopBanner>

      <ModalSalle ref="modalSalle" @refresh-view="refreshView" />
      <ModalErreur v-if="modalErreur" @toggle-erreur="toggleErreur" />

      <div>
        <VTabs
          v-model="currentTab"
          align-tabs="center"
          class="mt-2 print:hidden"
        >
          <VTab>Tout les salles</VTab>
          <VTab>Salles libres</VTab>
          <VTab>Salles occupés</VTab>
        </VTabs>
        <VWindow v-model="currentTab">
          <VWindowItem>
            <div class="bg-white rounded-tl-10 rounded-tr-10">
              <div class="py-4 px-4">
                <SearchWithDeps
                  @search="handleSearch"
                  :DepartementOptions="departements"
                />
              </div>
              <div class="w-full pr-4 justify-end flex">
                <div class="w-60">
                  <v-select
                    v-model="itemsPerPage"
                    label="Nombres d'élements par page"
                    :items="perPageOptions"
                    variant="outlined"
                    class="h-16 text-sm text-slate-600"
                  ></v-select>
                </div>
              </div>
              <div class="p-5">
                <div v-if="paginatedItems.length == 0">
                  Aucun élément à afficher...
                </div>
                <div
                  v-else
                  class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3"
                >
                  <div v-for="(item, index) in paginatedItems" :key="index">
                    <CarteSalle
                      :idSalle="item.id"
                      :nomDep="item.dep_id.label"
                      :idDep="item.dep_id.id"
                      :title="item.label"
                      :bloc="item.bloc"
                      :etat="item.etat"
                      @refresh-view="refreshView"
                      @toggleSupression="toggleSupression(item.id)"
                      @open-modal-salle="openModalSalleHandler"
                    />
                  </div>
                </div>
              </div>
              <div class="ml-auto" v-if="paginatedItems.length > 0">
                <v-pagination
                  v-model="page"
                  variant="flat"
                  prev-icon="mdi-arrow-left"
                  next-icon="mdi-arrow-right"
                  active-color="blue-700 text-white"
                  size="40"
                  rounded="circle"
                  :length="Math.ceil(filterSalles.length / itemsPerPage)"
                ></v-pagination>
              </div>
            </div>
          </VWindowItem>
          <VWindowItem>
            <SallesLibresOrOccupes typeSalle="libre"
          /></VWindowItem>
          <VWindowItem>
            <SallesLibresOrOccupes typeSalle="occupe" />
          </VWindowItem>
        </VWindow>
      </div>
    </div>
    <ColoredModal
      v-if="confirmSupressionOpen"
      title="Confirmez la suppression"
      message="Êtes-vous sûr d'avoir voulu effectuer cette opération?<br> La suppression est définitive."
      @b-confirm="deleteSalle"
      @b-reject="confirmSupressionOpen = false"
    />
  </div>
</template>

<script>
import apolloClient from '../../apolloClient';
import {editAllowed} from './../spa.services.js';
import ModalErreur from '../../components/Popup/Erreurs/ModalErreur.vue';
import plus from '../../assets/plus-sm.png';
import {GET_SALLE, DELETE_SALLE} from '../../SPA/Salles/Salle.service';
import ModalSalle from '@/components/Modals/ModalSalle/ModalSalle.vue';
import ModalImportSalle from '@/components/Modals/ModalSalle/ModalImportSalle.vue';
import CarteSalle from '@/components/CarteSalle/CarteSalle.vue';
import SearchWithDeps from '../Classes/SearchWithDeps.vue';
import {GET_DEPARTEMENTS} from '../../components/Modals/ModalEmploisDeTemps/ModalEmploisDeTemps.service';
import ColoredModal from '../../components/Popup/ColoredModal/ColoredModal.vue';
import SallesLibresOrOccupes from './Components/SallesLibresOrOccupes.vue';
import GradientButton from '@/components/Buttons/GradientButton/GradientButton.vue';
import TopBanner from '@/components/Cards/TopBanner/TopBanner.vue';
export default {
  components: {
    SearchWithDeps,
    ModalSalle,
    ModalImportSalle,
    CarteSalle,
    ModalErreur,
    ColoredModal,
    GradientButton,
    SallesLibresOrOccupes,
    TopBanner,
  },
  data() {
    return {
      plus,
      salles: [],
      items: [],
      page: 1,
      itemsPerPage: 6,
      showOnlyActive: false,
      perPageOptions: [
        {title: '6 élements par page', value: 6},
        {title: '12 élements par page', value: 12},
        {title: '24 élements par page', value: 24},
      ],
      modalErreur: false,
      departements: [],
      selectedDepartement: 'Tout département',
      searchQuery: '',
      isEditAllowed: editAllowed,
      idSalle: null,
      confirmSupressionOpen: false,
      currentTab: 0,
    };
  },
  watch: {
    itemsPerPage() {
      if (Math.ceil(this.items.length / this.itemsPerPage) < this.page) {
        this.page = 1;
      }
    },
  },
  methods: {
    openModal() {
      this.$refs.modalSalle.openModal();
    },
    openModalSalleHandler(salleData) {
      this.$refs.modalSalle.modifierSalle(salleData);
    },
    toggleErreur() {
      this.modalErreur = !this.modalErreur;
    },
    async refreshView() {
      this.fetchSalles(true);
    },
    async fetchSalles(refetch = false) {
      try {
        const response = await apolloClient.query({
          query: GET_SALLE,
          fetchPolicy: refetch ? 'network-only' : 'cache-first',
        });
        this.salles = response.data.getAllSalles;
      } catch (error) {
        console.error('Error fetching Matieres:', error);
      }
    },
    async getDepartements() {
      try {
        const response = await apolloClient.query({
          query: GET_DEPARTEMENTS,
        });
        this.departements = response.data.getAllDepartements;
      } catch (error) {
        console.error('erreur de recuperation departements :', error);
      }
    },
    handleSearch(searchData) {
      this.searchQuery = searchData.searchQuery;
      this.selectedDepartement = searchData.selectedDepartement;
    },
    searchedSalles() {
      let filtered = this.salles;

      if (this.searchQuery) {
        const query = String(this.searchQuery).toLowerCase().trim();
        filtered = filtered.filter(
          item =>
            (item.label && item.label.toLowerCase().includes(query)) ||
            (item.department &&
              item.department.label &&
              item.department.label.toLowerCase().includes(query))
        );
      }
      return filtered;
    },
    toggleSupression(id_salle) {
      this.idSalle = id_salle;
      this.confirmSupressionOpen = !this.confirmSupressionOpen;
    },
    async deleteSalle() {
      try {
        const response = await apolloClient.mutate({
          mutation: DELETE_SALLE,
          variables: {
            id: this.idSalle,
          },
        });
        if (response.data && response.data.deleteSalle) {
          this.refreshView();
          this.openKebabMenu = false;
          this.confirmSupressionOpen = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    paginatedItems() {
      const startIndex = (this.page - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filterSalles.slice(startIndex, endIndex);
    },
    filterSalles() {
      let filteredSalles = this.salles;
      if (this.searchQuery) {
        const query = String(this.searchQuery).toLowerCase().trim();
        filteredSalles = filteredSalles.filter(
          salle => salle.label && salle.label.toLowerCase().includes(query)
        );
      }
      if (
        this.selectedDepartement &&
        this.selectedDepartement != 'Tout département'
      ) {
        filteredSalles = filteredSalles.filter(
          salle => salle.dep_id.label === this.selectedDepartement
        );
      }
      return filteredSalles;
    },
  },
  mounted() {
    this.fetchSalles();
    this.getDepartements();
  },
};
</script>

<style scoped>
.banner-bg {
  background-image: url('../../assets/bannerClasse.png');
}
</style>
