<template>
  <div class="p-4 bg-zinc-50">
    <div class="flex items-center justify-between pb-8">
      <h1 class="text-3xl font-semibold text-slate-600 pt-4 pl-12">
        Liste des départements
      </h1>
      <Modal @departementAdded="refreshData" />
    </div>
    <div>
      <div class="m-3 md:px-28 z-30">
        <RechercheComponent
          :placeholder="'Recherche...'"
          :departements="departements"
          @resultatsFiltres="setDepartementsFiltres"
        />
      </div>
      <div class="flex justify-center pt-20">
        <RoundedButton
          :Buttoncolor="
            boutonSelectionne === 'Actifs' ? 'bg-green-700' : 'bg-green-100'
          "
          :Textcolor="
            boutonSelectionne === 'Actifs' ? 'text-white' : 'text-green-700'
          "
          title="Actifs"
          :svgPath="boutonSelectionne === 'Actifs' ? list : greenList"
          @click="afficherActifs"
        />
        <RoundedButton
          :Buttoncolor="
            boutonSelectionne === 'Archivés' ? 'bg-gray-300' : 'bg-gray-100'
          "
          Textcolor="text-gray-900"
          title="Archivés"
          :svgPath="archive"
          @click="afficherArchives"
        />
        <RoundedButton
          :Buttoncolor="
            boutonSelectionne === 'Tous' ? 'bg-blue-700' : 'bg-blue-100'
          "
          :Textcolor="
            boutonSelectionne === 'Tous' ? 'text-white' : 'text-blue-700'
          "
          title="Tous"
          :svgPath="boutonSelectionne === 'Tous' ? whiteRefresh : refresh"
          @click="afficherTous"
        />
      </div>
      <div class="md:flex sm:flex gap-8 justify-center">
        <div class="mt-10 w-100">
          <div
            v-if="departementsFiltres.length === 0 && departements.length > 0"
            class="grid gap-8 justify-center"
            :class="showDetails ? 'md:grid-cols-2' : 'md:grid-cols-3'"
          >
            <div
              v-for="departement in departements"
              :key="departement.id"
              class="m-4"
            >
              <CarteDepartement
                :title="departement.label"
                :departementId="departement.id"
                :idDirecteur="departement.user.id"
                :archived="departement.archived"
                @toggleArchive="toggleArchive"
                @refreshData="refreshData"
                @info="handleInfo"
              />
            </div>
          </div>
          <div
            v-else-if="departementsFiltres.length > 0"
            class="grid gap-8 justify-center"
            :class="showDetails ? 'md:grid-cols-2' : 'md:grid-cols-3'"
          >
            <div
              v-for="(departement, index) in departementsFiltres"
              :key="index"
              class="m-4"
            >
              <CarteDepartement
                :title="departement.label"
                :departementId="departement.id"
                :archived="departement.archived"
                :idDirecteur="departement.user.id"
                @toggleArchive="toggleArchive"
                @refreshData="refreshData"
                @info="handleInfo"
              />
            </div>
          </div>
          <div v-else>
            <p>Aucun département trouvé.</p>
          </div>
        </div>
        <div
          ref="detailsCard"
          v-if="showDetails && selectedDepartement"
          class="mt-10 flex-grow justify-center"
        >
          <DetailsCarteDepartement
            :id="idDep"
            :departement="selectedDepartement"
            @closeDetailsDep="closeDepDetails"
            @archiveConfirmed="handleConfirmation"
            @departementUpdated="refreshData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoundedButton from "../Buttons/RoundedButton.vue";
import RechercheComponent from "../RechercheComponent/RechercheComponent.vue";
import CarteDepartement from "../CarteDepartement/CarteDepartement.vue";
import Modal from "../../components/Modals/ModalDepartement/ModalDepartement.vue";
import DetailsCarteDepartement from "../CarteDepartement/DetailsCarteDepartement.vue";
import gql from "graphql-tag";
import apolloClient from "../../apolloClient";
import list from "../../assets/list.svg";
import archive from "../../assets/archive.svg";
import refresh from "../../assets/refresh.svg";
import greenList from "../../assets/greenList.svg";
import whiteRefresh from "../../assets/whiteRefresh.svg";
import { Get_All_Dep } from "../Modals/ModalClasse/ModalClasse.service.js";
export default {
  name: "DepartementsList",
  components: {
    RechercheComponent,
    RoundedButton,
    CarteDepartement,
    Modal,
    DetailsCarteDepartement,
  },
  computed: {
    modalOpen() {
      return this.$store.state.modalOpen;
    },
  },
  props: {
    etab: {
      Type: String,
      default: "etab",
    },
  },
  data() {
    return {
      departements: [],
      departementsFiltres: [],
      list,
      archive,
      refresh,
      idDep: null,
      showDetails: false,
      selectedDepartement: null,
      id: null,
      etat: "",
      boutonSelectionne: "Actifs",
      greenList,
      whiteRefresh,
    };
  },

  methods: {
    openModal() {
      this.modalOpen = true;
    },
    async refreshData() {
      const currentState = this.boutonSelectionne;
      this.showDetails = false;
      await this.fetchDepartements(true);
      if (currentState === "Tous") {
        this.afficherTous();
      } else if (currentState === "Archivés") {
        this.afficherArchives();
      } else {
        this.afficherActifs();
      }
    },
    async fetchDepartements(refetch = false) {
      try {
        const response = await apolloClient.query({
          query: Get_All_Dep,
          fetchPolicy: refetch ? "network-only" : "cache-first",
        });
        this.departements = response.data.getAllDepartements;
      } catch (error) {
        console.error("Error fetching Departements:", error);
      }
    },
    async getDepartementById() {
      if (this.idDep) {
        try {
          const response = await apolloClient.query({
            query: gql`
              query getDepartementById($id: Int) {
                getDepartementById(id: $id) {
                  id
                  label
                  etab
                  added_at
                  updated_at
                  user {
                    id
                    nom
                    prenom
                  }
                  archived
                }
              }
            `,
            variables: {
              id: this.idDep,
            },
          });
          this.selectedDepartement = response.data.getDepartementById;
        } catch (error) {
          console.error("Error fetching Department by ID:", error);
        }
      }
    },
    toggleArchive(id_dep) {
      // ? Toggle archive from kebab menu
      this.idDep = id_dep;
      this.archiveDepartement();
    },
    async archiveDepartement() {
      if (this.idDep) {
        try {
          const response = await apolloClient.mutate({
            mutation: gql`
              mutation departement_prs($id: Int) {
                archiveDepartement(id: $id)
              }
            `,
            variables: {
              id: this.idDep,
            },
          });
          if (response.data && response.data.archiveDepartement) {
            this.refreshData();
          } else {
            console.error("Erreur");
          }
        } catch (error) {
          console.error("Une erreur s'est produite :", error);
        }
      }
    },
    setDepartementsFiltres(departementsFiltres) {
      this.departementsFiltres = departementsFiltres;
    },
    handleInfo(info) {
      this.idDep = info.id_dep;
      this.showDetails = info.openDetailsDep;
      this.getDepartementById();
      if (this.$refs.detailsCard) {
        this.$refs.detailsCard.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    },
    handleConfirmation() {
      this.archiveDepartement().then(() => {
        this.getDepartementById();
      });
    },
    closeDepDetails(close) {
      this.showDetails = close.closeDetailsDep;
    },
    afficherActifs() {
      this.departementsFiltres = this.departements.filter(
        (departement) => !departement.archived
      );
      this.boutonSelectionne = "Actifs";
    },
    afficherArchives() {
      this.departementsFiltres = this.departements.filter(
        (departement) => departement.archived
      );
      this.boutonSelectionne = "Archivés";
    },
    afficherTous() {
      this.departementsFiltres = this.departements;
      this.boutonSelectionne = "Tous";
    },
  },
  mounted() {
    this.refreshData();
    this.fetchDepartements();
    this.getDepartementById();
  },
};
</script>
