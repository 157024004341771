<template>
  <div class="py-6 px-10 bg-gray-50 flex flex-col gap-8">
    <div class="flex max-md:flex-wrap md:flex-nowrap gap-8">
      <div class="flex gap-6 flex-col grow">
        <DashProfileCard :userInfos="userInfos" :role="role" />
        <BlocCarteNombres
          :nbEnseignants="nbEnseignants"
          :nbDepartements="nbDepartements"
          :nbClasses="nbClasses"
          :nbEmplois="nbEmplois"
        />
        <BarChart />
      </div>
      <DashSideProfileCard class="w-full lg:w-fit" :userInfos="userInfos" />
    </div>
    <div>
      <div class="text-indigo-900 text-3xl font-bold mb-6">Emplois récents</div>
      <v-carousel
        height="400"
        show-arrows="hover"
        v-if="chunkedSlides.length"
        cycle
        hide-delimiter-background
      >
        <v-carousel-item v-for="(chunk, i) in chunkedSlides" :key="i">
          <v-row>
            <v-col v-for="(emploi, j) in chunk" :key="j" sm="12" lg="4">
              <v-sheet height="100%">
                <CarteEmploiTemps
                  :nomDep="emploi.departement?.label ?? ''"
                  :acces="emploi.acces"
                  :archived="false"
                  :emploiId="emploi.id"
                  :semestre="emploi.semestre"
                  :group="emploi.classe.label"
                  :niveau="emploi.classe.niveau"
                  :nbSeances="emploi.nb_seances"
                  :nbVolHoraire="calculateTotalVolHoraire(emploi)"
                  @acceder="handleAccederClick"
                  @access-change="toggleAcces"
                />
              </v-sheet>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </div>

    <div class="flex max-md:flex-wrap md:flex-nowrap gap-8">
      <div class="grow">
        <div class="text-indigo-900 text-3xl font-bold mb-6">
          Activités récentes
        </div>
        <div class="flex flex-row gap-6 max-md:flex-wrap md:flex-nowrap">
          <DashActivitesRecents class="grow" />
          <DoughnutChart class="min-w-82.5 w-full lg:w-fit" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BlocCarteNombres from '../../components/BlocCarteNombres/BlocCarteNombres.vue';
import DashProfileCard from './DashboardSections/DashProfileCard/DashProfileCard.vue';
import DashSideProfileCard from './DashboardSections/DashSideProfileCard/DashSideProfileCard.vue';
import apolloClient from '@/apolloClient';
import {GET_CURRENT_USER_INFOS} from '@/SPA/Profile/ProfilePage.service';
import {getDecryptedType} from '../spa.services';
import BarChart from './DashboardSections/BarChart/BarChart.vue';
import DoughnutChart from './DashboardSections/DoughnutChart/DoughnutChart.vue';
import {STAT_DASHBOARD, STAT_LAST_EMLPOI} from './Dashboard.service';
import DashActivitesRecents from './DashboardSections/DashActivitesRecents/DashActivitesRecents.vue';
import CarteEmploiTemps from '../../components/CarteEmploiTemps/CarteEmploiTemps.vue';
import {GET_DEROULEMENT_SEANCE_BY_ETAB} from '../Parametre/Parametre.services';

export default {
  name: 'DashBoard',
  components: {
    BlocCarteNombres,
    DashProfileCard,
    DashSideProfileCard,
    BarChart,
    DoughnutChart,
    DashActivitesRecents,
    CarteEmploiTemps,
  },
  data() {
    return {
      userInfos: {
        nom: '...',
        prenom: '',
        departement_str: '...',
      },
      role: getDecryptedType(),
      nbEnseignants: 0,
      nbDepartements: 0,
      nbEmplois: 0,
      nbClasses: 0,
      sallesStats: [],
      enseignantsStat: [],
      emplois: [],
      duree_seance: null,
    };
  },
  mounted() {
    this.getCurrentUserInfos();
    this.getStatsForHorizontalCards();
    this.getLastEmplois();
    this.getDureeSeance();
  },
  computed: {
    chunkedSlides() {
      const chunkSize = this.isMobile() ? 1 : 3;
      const chunks = [];
      for (let i = 0; i < this.emplois.length; i += chunkSize) {
        chunks.push(this.emplois.slice(i, i + chunkSize));
      }
      return chunks;
    },
  },

  methods: {
    isMobile() {
      return window.innerWidth <= 768;
    },
    async getCurrentUserInfos() {
      try {
        const response = await apolloClient.query({
          query: GET_CURRENT_USER_INFOS,
        });
        this.userInfos = response.data.getCurrentUserInfos;
      } catch (e) {
        console.error(e);
      }
    },
    async getStatsForHorizontalCards() {
      try {
        const response = await apolloClient.query({
          query: STAT_DASHBOARD,
        });
        this.nbEnseignants =
          response.data.getStatsForHorizontalCards.getNumberOfEnseignant;
        this.nbClasses =
          response.data.getStatsForHorizontalCards.getNumberOfClasses;
        this.nbDepartements =
          response.data.getStatsForHorizontalCards.getNumberOfDepartments;
        this.nbEmplois =
          response.data.getStatsForHorizontalCards.getNumberOfEmploiTemps;
      } catch (error) {
        console.error(error);
      }
    },
    async getLastEmplois() {
      try {
        const response = await apolloClient.query({
          query: STAT_LAST_EMLPOI,
        });
        this.emplois = response.data.getLatestEmploisTemps;
      } catch (error) {
        console.error(error);
      }
    },
    getDureeSeance() {
      apolloClient
        .query({
          query: GET_DEROULEMENT_SEANCE_BY_ETAB,
        })
        .then(res => {
          this.duree_seance = res.data.getDeroulementSeanceByEtab.duree_seance;
        })
        .catch(e => console.error(e));
    },
    calculateTotalVolHoraire(emploi) {
      const duree_seance = this.duree_seance;
      const nbr_de_seances = emploi.nb_seances;
      switch (duree_seance) {
        case '1h':
          return nbr_de_seances * 1;
        case '1h30':
          return nbr_de_seances * 1.5;
        case '2h':
          return nbr_de_seances * 2;
        default:
          return 0;
      }
    },
  },
};
</script>
