<template>
  <v-dialog v-model="modalOpen" max-width="400">
    <template v-slot:activator="{ on }" v-if="showIcon == 1">
      <button dark v-on="on" @click="openModal">
        <img :src="trash" alt="" class="w-6 h-6" />
      </button>
    </template>
    <v-card class="rounded-lg px-5">
      <div class="flex flex-col items-center py-6">
        <div
          class="bg-red-50 w-16 h-16 flex justify-center items-center rounded-full"
        >
          <img
            :src="alertcircle"
            alt=""
            class="bg-red-100 w-10 h-10 rounded-full border-1 border-black p-2"
          />
        </div>
      </div>
      <h1 class="text-lg">Confirmez la suppression</h1>
      <div class="mt-2">
        <label class="mb-2 text-gray-500 text-sm"
          >Êtes-vous sûr d'avoir voulu effectuer cette opération? La suppression
          est définitive.</label
        >
      </div>
      <v-card-actions class="gap-4 flex justify-center mb-4 mt-6">
        <button
          class="bg-white border border-gray-300 rounded-lg outline-none text-black font-inter text-sm leading-10 tracking-normal text-center w-40"
          @click="closeModal"
        >
          Annuler
        </button>
        <button
          class="bg-red-600 rounded-lg outline-none font-inter text-sm leading-10 tracking-normal text-center text-white w-40"
          @click="ConfirmSuppression"
        >
          Supprimer
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import alertcircle from "../../assets/alertcircleRed.svg";
import trash from "../../assets/trash.svg";
export default {
  name: "ConfirmSuppression",
  props: {
    showIcon: {
      type: Number,
      default: 1
    },
    showModal: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      modalOpen: false,
      alertcircle,
      trash,
    };
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    ConfirmSuppression() {
      this.$emit("confirm");
      this.closeModal();
    },
  },
  mounted() {
    this.modalOpen = (this.showModal == 1)
    if (this.modalOpen) {
      this.openModal()
    }
  }
};
</script>
