<template>
  <v-dialog v-model="modalOpen" max-width="500">
    <template v-slot:activator="{ on }">
      <CreationButton v-on="on" @click="openModal" :type="decryptedType" />
    </template>
    <v-card class="rounded-lg px-5">
      <div class="flex items-center justify-between py-2 border-b">
        <h1 class="text-xl font-semibold">Ajout du département</h1>
        <img :src="Button" class="ml-auto cursor-pointer" @click="closeModal">
      </div>
      <div class="mt-4">
        <label class="mb-2">Veuillez saisir le libellé du département</label>
        <input type="text" v-model="departmentLabel"
          class="w-full px-4 py-2 border border-gray-300 rounded-md mb-4 outline-none">
        <label class="mb-2">Choisir le directeur du département</label>
        <select v-model="selectedDirector"
          class="w-full appearance-none border border-gray-300 rounded-md py-2 pl-4 pr-10 mb-4 outline-none">
          <option value="" disabled hidden selected>Directeur de département</option>
          <option v-for="director in directors" :key="director.id" :value="director.id">{{ director.nom }} {{
            director.prenom }}</option>
        </select>
        <div v-if="directors.length === 0" class="text-orange-500">Tous les directeurs sont déjà affectés.</div>

      </div>
      <v-card-actions class="gap-2 flex justify-end mt-2">
        <button
          class="bg-gray-300 rounded-full px-6 outline-none text-black font-inter text-sm font-semibold leading-10 tracking-normal text-center"
          @click="closeModal">Annuler</button>
        <button
          class="bg-blue-700 rounded-full px-6 outline-none font-inter text-sm font-semibold leading-10 tracking-normal text-center text-white"
          @click="addDepartement">Valider</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import plus from '../../../assets/plus-sm.png';
import Button from '../../../assets/Button.png';
import apolloClient from '../../../apolloClient';
import CryptoJS from 'crypto-js';
import { ADD_Departement, Get_Dep } from './ModalDepartement.service';
import CreationButton from '../../Buttons/CreationButton/CreationButton.vue';
export default {
  name: "ModalDepartement",
  components: {
    CreationButton
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      modalOpen: false,
      plus,
      Button,
      selectedDirector: '',
      departmentLabel: '',
      directors: [],
      decryptedType: '',
      decryptedLabel: '',
    };
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    async addDepartement() {
      try {

        const response = await apolloClient.mutate({
          mutation: ADD_Departement
          ,
          variables: {
            label: this.departmentLabel,
            id_directeur: parseInt(this.selectedDirector)
          }
        });

        if (response.data && response.data.addDepartement) {
          this.$emit('departementAdded');
          this.departmentLabel = '';
          this.selectedDirector = '';
          this.closeModal();
        } else {
          alert("Erreur lors de l'ajout du département !");
        }
      } catch (error) {
        alert("Une erreur s'est produite :", error);
      }
    },
    async getDep() {
      try {
        const etab = localStorage.getItem('etab');
        const response = await apolloClient.query({
          query: Get_Dep,
          variables: {
            type: 'dir_dept',
            etab: etab
          },
        });
        this.directors = response.data.getAllUsersByType;
        this.$emit('departementAdded');
      } catch (error) {
        console.error('Erreur lors de la récupération des directeurs :', error);
      }
    }
  },
  mounted() {
    const typeCrypte = localStorage.getItem('type');
    const bytes = CryptoJS.AES.decrypt(typeCrypte, 'KEY');
    this.decryptedType = bytes.toString(CryptoJS.enc.Utf8);
    this.getDep();
  }
};
</script>
