<template>
  <div>
    <div class="fixed inset-0 bg-black opacity-25 z-40"></div>
    <div
      class="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50"
    >
      <div class="bg-white p-6 rounded-lg shadow-lg w-99 relative">
        <img
          :src="success"
          alt="Union"
          class="absolute -mt-14 left-6 w-18 h-20"
        />

        <h2 class="text-2xl font-bold mb-2 mt-12 text-gray-800">
          Succès de l'opération
        </h2>
        <p class="mb-10 text-gray-500 text-sm">
          Cette opértion a été effectuée avec succés
        </p>
        <div class="flex justify-end">
          <button
            @click="closeModal"
            class="bg-emerald-400 text-white px-6 py-2 rounded"
          >
            OK
          </button>
        </div>
        <img
          :src="greenBubbles"
          alt="Red Bubbles"
          class="absolute bottom-0 left-0 w-24 rounded-lg"
        />
      </div>
    </div>
  </div>
</template>

<script>
import greenBubbles from "../../../assets/green_bubbles.svg";
import success from "../../../assets/success.svg";

export default {
  name: "ModalSuccess",
  data() {
    return {
      greenBubbles,
      success,
    };
  },
  methods: {
    closeModal() {
      this.$emit("toggleSuccess");
    },
  },
};
</script>
