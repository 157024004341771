import gql from 'graphql-tag';

export const UPDATE_DEROULEMENT = gql`
  mutation UpdateDeroulementSeance(
    $hr_demarrage: String!
    $duree_seance: String!
    $duree_pause: String!
    $nbr_de_seances: String!
    $mid_seance: Int!
    $mid_duree: Int!
  ) {
    updateDeroulementSeance(
      hr_demarrage: $hr_demarrage
      duree_seance: $duree_seance
      duree_pause: $duree_pause
      nbr_de_seances: $nbr_de_seances
      mid_seance: $mid_seance
      mid_duree: $mid_duree
    )
  }
`;
export const GET_DEROULEMENT_SEANCE_BY_ETAB = gql`
  query getDeroulementSeanceByEtab {
    getDeroulementSeanceByEtab {
      id
      etab
      date_update
      updated_by
      hr_demarrage
      duree_seance
      duree_pause
      nbr_de_seances
      horaire
      mid_seance
      mid_duree
    }
  }
`;

export const GET_DEROULEMENT_SEANCE_PUBLIC = gql`
  query getDeroulementSeanceByEtab($etab: String!) {
    getDeroulementSeanceByEtab(etab: $etab) {
      id
      etab
      date_update
      updated_by
      hr_demarrage
      duree_seance
      duree_pause
      nbr_de_seances
      horaire
      mid_duree
    }
  }
`;

export const MID_PAUSE_TYPES = {
  MID_2_3: 0,
  MID_3_4: 1,
  MID_4_5: 2,
};
