import gql from "graphql-tag";
export const IMPORT_MATIERE = gql`
  mutation importerMatiere(
    $file: String
    $dep_id: Int
    $id_specialite: Int
    $niveau: String
    $semestre: String
  ) {
    importerMatiere(
      file: $file
      dep_id: $dep_id
      id_specialite: $id_specialite
      niveau: $niveau
      semestre: $semestre
    )
    {
      success
      message
    }
  }
`;
