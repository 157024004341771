import { createRouter, createWebHashHistory } from "vue-router";

const isUserLoggedIn = () => {
  const token = localStorage.getItem("token");
  const nom = localStorage.getItem("nom");
  const prenom = localStorage.getItem("prenom");
  const type = localStorage.getItem("type");

  if (token && nom && prenom && type) {
    return true;
  }

  return false;
};

const routes = [

  {
    path: "/:pathMatch(.*)*",
    name: 'NotFound',
    component: () => import("../SPA/NotFound/notfound.vue"),
  },
  {
    path: "/",
    name: "Auth",
    component: () => import("../views/Auth.vue"),
  },
  {
    path: "/accueil",
    name: "Tableau de bord",
    component: () => import("../SPA/DashBoard/DashBoard.vue"),
  },

  {
    path: "/departements",
    name: "Gestion des départements",
    component: () => import("../components/Departements/DepartementsList.vue"),
  },
  {
    path: "/classes",
    name: "Gestion des classes",
    component: () => import("../SPA/Classes/ClassesList.vue"),
  },
  {
    path: "/matieres",
    name: "Gestion des matières",
    component: () => import("../SPA/Matieres/MatieresList.vue"),
  },

  {
    path: "/enseignants",
    name: "Gestion des enseignants",
    component: () => import("../SPA/Enseignants/EnseignantsList.vue"),
  },

  {
    path: "/salles",
    name: "Gestion des salles",
    component: () => import("../SPA/Salles/SallesList.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../SPA/Profile/ProfilePage.vue"),
  },
  {
    path: "/emplois-temps",
    name: "Emplois de temps",
    component: () => import("../SPA/EmploisDeTemps/EmploisDeTemps.vue"),
  },

  {
    path: "/parametres",
    name: "Déroulement des séances",
    component: () => import("../SPA/Parametre/Parametre.vue"),
  },

  {
    path: "/gestion-membres",
    name: "Gestion des membres",
    component: () => import("../SPA/GestionMembres/GestionMembres.vue"),
  },

  {
    path: "/absences",
    name: "Gestion des absences",
    component: () => import("../SPA/AbsencesPage/AbsencesPage.vue"),
  },
  {
    path: "/heures-supp",
    name: "Heures supp",
    component: () => import("../SPA/HeuresSupp/HeuresSupp.vue"),
  },
  {
    path: "/emploi-classe",
    name: "Emploi du temps classe",
    component: () =>
      import("../SPA/EmploiDuTempsClasse/EmploiDuTempsClasse.vue"),
  },
  {
    path: "/emploi-enseignant",
    name: "EmploiEnseignant",
    component: () => import("../SPA/Enseignants/EmploiEnseignant.vue"),
  },
  {
    path: "/emploi-salle",
    name: "EmploiSalle",
    component: () => import("../SPA/Salles/EmploiSalle.vue"),
  },
  {
    path: "/emploi-classe-public",
    name: "EmploiClassePublic",
    component: () =>
      import("../SPA/EmploiDuTempsClasse/EmploiClassePublic.vue"),
  },
  {
    path: "/emploi-classe-print",
    name: 'PrintPage',
    component: () => import("../SPA/EmploisForPrint/EmploisForPrint.vue"),
  },
  {
    path: "/emploi-enseignants-print",
    name: 'PrintPageEnseignants',
    component: () => import("../SPA/Enseignants/EmploiEnseignantForPrint.vue"),
  },

];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");

  if (
    to.name !== "Auth" &&
    to.name !== "EmploiClassePublic" &&
    !isUserLoggedIn() && !token
  ) {
    next({ name: "Auth" });
  }
  if (to.name === "Auth" && isUserLoggedIn()) {
    next({ path: "/accueil" });
  } else next();
});

export default router;
