<template>
  <div class="relative bg-white p-6 rounded-lg shadow-lg mx-auto max-w-3xl justify-center">
    <v-icon class="absolute top-2 right-2 w-6 h-6 z-30 cursor-pointer" @click="closeStatsBloc">mdi-close</v-icon>
    <div class="flex justify-center gap-8 py-6 px-3">
      <div class="flex flex-col items-center w-44">
        <img :src="grid" class="w-6 h-6" />
        <div class="text-center text-gray-800 text-2xl font-normal mt-4">
          {{ stats.nombre_matieres }}
        </div>
        <div class="text-center">Nombre de matières</div>
      </div>
      <div class="flex flex-col items-center w-44">
        <img :src="heure" class="w-6 h-6" />
        <div class="text-center text-gray-800 text-2xl font-normal mt-4">
          {{ stats.vol_horaire }}H
        </div>
        <div class="text-center">Volume horaire total</div>
      </div>
      <div class="flex flex-col items-center w-44">
        <img :src="table" class="w-6 h-6" />
        <div class="text-center text-gray-800 text-2xl font-normal mt-4">
          {{ stats.specialite }}
        </div>
        <div class="text-center">Spécialités</div>
      </div>
    </div>
  </div>
</template>
<script>
import grid from "@/assets/grid.svg";
import heure from "@/assets/heure.svg";
import table from "@/assets/table.svg";
import apolloClient from "@/apolloClient";
import { GET_STATS_MATIERES } from "../Matiers.services"; 
export default {
  name: "MatiereStats",
  data() {
    return {
      grid,
      heure,
      table,
      stats: {
        vol_horaire: 0,
        specialite: 0,
        nombre_matieres: 0,
      },
    };
  },
  mounted() {
    this.fetchStats();
  },
  methods: {
    fetchStats() {
      apolloClient
        .query({
          query: GET_STATS_MATIERES,
        })
        .then((response) => {
          this.stats = response.data.getMatiereStats;
        })
        .catch((error) => {
          console.error("Error fetching stats:", error);
        });
    },
    closeStatsBloc() {
     this.$store.commit('toggleStatBloc')
    },
  },
};
</script>