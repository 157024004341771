<template>
  <div class="rounded-5 bg-white" :class="{ 'shadow-lg': noShadow != true }">
    <!-- header -->
    <div
      v-if="title"
      class="text-[#2B3674] text-xl font-semibold pt-4 pb-2 px-8 border-b"
      :class="titleClass"
    >
      {{ title }}
    </div>
    <!-- body -->
    <div :class="{ 'p-3.5': fullWidth != true }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "SimpleCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    noShadow: {
      type: Boolean,
      default: false,
    },
    titleClass: {
      type: String,
      default: "",
    },
  },
};
</script>
