<template>
    <SimpleCard title="Informations de base" fullWidth>
        <div class="p-8 mt-2">
            <InformationRow value="Chargement en cours ..." v-if="!userInfos.nom" />
            <div class="flex gap-4 flex-col" v-else>
                <InformationRow title="Nom" :value="userInfos.nom" />
                <InformationRow title="Prénom" :value="userInfos.prenom" />
                <InformationRow title="Identifiant" :value="userInfos.cin" />
                <InformationRow title="Type d'accès" :value="userInfos.type_translate" />
                <InformationRow title="Date de naissance" :value="userInfos.date_naissance" />
            </div>
        </div>
    </SimpleCard>
</template>

<script>
import SimpleCard from '../../../../components/Cards/SimpleCard/SimpleCard.vue'
import InformationRow from './InformationRow.vue';

export default {
    name: 'ProfileInfosDetails',
    components: {
        SimpleCard,
        InformationRow
    },
    props: {
        userInfos : {
            type : Object,
            default: () => ({})
        }
    },

   
}
</script>
