<template>
  <div class="flex flex-row flex-wrap gap-6">
    <CarteNombres
      v-for="(item, index) in items()"
      :key="index"
      :number="item.number"
      :element="item.element"
      :title="item.title"
    />
  </div>
</template>
<script>
import CarteNombres from "../../components/CarteNombres/CarteNombres.vue";
export default {
  name: "BlocCarteNombres",
  components: {
    CarteNombres,
  },
  props: {
    nbEnseignants:Number,
    nbDepartements:Number,
    nbEmplois:Number,
    nbClasses:Number
  },
  methods:{
    items() {
      return [
        {
          number: this.nbDepartements,
          element: "Départements",
          title: "Départements",
        },
        {
          number: this.nbEmplois,
          element: "Emplois",
          title: "Emplois de temps",
        },
        {
          number: this.nbEnseignants,
          element: "Enseignants",
          title: "Enseignants",
        },
        { number: this.nbClasses,
          element: "Classes", 
          title: "Classes" 
        },
      ];
    }
  }
};
</script>
