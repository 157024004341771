<template>
  <div
    class="w-10 h-10 p-1.5 bg-white rounded-full shadow justify-center items-center"
  >
    <div class="w-7 h-7">
      <img :src="icon" alt="icon" />
    </div>
  </div>
</template>

<script>
export default {
  name: "RoundedIcon",
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>
