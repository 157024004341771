<template>
    <div  class="w-56 p-1 flex items-center bg-blue-50 border-0 rounded-full">
        <div class="h-8 w-8 rounded-full flex items-center justify-center bg-blue-500 text-white">
            {{count}}
        </div>
        <div class="text-blue-500 pr-6 pl-2">
            <p class="">Elements sélectionnés</p>
        </div>
    </div>
</template>
<script>
export default {
 props:{
    count:{
        Type:Number
    }
 }
}
</script>
