<template>
  <div>
    <span class="text-base font-semibold pl-10 pt-6"
      >Code PFE : {{ code_projet }}</span
    >
    <div class="rounded-md border-2 border-neutral-200 mx-10 my-4">
      <div class="p-4 grid grid-rows-1 gap-4">
        <div class="flex justify-between">
          <span class="text-gray-800 text-sm font-bold">Titre: </span>
          <span class="text-gray-600 text-sm">
            {{ titre }}
          </span>
        </div>
        <div class="flex justify-between">
          <span class="text-gray-800 text-sm font-bold">Entreprise: </span>
          <span class="text-gray-600 text-sm">
            {{ entreprise }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalPfeBloc",
  props: {
    titre: String,
    entreprise: String,
    code_projet: String,
  },
};
</script>
