<template>
    <div>
        <v-chip v-for="(item, index) in items" :key="index" density="comfortable" :size="chipSize"
            :color="setColor(item)">
            {{ capitalizeFirstLetters(item) }}
        </v-chip>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: true
        },
        chipSize: {
            type: String,
            default: 'small'
        }
    },
    methods: {
        setColor(value) {
            switch (value) {
                case 'cours':
                    return 'orange';
                case 'cours integrés':
                    return 'deep-purple';
                case 'td':
                    return 'green';
                case 'tp':
                    return 'blue';
                default:
                    return 'gray';
            }
        },
        capitalizeFirstLetters(string) {
            if (string.length === 2) {
                return string.toUpperCase();
            } else {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }
        }
    }
}
</script>
