import gql from "graphql-tag";
export const GET_CURRENT_USER_INFOS = gql`
  query userInfos {
    getCurrentUserInfos {
      nom
      prenom
      date_naissance
      cin
      type_translate
      etab
      email
      departement_str
    }
  }
`;

export const UPDATE_MDP = gql`
  mutation changerMdp($oldMdp: String, $newMdp: String, $confirmMdp: String) {
    changerMdp(oldMdp: $oldMdp, newMdp: $newMdp, confirmMdp: $confirmMdp) {
      success
      message
    }
  }
`;

export const UPDATE_EMAIL = gql`
  mutation changerEmail($oldMdp: String, $newEmail: String) {
    changerEmail(oldMdp: $oldMdp, newEmail: $newEmail) {
      success
      message
    }
  }
`;

export const DISABLE_ACCOUNT = gql`
  mutation disableAccount {
    disableAccount {
      success
      message
    }
  }
`;
