<template>
  <button
    type="button"
    ref="button"
    class="rounded-full px-4 h-10 gap-2 items-center print:hidden transition duration-500 shadow opacity-90 hover:opacity-100 hover:shadow-lg"
    :class="[getButtonBgColor(), textFont]"
    @click="click"
  >
    <v-icon v-if="icon" size="small">{{ icon }}</v-icon>
    {{ text }}
    <slot></slot>
  </button>
</template>
<script>
export default {
  name: 'GradientButton',
  props: {
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'normal',
    },
    color: {
      type: String,
      default: 'blue',
    },
    icon: {
      type: String,
      required: false,
      default: '',
    },
    size: String,
    textFont: {
      type: String,
      default: 'font-semibold',
    },
  },
  mounted() {},
  methods: {
    getButtonBgColor() {
      if (this.type == 'normal') {
        // ? Return gradient colors
        const gradientColorsClassMap = {
          blue: 'from-[#000046] to-[#1CB5E0] text-white',
          red: 'from-[#ED213A] to-[#93291E] text-white',
          green: 'from-[#56AB2F] to-[#A8E063] text-white',
          gray: 'gradient-gray text-white',
        };
        return [
          gradientColorsClassMap[this.color] ?? gradientColorsClassMap.blue,
          'bg-gradient-to-r',
        ];
      }
      if (this.type == 'outlined') {
        const outlinedColorsClassMap = {
          blue: 'text-blue-700 border-simple border-blue-700',
          green: 'text-green-700 border-simple border-green-700',
          orange: 'text-orange-500 border-simple border-orange-500',
          gray: 'text-gray-900 border-simple border-gray-900',
          red: 'text-red-500 border-simple border-red-600',
          yellow: 'text-yellow-700 border-simple border-yellow-700',
          purple: 'text-purple-700 border-simple border-purple-700',
          pink: 'text-pink-700 border-simple border-pink-700',
        };
        return (
          outlinedColorsClassMap[this.color] ?? outlinedColorsClassMap.blue
        );
      }
      // ? return soft colors
      const softColorsClassMap = {
        blue: 'bg-blue-100 text-blue-700',
        primary: 'bg-blue-700 text-white',
        blueDark: 'bg-blue-900 text-white',
        green: 'bg-green-100 text-green-700',
        orange: 'bg-orange-100 text-orange-500',
        gray: 'bg-gray-100 text-gray-900',
        red: 'bg-red-500 text-red-100',
        redLite: 'bg-gray-100 text-red-500',
      };
      const targetColor =
        softColorsClassMap[this.color] ?? softColorsClassMap.blue;
      return targetColor + ' border-simple border-transparent';
    },
    click($event) {
      this.$emit('btn-click', $event);
    },
  },
};
</script>
