<template>
  <v-dialog v-model="isModalOpened" max-width="444">
    <v-card class="rounded-lg px-5">
      <div class="flex items-center justify-between py-2 border-b">
        <h1 class="text-xl font-semibold">Gestion spécialités</h1>
        <img :src="Button" class="ml-auto cursor-pointer" @click="closeModal" />
      </div>
      <div class="my-2">
        <label
          class="block font-inter text-base font-normal leading-6 text-left text-gray-900"
        >
          Ajout spécialités
        </label>
      </div>
      <div class="flex items-center mb-2 gap-6">
        <div class="grow">
          <input
            type="text"
            v-model="specialite"
            class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none"
          />
        </div>
        <div>
          <button
            class="bg-violet-600 rounded-lg px-6 outline-none text-white font-inter text-sm font-semibold leading-10 tracking-normal text-center"
            @click="addSpecialites"
          >
            valider
          </button>
        </div>
      </div>
      <div class="w-full py-2 space-x-2">
        <v-chip
          v-for="item in specialites"
          :key="item.id"
          closable
          density="comfortable"
          :size="chipSize"
          :class="item.color"
          class="mb-2"
          @click:close="deleteSpecialite(item.id)"
        >
          {{ item.label }}
        </v-chip>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import Button from "../../../assets/Button.png";
import apolloClient from "../../../apolloClient";
import {
  GET_DEPARTEMENT_BY_ID,
  DELETE_SPECIALITE,
  ADD_SPECIALITE,
} from "./ModalSpecialite.service.js";
export default {
  name: "ModalSpecialité",
  data() {
    return {
      Button,
      isModalOpened: false,
      specialite: "",
      specialites: [],
      colors: [
        "bg-cyan-400",
        "bg-orange-400",
        "bg-pink-400",
        "bg-violet-400",
        "bg-blue-400",
        "bg-fuchsia-400",
      ],
    };
  },
  props: {
    chipSize: {
      type: String,
      default: "small",
    },
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    departementId: {
      type: Number,
    },
  },
  watch: {
    isModalOpen(newVal) {
      this.isModalOpened = newVal;
      if (newVal) {
        this.getSpecialites();
        this.setInitialColors();
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal", true);
    },
    async addSpecialites() {
      try {
        if (this.specialite.trim().length < 1) {
          alert("Veuillez entrer une spécialité valide.");
          return;
        }
        const response = await apolloClient.mutate({
          mutation: ADD_SPECIALITE,
          variables: {
            label: this.specialite,
            id_dept: this.departementId,
          },
        });

        if (response.data) {
          const specialiteObj = {
            id: response.data.addSpecialite,
            label: this.specialite,
            color: this.getRandomColor(),
          };
          this.specialites.push(specialiteObj);
          this.specialite = "";
        } else {
          alert("Erreur lors de l'ajout de spécialité !");
        }
      } catch (error) {
        alert("Une erreur s'est produite : " + error);
      }
    },
    setInitialColors() {
      this.specialites.forEach((item, index) => {
        item.color = this.colors[index % this.colors.length];
      });
    },
    getRandomColor() {
      return this.colors[Math.floor(Math.random() * this.colors.length)];
    },
    async deleteSpecialite(index) {
      try {
        await apolloClient.mutate({
          mutation: DELETE_SPECIALITE,
          variables: {
            id: index,
          },
          refetchQueries: [
            {
              query: GET_DEPARTEMENT_BY_ID,
              variables: {
                id: this.departementId,
              },
            },
          ],
        });
      } catch (error) {
        alert("Une erreur s'est produite : " + error);
      }
    },

    getSpecialites() {
      apolloClient
        .query({
          query: GET_DEPARTEMENT_BY_ID,
          variables: {
            id: this.departementId,
          },
          fetchPolicy: "no-cache",
        })
        .then((response) => {
          this.specialites = response.data.getDepartementById.specialites;
        })
        .catch((error) => {
          alert("Une erreur s'est produite");
          console.error("Une erreur s'est produite :", error);
        });
    },
  },
  mounted() {
    if (this.isModalOpen) {
      this.getSpecialites();
      this.setInitialColors();
    }
  },
};
</script>
