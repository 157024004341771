<template>
  <div class="pb-5 px-3">
    <div class="p-4 shadow-md rounded-lg min-h-64">
      <div class="flex justify-between">
        <ButtonDisplay :label="nomDep" />
        <Toggle
          v-if="isEditAllowed()"
          :value="isActive"
          textYes="Actif"
          textNo="Inactif"
          @toggle-input="toggleActive"
        />
        <Toggle
          v-else
          :value="isActive"
          disabled
          textYes="Actif"
          textNo="Inactif"
          :isDisabled="true"
        />
      </div>
      <div class="mt-4 flex items-center gap-3">
        <div class="w-4 h-4 bg-blue-600 rounded-full"></div>
        <div class="text-xl text-gray-800 font-semibold">
          {{ labelClasse }}
        </div>
      </div>
      <div class="text-center text-gray-500 mt-2.5 text-base mb-2">
        {{ specialite }}
      </div>
      <div class="h-2 bg-gray-100 rounded-full mb-2">
        <div class="w-40 h-2 bg-amber-300 rounded-full"></div>
      </div>
      <div class="flex hidden">
        <img
          v-for="(img, ind) in images"
          v-bind:key="ind"
          class="rounded-full w-9 h-9 border"
          :class="{'-ml-2.5': ind > 0}"
          :src="img"
        />
        <div
          class="rounded-full bg-neutral-100 flex items-center justify-center w-9 h-9 -ml-3.5"
        >
          <div>+1</div>
        </div>
      </div>
      <div class="flex flex-wrap gap-2 mt-2">
        <div
          v-for="(groupe, index) in groupes"
          :key="index"
          class="px-4 py-2 mt-1 rounded-full bg-blue-50 text-blue-600 items-center mr-4 flex text-xs"
        >
          {{ groupe.label }}
          <v-icon
            icon="mdi-close"
            size="small"
            v-if="isEditAllowed()"
            color="blue-600"
            class="ml-2"
            @click="showPopup(groupe.id)"
          />
        </div>
        <button
          v-if="
            groupes.length < 4 &&
            (decryptedType === 'dir_dept' || decryptedType === 'dir_etu')
          "
          class="rounded-full bg-blue-600 text-white px-6 py-2 mt-2 flex flex-row gap-2 items-center text-xs"
          @click="addGroupe"
        >
          <div>+</div>
          Groupe
        </button>
      </div>
    </div>
    <SupprimerGroupe
      v-if="showDeletePopup"
      @closeModal="showDeletePopup = false"
      @confirmSuppression="value => deleteGroupe(value)"
      :groupId="currentGroupeId"
    />
  </div>
</template>
<script>
import CryptoJS from 'crypto-js';
import ButtonDisplay from '../../components/Buttons/ButtonDisplay.vue';
import Toggle from '../../components/Toggle/Toggle.vue';
import {ADD_GROUPE, DELETE_GROUPE} from './Groupe.service';
import {GET_ALL_CLASSES} from '../Modals/ModalClasse/ModalClasse.service';
import apolloClient from '../../apolloClient';
import SupprimerGroupe from '../Popup/popupSuprimerGroupe/SupprimerGroupe.vue';
export default {
  name: 'CardItemClasse',
  components: {
    ButtonDisplay,
    Toggle,
    SupprimerGroupe,
  },
  props: {
    nomDep: String,
    labelClasse: String,
    isActive: Number,
    classId: {
      type: Number,
      required: true,
    },
    specialite: String,
    groupes: {
      type: Array,
      required: true,
    },
  },
  methods: {
    toggleActive(newValue) {
      this.$emit('toggleActive', this.classId, newValue);
    },
    async addGroupe() {
      try {
        await apolloClient.mutate({
          mutation: ADD_GROUPE,
          variables: {
            classe_id: this.$props.classId,
          },
          refetchQueries: [
            {
              query: GET_ALL_CLASSES,
            },
          ],
        });
        this.$emit('refresh-view', 1);
      } catch (error) {
        console.error('Error importing classes:', error);
      }
    },
    async deleteGroupe(groupeId) {
      try {
        await apolloClient.mutate({
          mutation: DELETE_GROUPE,
          variables: {
            id: groupeId,
          },
          refetchQueries: [
            {
              query: GET_ALL_CLASSES,
            },
          ],
        });
        this.$emit('refresh-view', 1);
      } catch (error) {
        console.error('Error importing classes:', error);
      }
    },
    showPopup(groupId) {
      this.currentGroupeId = groupId;
      this.showDeletePopup = true;
    },
    isEditAllowed() {
      return (
        this.decryptedType === 'dir_dept' || this.decryptedType === 'dir_etu'
      );
    },
  },
  mounted() {
    const typeCrypte = localStorage.getItem('type');
    const bytes = CryptoJS.AES.decrypt(typeCrypte, 'KEY');
    this.decryptedType = bytes.toString(CryptoJS.enc.Utf8);
  },
  data() {
    return {
      images: [
        'https://www.svgrepo.com/show/384674/account-avatar-profile-user-11.svg',
        'https://www.svgrepo.com/show/384674/account-avatar-profile-user-11.svg',
        'https://www.svgrepo.com/show/384674/account-avatar-profile-user-11.svg',
        'https://www.svgrepo.com/show/384674/account-avatar-profile-user-11.svg',
      ],
      decryptedType: '',
      showDeletePopup: false,
      currentGroupeId: null,
    };
  },
};
</script>
