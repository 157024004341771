<template>
    <div :class="layoutClass.length > 0 ? layoutClass : 'my-4'" class="flex flex-col w-full gap-2">
        <span class="text-l text-gray-500 font-inter font-semibold text-left w-full" :class="labelClass">{{ label
            }}</span>
        <input :class="[
            'peer block w-full placeholder-gray-300 bg-white rounded-md border-simple p-2.5 px-5 py-2.5 text-black',
            { 'shadow-lg drop-shadow-lg': showShadow == true },
            inputClass,
            (inputClass.length > 0 ? 'border-simple' : 'border'),
            'focus:border-blue-500 focus:outline-none'
        ]" :type="type" :placeholder="placeholder" v-model="value" />
    </div>
</template>

<script>
export default {
    name: "InputWithLabel",
    emits: ['update:modelValue'],
    props: {
        label: {
            type: String,
            required: true
        },
        placeholder: {
            type: String,
            default: '|...'
        },
        type: {
            default: "text",
            required: false,
            type: String
        },
        showShadow: {
            default: true,
            type: Boolean
        },
        layoutClass: {
            default: '',
            type: String
        },
        labelClass: {
            default: '',
            type: String
        },
        inputClass: {
            default: '',
            type: String
        },
        modelValue: String,
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value)
            }
        }
    }
}
</script>
