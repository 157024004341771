<template>
  <div class="pr-1">
    <button
      class="flex p-2 px-3 w-24 bg-blue-50 rounded-3xl duration-200 hover:bg-blue-600 text-blue-600 text-sm font-Inter hover:text-white not-italic border-simple border-blue-600 max-sm:hidden justify-center"
      @mouseenter="isHovering = true"
      @mouseleave="isHovering = false"
      @click="emitAction"
    >
      <img
        v-if="icon"
        :src="icon"
        class="h-6 w-8 -ml-1"
        :style="{ filter: isHovering ? 'brightness(0) invert(1)' : 'none' }"
      />
      <span class="mt-0.5 text-center">{{ buttonText }}</span>
    </button>
    <img
      @click="emitAction"
      :src="icon"
      class="h-6 w-8 md:hidden sm:hidden"
      :style="{ filter: isHovering ? 'brightness(0) invert(1)' : 'none' }"
    />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isHovering: false,
    };
  },
  methods: {
    emitAction() {
      this.$emit("action-clicked", this.item);
    },
  },
};
</script>
