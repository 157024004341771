<template>
  <div>
    <div class="text-slate-500 text-xs font-bold pt-5 pb-7.5">Raccourcis</div>
    <div class="flex gap-5 flex-col">
      <ShortcutLink
        v-for="(option, ind) in options"
        :key="ind"
        :icon="option.icon"
        :route="option.route"
        :title="option.title"
      />
    </div>
  </div>
</template>

<script>
import { getDecryptedType } from "@/SPA/spa.services";
import ShortcutLink from "./ShortcutLink.vue";
export default {
  name: "DashProfileShortcuts",
  components: {
    ShortcutLink,
  },
  data() {
    return {
      options: [
        {
          title: "Paramètre & sécurité",
          icon: require("@/assets/icon-lock.svg"),
          route: "/profile",
        },
        {
          title: "Emplois de temps",
          icon: require("@/assets/icon-doc.svg"),
          route: "/emplois-temps",
        },
        {
          title: "Classes",
          icon: require("@/assets/icon-list.svg"),
          route: "/classes",
        },
      ],
    };
  },
  mounted() {
    const currentRole = getDecryptedType();
    if (currentRole == "dir_etu") {
      this.options.push({
        title: "Déroulement des séances",
        icon: require("@/assets/icon-cog.svg"),
        route: "/parametres",
      });
    }
  },
};
</script>
