import gql from "graphql-tag";
export const IMPORT_SALLE = gql`
  mutation salle_prs($file: String, $dep_id: Int, $etat: String) {
    importSalle(file: $file, dep_id: $dep_id, etat: $etat)
  }
`;

export const UPDATE_SALLE = gql`
  mutation updateSalle(
    $id: Int!,
    $label: String!,
    $dep_id: Int!,
    $etat: String!,
    $bloc: String!
  ) {
    updateSalle(
      id: $id,
      label: $label,
      dep_id: $dep_id,
      etat: $etat,
      bloc: $bloc
    )
  }
`;

export const ADD_SALLE = gql`
  mutation addSalle(
    $label: String!,
    $dep_id: Int!,
    $etat: String!,
    $bloc: String!
  ) {
    addSalle(
      label: $label,
      dep_id: $dep_id,
      etat: $etat,
      bloc: $bloc
    )
  }
`;