<template>
  <div class="py-5 px-5">
    <div class="shadow-md rounded-2xl flex relative">
      <div :class="`w-4  ${getColor('bg')} rounded-l-3xl`"></div>
      <div class="flex flex-col w-full p-4">
        <div class="flex justify-between">
          <ButtonDisplay :label="nomDep" />
          <img
            v-if="isEditAllowed()"
            :src="groupImg"
            alt=""
            class="cursor-pointer w-6"
            @click="toggleKebabMenu"
          />
          <KebabMenu
            v-if="openKebabMenu"
            class="absolute right-4 top-11 z-10"
            :dataOpenKebabMenu="openKebabMenu"
            :menuItems="menuItems"
            @toggleSupression="toggleSupression"
          />
        </div>
        <div class="text-xl pt-4 text-gray-800 font-semibold text-capitalize">
          Salle : {{ title }}
        </div>
        <div class="text-lg pt-3.5 text-gray-500 text-capitalize">
          Bloc : {{ bloc }}
        </div>
        <div class="text-lg pt-3.5 text-gray-500 text-capitalize">
          Etat : {{ displayEtat() }}
        </div>
        <div class="flex mt-2 justify-end">
          <router-link :to="`/emploi-salle?id=${idSalle}`" target="_blank">
            <GradientButton text="Emploi salle" type="soft" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ButtonDisplay from '../../components/Buttons/ButtonDisplay.vue';
import groupImg from '../../assets/groupGray.svg';
import KebabMenu from '../KebabMenu/KebabMenu.vue';
import GradientButton from '../Buttons/GradientButton/GradientButton.vue';
import {editAllowed} from '@/SPA/spa.services';
export default {
  name: 'CarteSalle',
  components: {
    ButtonDisplay,
    KebabMenu,
    GradientButton,
  },
  props: {
    idDep: Number,
    nomDep: {
      Type: String,
      default: 'mecanique',
    },
    title: {
      Type: String,
      default: 'Salle 1',
    },
    bloc: {
      Type: String,
      default: 'Mecanique',
    },
    etat: {
      Type: String,
      default: 'Libre',
    },
    idSalle: {
      Type: Number,
      required: true,
    },
  },
  data() {
    return {
      groupImg,
      openKebabMenu: false,
    };
  },
  methods: {
    getColor() {
      const labels = [
        ['a', 'e', 'i', 'm', 'q', 'u', 'y'],
        ['b', 'f', 'j', 'n', 'r', 'v'],
        ['c', 'g', 'k', 'o', 's', 'w'],
        ['d', 'h', 'l', 'p', 't', 'x'],
      ];
      const index = labels.findIndex(list => {
        if (this.nomDep) {
          return list.includes(this.nomDep.charAt(0).toLowerCase());
        }
        return false;
      });

      const bgColors = [
        'bg-teal-500',
        'bg-orange-500',
        'bg-lime-500',
        'bg-amber-500',
      ];

      return bgColors[index] || bgColors[0];
    },
    displayEtat() {
      switch (this.etat) {
        case 'libre':
          return 'Exploitable';
        case 'maintenance':
          return 'Maintenance';
        case 'inactif':
          return 'Inactif';
        case 'reserve':
          return 'Réservé';
        default:
          return 'Inconnu';
      }
    },
    openModalSalle() {
      const salleData = {
        idSalle: this.idSalle,
        idDep: this.idDep,
        nomDep: this.nomDep,
        title: this.title,
        bloc: this.bloc,
        etat: this.etat,
      };
      this.$emit('open-modal-salle', salleData);
      this.openKebabMenu = false;
    },
    toggleKebabMenu() {
      this.openKebabMenu = !this.openKebabMenu;
    },
    toggleSupression() {
      this.$emit('toggleSupression');
      this.openKebabMenu = !this.openKebabMenu;
    },
    isEditAllowed() {
      return editAllowed;
    },
  },
  computed: {
    menuItems() {
      return [
        {label: 'Modifier', method: this.openModalSalle},
        {label: 'Supprimer', method: this.toggleSupression},
      ];
    },
  },
};
</script>
