import gql from "graphql-tag";
export const ADD_EMPLOIS_TEMPS = gql`
  mutation addEmploiTemps(
    $departement_id: Int
    $classe_id: Int
    $annee_universitaire: String
    $semestre: String
    $acces: Boolean
  ) {
    addEmploiTemps(
      departement_id: $departement_id
      classe_id: $classe_id
      annee_universitaire: $annee_universitaire
      semestre: $semestre
      acces: $acces
    )
    {
      message
      success
    }
  }
`;
export const GET_DEPARTEMENTS = gql`
  query departement_prs {
    getAllDepartements {
      id
      label
    }
  }
`;
export const GET_CLASSES_BY_DEPARTEMENT = gql`
  query getClassesByDepartement($id:Int)
{
    getClassesByDepartement(id:$id)
    {
        id
        label
    }
}
`;
