<template>
    <div class="flex">
        <img :src="getImageSrc" alt="" class="w-5 h-5 mr-2">
        <span :class="getTextColor">{{ getGenreText }}</span>
    </div>
</template>

<script>
import man from '../../assets/man.svg';
import woman from '../../assets/woman.svg';
export default {
    name: "GenreComponent",
    data(){
        return {
            man,
            woman
        }
    },
    props:{
        genre:{
            type:String,
            default:'F'
        }
    },
    computed: {
        getImageSrc() {
            return this.genre === 'M' ? man : woman;
        },
        getTextColor() {
            return this.genre === 'M' ? 'text-sky-600' : 'text-pink-600';
        },
        getGenreText() {
            return this.genre === 'M' ? 'Homme' : 'Femme';
        }
    }
}
</script>
