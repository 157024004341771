<template>
  <span>
    <ActionButton
      buttonText="PFEs"
      @click="
        isDialogOpen = true;
        getPfes();
      "
    />
    <v-dialog v-model="isDialogOpen" max-width="531">
      <v-card>
        <div class="flex items-center justify-between py-2 pl-5 border-b">
          <img :src="info" alt="" class="w-7 h-7" />
          <h1 class="text-sm text-blue-600 font-medium pl-2">
            PFEs enseignant : <span class="text-uppercase">{{ nom }}</span
            >&nbsp;
            <span class="text-capitalize">{{ prenom }}</span>
          </h1>
          <img
            :src="Button"
            class="ml-auto cursor-pointer pr-2"
            @click="closeModal"
          />
        </div>
        <div class="mt-4">
          <div v-if="pfes.length > 0">
            <modal-pfe-bloc
              v-for="item in pfes"
              :titre="item.titre"
              :code_projet="item.code_projet"
              :entreprise="item.entreprise"
              :key="item.id"
            />
          </div>
          <p v-else class="text-center text-gray-500 pb-3">
            Aucun PFE enseigné
          </p>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import Button from "@/assets/Button.png";
import info from "@/assets/info.svg";

import ActionButton from "../../../components/Buttons/ActionButton.vue";
import ModalPfeBloc from "./ModalPfeBloc.vue";
import apolloClient from "../../../apolloClient";
import { GET_PFES_ENS } from "./ModalPfe.service";

export default {
  name: "MonPfe",
  components: {
    ActionButton,
    ModalPfeBloc,
  },
  props: {
    idEnseignant: Number,
    nom: String,
    prenom: String,
  },
  data() {
    return {
      isDialogOpen: false,
      Button,
      info,
      pfes: [],
    };
  },
  methods: {
    getPfes() {
      apolloClient
        .query({
          query: GET_PFES_ENS,
          variables: {
            id: this.idEnseignant,
          },
        })
        .then((result) => {
          this.pfes = result.data.getEnseignantById.pfes;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    openModal() {
      this.isDialogOpen = true;
      this.$emit("showDetails");
    },
    closeModal() {
      this.isDialogOpen = false;
    },
  },
};
</script>
