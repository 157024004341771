<template>
  <div>
    <!-- LeftNavbar -->
    <div
      class="h-full shrink-0 bg-gray-900 shadow-xl px-2 py-2.5 inset-y-0 left-0 md:relative md:-translate-x-0 transform duration-300 scrollbar-thin"
      :class="
        dataOpenSideBar == true
          ? 'w-64 overflow-y-auto'
          : 'md:w-20 max-sm:hidden sm:w-0'
      "
    >
      <div class="flex pl-2 py-1">
        <img
          src="../../assets/logo_dst.png"
          alt=""
          class="h-10 w-32 mr-16"
          v-if="dataOpenSideBar"
        />
        <button>
          <img
            src="../../assets/Frame.svg"
            class="w-6 h-6 my-3 cursor-pointer"
            @click="clickHambuger"
            v-if="dataOpenSideBar"
          />
        </button>
        <div class="flex py-1">
          <img
            src="../../assets/logo2.png"
            alt=""
            class="h-8 w-11"
            v-if="!dataOpenSideBar"
          />
        </div>
      </div>
      <div class="pt-4">
        <router-link
          v-for="(item, index) in getItemsForUser()"
          :key="index"
          type="button"
          :to="item.link"
          class="relative"
          @mouseover="item.show = true"
          @mouseleave="item.show = false"
          :class="[
            'flex',
            'pl-3',
            'items-center',
            'text-slate-400',
            'rounded',
            'w-full',
            'transition',
            'duration-200 hover:bg-white/[0.1] hover:text-white',
            {'bg-white/[0.1] text-white': $route.name === item.label},
          ]"
          data-tooltip-target="tooltip-right"
          data-tooltip-placement="right"
        >
          <img :src="item.icon" alt="icon" class="w-5 h-5 my-4 ml-2" />
          <span
            class="flex pl-2 w-45 h-4 flex-col justify-center text-l font-normal"
            v-if="dataOpenSideBar"
            >{{ item.label }}
          </span>
          <Transition>
            <tooltip
              v-if="item.show && !dataOpenSideBar"
              :label="item.label"
            ></tooltip>
          </Transition>
        </router-link>
        <div class="px-12.5 py-4">
          <img src="../../assets/roket.png" alt="img" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import home3 from '../../assets/home3.svg';
import group from '../../assets/Group.svg';
import casseTete from '../../assets/casseTete.svg';
import livre from '../../assets/livre.svg';
import chalkboard_user from '../../assets/chalkboard_user.svg';
import boss from '../../assets/boss.svg';
import calendar from '../../assets/calendar.svg';
import evaluation from '../../assets/evaluation.svg';
import time from '../../assets/time.svg';
import Tooltip from '@/SPA/DashBoard/DashboardSections/tooltip.vue';
import roket from '../../assets/roket.png';

export default {
  name: 'LeftNavbar',
  props: {
    dataOpenSideBar: Boolean,
    clickHambuger: Function,
    userType: {
      Type: String,
    },
  },
  components: {Tooltip},
  data() {
    return {
      home3,
      casseTete,
      livre,
      chalkboard_user,
      boss,
      calendar,
      evaluation,
      time,
      roket,

      items: [
        {label: 'Tableau de bord', icon: home3, link: '/accueil'},
        {
          label: 'Gestion des départements',
          icon: group,
          link: '/departements',
        },
        {label: 'Gestion des classes', icon: casseTete, link: '/classes'},
        {label: 'Gestion des matières', icon: livre, link: '/matieres'},
        {
          label: 'Gestion des enseignants',
          icon: chalkboard_user,
          link: 'enseignants',
        },
        {label: 'Gestion des salles', icon: boss, link: '/salles'},
        {label: 'Emplois de temps', icon: calendar, link: '/emplois-temps'},
        {label: 'Gestion des absences', icon: evaluation, link: '/absences'},
        {label: 'Heures supp', icon: time, link: '/heures-supp'},
      ],
      activeTab: 'Tableau de bord',
    };
  },
  watch: {
    dataOpenSideBar(newVal) {
      if (!newVal) {
        this.handleSidebarClose();
      }
    },
  },
  mounted() {
    this.handleItemClick(this.items[0]);
  },
  methods: {
    handleItemClick(item) {
      this.activeTab = item.label;
      this.$emit('item-clicked', item.label);
    },
    handleSidebarClose() {
      this.$emit('clickHambuger');
    },
    getItemsForUser() {
      if (this.userType === 'dir_dept') {
        return this.items.filter(
          item => item.label !== 'Gestion des départements'
        );
      } else {
        return this.items;
      }
    },
  },
};
</script>
<style>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

.scrollbar-thin {
  scrollbar-width: thin;
}
</style>
