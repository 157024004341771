<template> 
<div>
  <div class="relative">
    <div class="w-full h-12 flex justify-center rounded-3xl bg-zinc-100 shrink-0 placeholder-slate-400 px-5 absolute bottom-10 inset-0 z-20">
            <img src="../../assets/recherche.svg" class="w-6 h-6 m-3"/>
            <input type="text" class="w-full bg-transparent placeholder-slate-400 focus:border-transparent focus:outline-none" :placeholder="placeholder" v-model="recherche" @keypress.enter="rechercherDepartements"/>
    </div>
    <div v-if="suggestions.length > 0" class="absolute w-full top-6 bg-white shadow-lg rounded z-10">
      <ul class="pt-6">
        <li v-for="suggestion in suggestions" :key="suggestion" @click="rechercherDepartementsParSugg(suggestion)" class="cursor-pointer hover:bg-gray-50 rounded-md p-2">{{ suggestion }}</li>
      </ul>
    </div>
  </div>
  
</div>
</template>
<script>
export default {
  name: 'RechercheComponent',
  props: {
    placeholder: String,
    departements: Array
  },
  data() {
    return {
      recherche: '',
      suggestions: []
    };
  },
   watch: {
    recherche() {
       if (typeof this.recherche !== 'string' || this.recherche.trim() === '') {
        this.suggestions = [];
      }
    else {
        this.suggestions = this.departements
        .filter(departement => departement.label.toLowerCase().startsWith(this.recherche.toLowerCase()))
        .map(departement => departement.label);
    
    }
  }
  },
  methods: {
    rechercherDepartementsParSugg(suggestion) {
      this.recherche = suggestion;
      this.suggestions = [];
      const departementsFiltres = this.departements.filter(departement => {
        return departement.label.toLowerCase().includes(this.recherche.toLowerCase());
      });
      this.$emit('resultatsFiltres', departementsFiltres);
      this.recherche = '';
    },
    rechercherDepartements() {
      const departementsFiltres = this.departements.filter(departement => {
        return departement.label.toLowerCase().includes(this.recherche.toLowerCase());
      });
      this.$emit('resultatsFiltres', departementsFiltres);
      this.recherche = '';
    },
}

}
</script>