<template>
  <v-dialog v-model="modalOpen" max-width="500">
    <v-card class="rounded-lg">
      <VCardTitle class="flex items-center justify-between py-2 border-b">
        <h1 class="text-xl font-semibold">Diffuser sur Didousoft.tn</h1>
        <VBtn
          icon="mdi-close"
          size="small"
          variant="text"
          @click="modalOpen = false"
        ></VBtn>
      </VCardTitle>

      <VCardText>
        Cette action diffusera une copie de tous les emplois de temps du
        département actuel vers Didousoft.tn.
        <Alert variant="warning" class="mt-2">
          L'ancienne version sera remplacée par la nouvelle; Cette action est
          <strong class="uppercase">Irréversible !</strong>
        </Alert>
        <div class="flex items-center justify-end">
          <VBtn variant="outlined" @click="modalOpen = false">Annuler</VBtn>
          <VBtn color="warning" class="ml-2" @click="proceedPublier"
            >Procéder</VBtn
          >
        </div>
      </VCardText>
    </v-card>
  </v-dialog>
</template>

<script setup>
import {computed, defineProps, defineEmits} from 'vue';
import Alert from '../../Alerts/Alert.vue';

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'proceedPublier']);

const modalOpen = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const proceedPublier = () => {
  emit('proceedPublier');
  modalOpen.value = false;
};
</script>
