<template>
  <div
    class="flex flex-row gap-6 md:items-center hover:ml-2 rounded duration-500"
  >
    <div class="hidden md:block">
      <img :src="getIconImage()" class="rounded-full w-12 h-12" />
    </div>
    <div class="grow">
      <div
        class="text-gray-800 font-medium"
        v-html="getTextTranslation()"
      ></div>
      <div class="text-teal-600 font-medium text-capitalize">{{ user }}</div>
    </div>
    <div
      class="text-gray-600 text-lg font-normal md:text-base text-xs min-w-fit"
    >
      {{ date }}
    </div>
  </div>
</template>

<script>
import emplois from '../../../../assets/icon-circle-calendar.svg';
import departement from '../../../../assets/icon-circle-books.svg';
import enseignant from '../../../../assets/icon-circle-bag.svg';
import salle from '../../../../assets/icon-circle-loc.svg';
import groupe from '../../../../assets/icon-circle-people.svg';
export default {
  name: 'ItemActiviteRecentes',
  data() {
    return {
      icons: {
        departement,
        groupe,
        enseignant,
        salle,
        emplois,
      },
    };
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Le département génie civil à été mis à jour',
    },
    user: {
      type: String,
      default: 'Nom utilisateur',
    },
    date: {
      type: String,
      default: '2022-06-04',
    },
  },
  methods: {
    getIconImage() {
      return this.icons[this.icon] ?? this.icons.departement;
    },
    getTextTranslation() {
      const translationMap = {
        departement: `Le département <strong>${this.label}</strong> à été mis à jour`,
        groupe: `Le groupe <strong>${this.label}</strong> à été mis à jour`,
        enseignant: `L'enseignant <strong>${this.label}</strong> à été mis à jour`,
        salle: `La salle <strong>${this.label}</strong> à été mis à jour`,
        emplois: `L'emploi du temps <strong>${this.label}</strong> à été mis à jour`,
      };
      return translationMap[this.icon] ?? this.icon + ': ' + this.label;
    },
  },
};
</script>
