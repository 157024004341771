<template>
  <SimpleCard>
    <div class="flex items-center justify-between border-b">
      <span class="font-medium text-blue-900 pr-6 pb-4"
        >Taux d’occupation
      </span>
      <select
        v-model="selectedOption"
        @change="handleChange"
        class="appearance-none rounded-md py-2 pl-4 pr-10 mb-4 outline-none bg-blue-50 text-blue-700"
      >
        <option class="text-blue-700">Par salle</option>
        <option class="text-blue-700">Par enseignant</option>
      </select>
    </div>
    <div class="flex justify-center mb-10 mt-6 px-16">
      <canvas id="TauxOccupation" class="w-40 h-40"></canvas>
    </div>
    <div class="grid grid-cols-1 gap-4">
      <div class="flex items-center justify-center" @Click="toggleData(0)">
        <div id="leg1" class="w-3 h-3 rounded-full"></div>
        <span id="leg1Text" class="pl-1 text-slate-500 text-sm"></span>
        <span class="pl-4 text-green-500">{{ pourcentageOccupe }} %</span>
      </div>
      <div class="flex items-center justify-center" @Click="toggleData(1)">
        <div id="leg2" class="w-3 h-3 rounded-full"></div>
        <span id="leg2Text" class="pl-1 text-slate-500 text-sm"></span>
        <span class="pl-4 text-green-500">{{ pourcentageLibre }} %</span>
      </div>
    </div>
  </SimpleCard>
</template>
<script>
import SimpleCard from "../../../../components/Cards/SimpleCard/SimpleCard.vue";
import Chart from "chart.js/auto";
import { TAUX_OCCUPATION } from "../../Dashboard.service";
import apolloClient from "@/apolloClient";
export default {
  name: "DoughnutChart",
  components: {
    SimpleCard,
  },
  data() {
    return {
      chart: null,
      selectedOption: "Par salle",
      pourcentageOccupe: 0,
      pourcentageLibre: 0,
      occupeData: 0,
      libreData: 0,
      dataStats: [],
      sallesStats: [],
      enseignantsStat: [],
    };
  },
  watch: {
    selectedOption() {
      this.updatePercentages();
      this.updateData();
      this.DoughnutChart();
    },
  },
  methods: {
    DoughnutChart() {
      const ctx = document.getElementById("TauxOccupation");
      if (!ctx) return;
      if (this.chart) {
        this.chart.destroy();
      }
      this.chart = new Chart(ctx, {
        type: "doughnut",
        options: {
          barPercentage: 0.8,
          plugins: {
            legend: {
              display: false,
            },
          },
        },
        data: {
          labels: ["Occupé ", "Libre"],
          datasets: [
            {
              data: [this.occupeData, this.libreData],
              backgroundColor: ["rgb(44, 123, 229)", "rgb(251, 146, 60)"],
              cutout: "70%",
            },
          ],
        },
      });
      this.costumLegend();
    },
    async getTauxOccupation() {
      try {
        const response = await apolloClient.query({
          query: TAUX_OCCUPATION,
        });
        if (response.data && response.data.getStatsOcupatioonCard) {
          this.sallesStats =
            response.data.getStatsOcupatioonCard.getSallesStatusByDepartement ||
            [];
          this.enseignantsStat =
            response.data.getStatsOcupatioonCard
              .getEnseignantStatusByDepartement || [];
          this.updatePercentages();
          this.updateData();
          this.DoughnutChart();
        }
      } catch (error) {
        console.error(error);
      }
    },
    costumLegend() {
      if (!this.chart) {
        return;
      }
      document.getElementById("leg1").style.backgroundColor =
        this.chart.data.datasets[0]?.backgroundColor[0] || "";
      document.getElementById("leg1Text").innerText =
        this.chart.data.labels[0] || "";
      document.getElementById("leg2").style.backgroundColor =
        this.chart.data.datasets[0]?.backgroundColor[1] || "";
      document.getElementById("leg2Text").innerText =
        this.chart.data.labels[1] || "";
    },
    toggleData(value) {
      if (!this.chart) {
        return;
      }
      const visibilityData = this.chart.isDatasetVisible(value);
      if (visibilityData === true) {
        this.chart.hide(value);
      } else {
        this.chart.show(value);
      }
    },
    updatePercentages() {
      if (this.selectedOption === "Par salle" && this.sallesStats.length >= 3) {
        this.pourcentageOccupe =
          Math.floor((this.sallesStats[1] / this.sallesStats[0]) * 100) || 0;
        this.pourcentageLibre = 100 - this.pourcentageOccupe;
      } else if (
        this.selectedOption === "Par enseignant" &&
        this.enseignantsStat.length >= 3
      ) {
        this.pourcentageOccupe =
          Math.floor(
            (this.enseignantsStat[1] / this.enseignantsStat[0]) * 100
          ) || 0;
        this.pourcentageLibre = 100 - this.pourcentageOccupe;
      } else {
        this.pourcentageOccupe = 0;
        this.pourcentageLibre = 0;
      }
    },
    updateData() {
      if (this.selectedOption === "Par salle" && this.sallesStats.length >= 3) {
        this.occupeData = this.sallesStats[1] || 0;
        this.libreData = this.sallesStats[2] || 0;
      } else if (
        this.selectedOption === "Par enseignant" &&
        this.enseignantsStat.length >= 3
      ) {
        this.occupeData = this.enseignantsStat[1] || 0;
        this.libreData = this.enseignantsStat[2] || 0;
      } else {
        this.occupeData = 0;
        this.libreData = 0;
      }
    },
    handleChange() {
      this.updatePercentages();
      this.updateData();
      if (this.chart) {
        this.chart.data.datasets[0].data = [this.occupeData, this.libreData];
      }
    },
  },
  mounted() {
    this.getTauxOccupation();
  },
};
</script>
