<template>
  <label class="inline-flex items-center cursor-pointer">
    <span class="mr-2 ml-4 font-semibold text-neutral-500"
      >Afficher le panneau
    </span>
    <Toggle
      :value="displayed"
      textYes=""
      textNo=""
      @toggle-input="toggleDisplayPanneau"
    />
  </label>

</template>

<script>
import Toggle from '../../../Toggle/Toggle.vue';
export default {
  components: {
    Toggle,
  },
  props: {
    checked: Boolean,
  },
  data() {
    return {
      displayed: false
    }
  },
  methods: {
    toggleDisplayPanneau() {
      this.displayed = !this.displayed;
       this.$emit("displayPanneau");
    },
  }
};
</script>
