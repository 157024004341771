<template>
  <div>
    <SimpleCard title="Changer adresse e-mail " fullWidth>
      <div class="grid sm:grid-cols-2 grid-cols-1 gap-6 p-6">
        <div class="text-gray-500 text-lg max-w-lg">
          Ceci servira ultérieurement pour récupérer vos données si nécessaire.
        </div>
        <div class="flex gap-6 flex-col">
          <InputWithLabel
            showShadow="false"
            type="text"
            label="Adresse email"
            v-model="newEmail"
            labelClass="text-gray-800 text-sm"
            inputClass="border-gray-200"
            layoutClass="my-0"
          />
          <InputWithLabel
            showShadow="false"
            type="password"
            label="Mot de passe actuel"
            labelClass="text-gray-800 text-sm"
            inputClass="border-gray-200"
            v-model="oldMdp"
            layoutClass="my-0"
          />
          <div>
            <GradientButton @btn-click="submitUpdate()" text="Mettre à jour" />
          </div>
        </div>
      </div>
    </SimpleCard>
    <ModalSuccess v-if="showSuccess" @toggleSuccess="toggleSuccess" />
    <ModalErreur
      v-if="showError"
      @toggle-erreur="toggleToggleError"
      :reason="errorReason"
    />
  </div>
</template>

<script>
import SimpleCard from "../../../../components/Cards/SimpleCard/SimpleCard.vue";
import InputWithLabel from "../../../../components/InputWithLabel/InputWithLabel.vue";
import GradientButton from "../../../../components/Buttons/GradientButton/GradientButton.vue";
import apolloClient from "@/apolloClient";
import { UPDATE_EMAIL } from "../../ProfilePage.service";
import ModalSuccess from "@/components/Popup/Success/ModalSuccess.vue";
import ModalErreur from "@/components/Popup/Erreurs/ModalErreur.vue";
export default {
  name: "ProfileChangeEmail",
  components: {
    SimpleCard,
    InputWithLabel,
    GradientButton,
    ModalSuccess,
    ModalErreur,
  },
  props: {
    currentEmail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newEmail: "",
      oldMdp: "",
      showSuccess: false,
      showError: false,
      errorReason: "",
    };
  },
  watch: {
    currentEmail() {
      this.newEmail = this.currentEmail;
    },
  },
  methods: {
    async submitUpdate() {
      if (!this.oldMdp || !this.newEmail) {
        return;
      }
      await apolloClient
        .mutate({
          mutation: UPDATE_EMAIL,
          variables: {
            newEmail: this.newEmail,
            oldMdp: this.oldMdp,
          },
        })
        .then((resp) => {
          const responseData = resp.data.changerEmail;
          if (responseData.success == true) {
            this.showSuccess = true;
            this.oldMdp = "";
          } else {
            this.errorReason = responseData.message;
            this.showError = true;
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    toggleSuccess() {
      this.showSuccess = !this.showSuccess;
    },
    toggleToggleError() {
      this.showError = !this.showError;
    },
  },
};
</script>
