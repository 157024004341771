<template>
  <v-dialog v-model="modalOpen" max-width="531">
    <template v-slot:activator="{ on }">
      <button
        dark
        v-on="on"
        @click="openModal"
        v-if="decryptedType === 'dir_dept' || decryptedType === 'dir_etu'"
        class="flex items-center justify-center sm:w-28 w-8 h-8 pl-0 rounded-full border-2 border-gray-300 outline-none bg-gray-100 text-gray-700 text-center"
      >
        <span class="items-center mr-4 hidden sm:flex">Importer</span>
        <img :src="uploadIm" class="w-4 h-4" />
      </button>
    </template>
    <v-card class="rounded-lg px-5">
      <div class="flex items-center justify-between py-2 border-b">
        <h1 class="text-xl font-semibold">Importer classes</h1>
        <img :src="Button" class="ml-auto cursor-pointer" @click="closeModal" />
      </div>
      <div class="mt-4">
        <label class="mb-2">Département </label>
        <select
          v-model="selectedDepartement"
          class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none"
          @change="handleDepartmentChange"
        >
          <option value="" disabled hidden>Veuillez sélectionner</option>
          <option
            v-for="departement in Departements"
            :key="departement.id"
            :value="departement.id"
          >
            {{ departement.label }}
          </option>
        </select>
      </div>
      <div class="mt-2">
        <label
          :class="`mb-2 block gap-2 ${
            specialites.length === 0 ? 'text-gray-400' : ''
          }`"
          >Spécialité</label
        >
        <select
          v-model="selectedSpecialite"
          :disabled="specialites.length === 0"
          class="w-full px-4 py-2 border border-gray-300 rounded-md outline-none"
        >
          <option value="" disabled hidden>Veuillez sélectionner</option>
          <option
            v-for="specialite in specialites"
            :key="specialite.id"
            :value="specialite.id"
          >
            {{ specialite.label }}
          </option>
        </select>
      </div>
      <div class="mt-4">
        <label class="mb-2">Fichier attaché </label>
        <div class="flex items-center mt-2">
          <input
            type="text"
            id="fileName"
            disabled
            class="grow rounded-l-full border border-gray-300 h-10 text-gray-400 text-sm px-4"
            placeholder="Insérer le fichier"
          />

          <label
            for="fileInput2"
            class="bg-cyan-600 rounded-r-full h-10 p-3 text-white flex items-center justify-center cursor-pointer gap-1"
          >
            <img :src="upload" class="w-4 h-4" />
            <span class="flex items-center max-sm:text-xs text-center"
              >Choisir le fichier</span
            >
            <input
              id="fileInput2"
              type="file"
              class="hidden"
              @change="loadFile"
              accept=".csv"
            />
          </label>
        </div>
        <a
          :href="api_url + '/download/model_classe.csv'"
          class="text-blue-500 text-xs flex justify-end items-center w-full px-2> mt-2 block"
        >
          <span>Télécharger le modèle</span>
        </a>
      </div>

      <alert variant="warning" class="mt-2">
        Veuillez noter que la première ligne du fichier doit contenir les
        en-têtes et vas être ignorée
      </alert>
      <v-card-actions class="gap-2 flex justify-end mt-4">
        <button
          class="bg-gray-300 rounded-full px-6 outline-none text-black font-inter text-sm font-semibold leading-10 tracking-normal text-center"
          @click="closeModal"
        >
          Annuler
        </button>
        <button
          class="bg-blue-700 rounded-full px-6 outline-none font-inter text-sm font-semibold leading-10 tracking-normal text-center text-white"
          @click="importClass"
        >
          Valider
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Button from "../../../assets/Button.png";
import plus from "../../../assets/plus-sm.png";
import upload from "../../../assets/upload.png";
import uploadIm from "../../../assets/upload.svg";
import {
  Get_All_Dep,
  IMPORT_CLASSE,
  GET_ALL_CLASSES,
  GET_SPECIALITES_BY_DEPT,
} from "./ModalClasse.service.js";
import apolloClient from "../../../apolloClient";
import CryptoJS from "crypto-js";
import Alert from "../../Alerts/Alert.vue";
const api_url = process.env.VUE_APP_API_URL;
export default {
  components: { Alert },
  name: "ModalClasse",
  data() {
    return {
      plus,
      uploadIm,
      Button,
      upload,
      modalOpen: false,
      selectedDepartement: "",
      Departements: [],
      FileBase64: null,
      base64String: "",
      decryptedType: "",
      selectedSpecialite: "",
      specialites: [],
      departementId: null,
      api_url,
    };
  },
  methods: {
    openModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },

    loadFile(event) {
      this.handleFileUpload(event, "fileName");
    },
    handleFileUpload(event, fileNameId) {
      const input = event.target;
      const fileNameInput = document.getElementById(fileNameId);
      if (input.files && input.files[0]) {
        const file = input.files[0];
        fileNameInput.value = file.name;
        const reader = new FileReader();
        reader.onload = () => {
          const base64String = reader.result.split(",")[1];
          if (fileNameId == "fileName") {
            this.FileBase64 = base64String;
          }
        };
        reader.readAsDataURL(file);
      }
    },
    async getDep() {
      try {
        const response = await apolloClient.query({
          query: Get_All_Dep,
        });
        this.Departements = response.data.getAllDepartements;
      } catch (error) {
        console.error("Error fetching Departements:", error);
      }
    },
    async importClass() {
      apolloClient
        .mutate({
          mutation: IMPORT_CLASSE,
          variables: {
            file: this.FileBase64,
            dep_id: parseInt(this.selectedDepartement),
            is_actif: 1,
            date_update: new Date().toISOString().slice(0, 10),
            id_specialite: this.selectedSpecialite,
          },
          refetchQueries: [
            {
              query: GET_ALL_CLASSES,
            },
          ],
        })
        .then(({ data }) => {
          if (!data.importClasse.success) {
            this.$emit("toggleErreur", data.importClasse);
            return;
          }
          this.$emit("refresh-view", 1);
        })
        .catch((error) => {
          console.error("Error importing classes:", error);
          this.$emit("toggleErreur");
        });

      this.closeModal();
    },
    async getSpecialites() {
      try {
        const response = await apolloClient.query({
          query: GET_SPECIALITES_BY_DEPT,
          variables: {
            id_dept: parseInt(this.selectedDepartement),
          },
        });
        this.specialites = response.data.getSpecialitesByDepartement;
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des spécialités :",
          error
        );
      }
    },
    handleDepartmentChange() {
      this.getSpecialites();
    },
  },
  mounted() {
    const typeCrypte = localStorage.getItem("type");
    const bytes = CryptoJS.AES.decrypt(typeCrypte, "KEY");
    this.decryptedType = bytes.toString(CryptoJS.enc.Utf8);
    this.getDep();
  },
};
</script>
