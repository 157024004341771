<template>
    <div class="w-44 rounded-lg shadow py-2 bg-white" v-show="dataOpenKebabMenu">
        <ul class="w-full p-0">
            <li v-for="(item, index) in menuItems" :key="index">
                <button class="flex w-full" @click="item.method">
                    <span 
                    :class="{'text-red-500': item.label === 'Supprimer'}"
                    class="justify-center text-neutral-900 font-normal text-sm text-center border-y w-full py-2.5 border-gray-300/[0.2]">
                        {{ item.label }}
                    </span>
                </button>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    name: 'KebabMenu',
    components: {

    },
    props: {
        dataOpenKebabMenu: Boolean,
        clickKebabMenu: Function,
        archived: {
            type: Boolean,
            default: false
        },
        menuItems: Array
    },
    methods: {
        toggleModalSpecialite() {
            this.$emit('toggleModalSpecialite', true)
           
        },
        toggleArchive() {
            this.$emit('toggleArchive', true)
            
        },
        toggleModalEdition() {
            this.$emit('toggleModalEdition', true)
        },
        toggleSupression() {
            this.$emit('toggleSupression', true)
        }
    },
}
</script>