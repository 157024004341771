<template>
  <SimpleCard>
    <div
      v-if="simpleView != true"
      class="gradient-top-img from-slate-900 to-sky-500 rounded-2xl bg-gradient-to-r w-full h-35"
    ></div>
    <div class="flex items-center justify-center -mt-10 flex-col">
      <UserAvatarImg :nom="userInfos.nom" />
      <div
        class="text-center text-indigo-900 text-xl font-bold mt-4 text-capitalize"
        v-if="userInfos"
      >
        {{ userInfos.nom + " " + userInfos.prenom }}
      </div>
      <div class="text-slate-400 text-sm font-medium text-center mb-5">
        <div>{{ userInfos.type_translate }}</div>
        <div>Institut : {{ userInfos.etab }}</div>
      </div>
    </div>
  </SimpleCard>
</template>

<script>
import SimpleCard from "../../../../components/Cards/SimpleCard/SimpleCard.vue";
import UserAvatarImg from "./UserAvatarImg.vue";

export default {
  name: "ProfileUserImage",
  components: {
    SimpleCard,
    UserAvatarImg,
  },
  props: {
    userInfos: {
      type: Object,
      default: () => ({
        nom: "Chargement",
        prenom: "",
        etab: "...",
      }),
    },
    simpleView: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
