import gql from 'graphql-tag';
export const STAT_DASHBOARD = gql`
  query getStatsForHorizontalCards {
    getStatsForHorizontalCards {
      getNumberOfEnseignant
      getNumberOfDepartments
      getNumberOfEmploiTemps
      getNumberOfClasses
      getSeancesStatusByDepartement
    }
  }
`;
export const TAUX_OCCUPATION = gql`
  query getStatsOcupatioonCard {
    getStatsOcupatioonCard {
      getSallesStatusByDepartement
      getEnseignantStatusByDepartement
    }
  }
`;
export const STAT_LAST_EMLPOI = gql`
  query getLatestEmploisTemps {
    getLatestEmploisTemps {
      id
      departement {
        id
        label
      }
      classe {
        id
        label
        niveau
      }
      semestre
      annee_universitaire
      acces
      nb_seances
    }
  }
`;
export const STAT_CHART = gql`
  query getDepartmentStats($annee_univ: String!) {
    getDepartmentStats(annee_univ: $annee_univ) {
      departmentName
      numberOfClasses
      numberOfEmploiTemps
      numberOfSeances
    }
  }
`;
export const STAT_ACTIVITY_RECENT = gql`
  query activities {
    activities {
      label
      personne
      op_date
      ac_type
    }
  }
`;
