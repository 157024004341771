import gql from 'graphql-tag';
export const GET_ALL_EMPLOIS = gql`
  query emplois_temps {
    getAllEmploisTemps {
      id
      departement {
        id
        label
      }
      classe {
        id
        label
        niveau
      }
      updatedDate
      semestre
      annee_universitaire
      acces
      nb_seances
      archived
    }
  }
`;

export const TOGGLE_ACCES = gql`
  mutation toggleAcces($id: Int!) {
    toggleAcces(id: $id)
  }
`;
export const GET_EMPLOI_BY_ID = gql`
  query getEmploiTempsById($id: Int!) {
    getEmploiTempsById(id: $id) {
      id
      departement {
        id
        label
      }
      classe {
        id
        label
      }
      semestre
      annee_universitaire
      acces
      archived
      seancesEmploi {
        id
        id_matiere
        id_enseignant
        date_update
        id_salle
        id_groupe
        etab
        id_emploi
        jour
        horaire
        matiere {
          id
          label
          natures
        }
        enseignant {
          id
          nom
          prenom
        }
        salle {
          id
          label
        }
        group {
          id
          label
        }
      }
    }
  }
`;
export const GET_BANNER_DATA_BY_EMPLOIID = gql`
  query getEmploiTempsById($id: Int!) {
    getEmploiTempsById(id: $id) {
      acces
      semestre
      departement {
        label
      }
      classe {
        etab
        label
        niveau
      }
    }
  }
`;

export const emploiFields = `
  id
  classe {
    label
  }
  updatedDate
  seancesEmploi {
    id
    id_matiere
    id_enseignant
    id_salle
    id_groupe
    etab
    id_emploi
    jour
    horaire

    matiere {
      id
      label
      natures
    }
    enseignant {
      id
      nom
      prenom
    }
    salle {
      id
      label
    }
    group {
      id
      label
    }
  }
`;

export const GET_ALL_SEANCES_BY_ID_EMPLOI = gql`
  query getEmploiTempsById($id: Int!) {
    getEmploiTempsById(id: $id) {
      ${emploiFields}
    }
  }
`;

export const GET_ANNEE_UNIVERSITAIRES = gql`
  query getAnneeUniversitaires {
    getAnneeUniversitaires
  }
`;
export const ARCHIVE_EMPLOI = gql`
  mutation archiveEmploi($id: Int) {
    archiveEmploi(id: $id)
  }
`;
export const DELETE_EMPLOI = gql`
  mutation deleteEmploi($id: Int) {
    deleteEmploi(id: $id)
  }
`;

export const DIFFUSER_EMPLOI = gql`
  mutation diffuserEmplois($annee_universitaire: String!) {
    diffuserEmplois(annee_universitaire: $annee_universitaire) {
      success
      message
    }
  }
`;
