<template>
  <div
    class="md:w-44 h-fit rounded-lg shadow py-2 bg-white right-2"
    v-show="dataOpenDropdown"
  >
    <ul class="max-sm:m-4 sm:m-4">
      <li class="flex">
        <router-link to="/profile" class="flex items-center">
          <img src="@/assets/circle_user.svg" alt="" class="mr-3 w-5 h-5" />
          <span class="text-gray-500 font-medium text-sm">Voir le profil</span>
        </router-link>
      </li>
      <li class="flex my-5">
        <router-link
          v-if="decryptedType === 'dir_etu'"
          to="/parametres"
          class="flex items-center"
        >
          <img src="@/assets/settings.svg" alt="" class="mr-3 w-5 h-5" />
          <span class="text-gray-500 font-medium text-sm">Paramètres</span>
        </router-link>
      </li>
      <li class="flex my-4">
        <button @click="deconnecter" class="flex items-center">
          <img src="@/assets/power.svg" alt="" class="mr-3 w-5 h-5" />
          <span class="text-gray-500 font-medium text-sm">Se déconnecter</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
//import { useRouter } from 'vue-router';
import { getDecryptedType } from "@/SPA/spa.services";
export default {
  name: "DropDown",
  props: {
    dataOpenDropdown: Boolean,
  },
  data() {
    return {
      decryptedType: "",
    };
  },
  created() {
    this.decryptedType = getDecryptedType();
  },
  setup() {
    const deconnecter = () => {
      try {
        localStorage.clear();
        location.href = "";
      } catch (error) {
        console.error("Erreur lors de la déconnexion", error);
      }
    };

    return {
      deconnecter,
    };
  },
  methods: {
    clickDropdown(value) {
      this.$emit("clickDropdown", value);
    },
  },
};
</script>
